@charset "UTF-8";
/* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:before, .container:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 728px;
  }
}

@media (min-width: 992px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 948px;
  }
}

@media (min-width: 1200px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 1148px;
  }
}

/* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:after {
  clear: both;
}

/* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.row {
  margin-left: -4px;
  margin-right: -4px;
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:before, .row:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1 {
  width: 8.33333%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-2 {
  width: 16.66667%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-3 {
  width: 25%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-4 {
  width: 33.33333%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-5 {
  width: 41.66667%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-6 {
  width: 50%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-7 {
  width: 58.33333%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-8 {
  width: 66.66667%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-9 {
  width: 75%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-10 {
  width: 83.33333%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-11 {
  width: 91.66667%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-12 {
  width: 100%;
}

/* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-0 {
  right: auto;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-1 {
  right: 8.33333%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-2 {
  right: 16.66667%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-3 {
  right: 25%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-4 {
  right: 33.33333%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-5 {
  right: 41.66667%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-6 {
  right: 50%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-7 {
  right: 58.33333%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-8 {
  right: 66.66667%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-9 {
  right: 75%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-10 {
  right: 83.33333%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-11 {
  right: 91.66667%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-12 {
  right: 100%;
}

/* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-0 {
  left: auto;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-1 {
  left: 8.33333%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-2 {
  left: 16.66667%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-3 {
  left: 25%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-4 {
  left: 33.33333%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-5 {
  left: 41.66667%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-6 {
  left: 50%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-7 {
  left: 58.33333%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-8 {
  left: 66.66667%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-9 {
  left: 75%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-10 {
  left: 83.33333%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-11 {
  left: 91.66667%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-12 {
  left: 100%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-0 {
  margin-left: 0%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-3 {
  margin-left: 25%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-6 {
  margin-left: 50%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-9 {
  margin-left: 75%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  /* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  /* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

/* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
.container, .section-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:before, .container:after, .section-container:before, .section-container:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:after, .section-container:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 728px;
  }
}

@media (min-width: 992px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 948px;
  }
}

@media (min-width: 1200px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1148px;
  }
}

@media (min-width: 1300px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1270px;
  }
}

@media (min-width: 1400px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1370px;
  }
}

/* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:after {
  clear: both;
}

/* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
.row {
  margin-left: -4px;
  margin-right: -4px;
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:before, .row:after {
  content: " ";
  display: table;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1 {
  width: 8.33333%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-2 {
  width: 16.66667%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-3 {
  width: 25%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-4 {
  width: 33.33333%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-5 {
  width: 41.66667%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-6 {
  width: 50%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-7 {
  width: 58.33333%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-8 {
  width: 66.66667%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-9 {
  width: 75%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-10 {
  width: 83.33333%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-11 {
  width: 91.66667%;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-12 {
  width: 100%;
}

/* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-0 {
  right: auto;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-1 {
  right: 8.33333%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-2 {
  right: 16.66667%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-3 {
  right: 25%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-4 {
  right: 33.33333%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-5 {
  right: 41.66667%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-6 {
  right: 50%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-7 {
  right: 58.33333%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-8 {
  right: 66.66667%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-9 {
  right: 75%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-10 {
  right: 83.33333%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-11 {
  right: 91.66667%;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-12 {
  right: 100%;
}

/* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-0 {
  left: auto;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-1 {
  left: 8.33333%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-2 {
  left: 16.66667%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-3 {
  left: 25%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-4 {
  left: 33.33333%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-5 {
  left: 41.66667%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-6 {
  left: 50%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-7 {
  left: 58.33333%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-8 {
  left: 66.66667%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-9 {
  left: 75%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-10 {
  left: 83.33333%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-11 {
  left: 91.66667%;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-12 {
  left: 100%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-0 {
  margin-left: 0%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-3 {
  margin-left: 25%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-6 {
  margin-left: 50%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-9 {
  margin-left: 75%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  /* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  /* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    width: 8.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    width: 16.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    width: 25%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    width: 33.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    width: 41.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    width: 50%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    width: 58.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    width: 66.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    width: 75%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    width: 83.33333%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    width: 91.66667%;
  }
  /* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    width: 100%;
  }
  /* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-0 {
    right: auto;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-3 {
    right: 25%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-6 {
    right: 50%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-9 {
    right: 75%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-12 {
    right: 100%;
  }
  /* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-0 {
    left: auto;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-3 {
    left: 25%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-6 {
    left: 50%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-9 {
    left: 75%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-12 {
    left: 100%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

/* line 42, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert h4 {
  margin-top: 0;
  color: inherit;
}

/* line 48, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert .alert-link, .alert a:not(.cta-button) {
  text-decoration: underline;
}

/* line 54, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert > p,
.alert > ul {
  margin-bottom: 0;
}

/* line 59, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert > p + p {
  margin-top: 5px;
}

/* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}

/* line 74, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

/* line 86, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-success {
  color: #4D7300;
  background-color: #ECF5D9;
  border-color: #D2E8A6;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-success hr {
  border-top-color: #c8e392;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-success .alert-link, .alert-success a {
  color: #4D7300;
}

/* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-success .alert-link:hover, .alert-success a:hover {
  color: #517A00;
}

/* line 20, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-success .cta-button {
  background-color: #6EA600;
  border-color: #6EA600;
  color: white;
}

/* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-success .cta-button:hover {
  background-color: white;
  color: #6EA600;
}

/* line 90, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-info {
  color: #005580;
  background-color: #D9EDF7;
  border-color: #A6D4EC;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-info hr {
  border-top-color: #91cae8;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-info .alert-link, .alert-info a {
  color: #005580;
}

/* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-info .alert-link:hover, .alert-info a:hover {
  color: #006FA9;
}

/* line 20, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-info .cta-button {
  background-color: #0076B2;
  border-color: #0076B2;
  color: white;
}

/* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-info .cta-button:hover {
  background-color: white;
  color: #0076B2;
}

/* line 94, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-warning {
  color: #996100;
  background-color: #FFF1D9;
  border-color: #FFDFA6;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-warning hr {
  border-top-color: #ffd68d;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-warning .alert-link, .alert-warning a {
  color: #996100;
}

/* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-warning .alert-link:hover, .alert-warning a:hover {
  color: #9B6300;
}

/* line 20, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-warning .cta-button {
  background-color: #E69300;
  border-color: #E69300;
  color: white;
}

/* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-warning .cta-button:hover {
  background-color: white;
  color: #E69300;
}

/* line 98, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-danger {
  color: #99000A;
  background-color: #FBD9DB;
  border-color: #F6A6AB;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-danger hr {
  border-top-color: #f48f95;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-danger .alert-link, .alert-danger a {
  color: #99000A;
}

/* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-danger .alert-link:hover, .alert-danger a:hover {
  color: #CB000D;
}

/* line 20, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-danger .cta-button {
  background-color: #CC000E;
  border-color: #CC000E;
  color: white;
}

/* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_alerts.scss */
.alert-danger .cta-button:hover {
  background-color: white;
  color: #CC000E;
}

/**
 * Fix for vw, vh, vmin, vmax on iOS 7.
 * http://caniuse.com/#feat=viewport-units
 *
 * This fix works by replacing viewport units with px values on known screen sizes.
 *
 * iPhone 6 and 6 Plus cannot run iOS 7, so are not targeted by this fix.
 * Target devices running iOS 8+ will incidentally execute the media query,
 * but this will still produce the expected result; so this is not a problem.
 *
 * As an example, replace:
 *
 *   height: 50vh;
 *   font-size: 5vmin;
 *
 * with:
 *
 *   @include viewport-unit(height, 50vh);
 *   @include viewport-unit(font-size, 5vmin);
 */
/*Spaces
------------------------------------*/
/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.no-padding {
  padding: 0 !important;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.no-margin {
  margin: 0 !important;
}

/* line 17, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.no-top-space {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

/* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.no-bottom-space {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

/* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.no-margin-bottom {
  margin-bottom: 0 !important;
}

/* line 31, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.no-padding-bottom {
  padding-bottom: 0 !important;
}

/* Comment the following for now, first one is causing issues, others
   probably not necessary either. */
/*
.content-xs {
  padding-top: 20px;
  padding-bottom: 20px;
}

.content {
  padding-top: 40px;
  padding-bottom: 40px;
}

.content-sm {
  padding-top: 60px;
  padding-bottom: 60px;
}

.content-md {
  padding-top: 80px;
  padding-bottom: 80px;
}

.content-lg {
  padding-top: 100px;
  padding-bottom: 100px;
}

.content-xlg {
  padding-top: 150px;
  padding-bottom: 150px;
}

*/
/* line 70, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.space-md-hor {
  padding-left: 40px;
  padding-right: 40px;
}

/* line 75, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.space-lg-hor {
  padding-left: 60px;
  padding-right: 60px;
}

/* line 80, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.space-xlg-hor {
  padding-left: 100px;
  padding-right: 100px;
}

/* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-5, .margin-bottom-10, .margin-bottom-15, .margin-bottom-20, .margin-bottom-25, .margin-bottom-30, .margin-bottom-35, .margin-bottom-40, .margin-bottom-45, .margin-bottom-50, .margin-bottom-55, .margin-bottom-60, .margin-bottom-100 {
  clear: both;
}

/* line 89, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-5 {
  margin-bottom: 5px;
}

/* line 93, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-10 {
  margin-bottom: 10px;
}

/* line 97, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-15 {
  margin-bottom: 15px;
}

/* line 101, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-20 {
  margin-bottom: 20px;
}

/* line 105, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-25 {
  margin-bottom: 25px;
}

/* line 109, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-30 {
  margin-bottom: 30px;
}

/* line 113, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-35 {
  margin-bottom: 35px;
}

/* line 117, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-40 {
  margin-bottom: 40px;
}

/* line 121, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-45 {
  margin-bottom: 45px;
}

/* line 125, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-50 {
  margin-bottom: 50px;
}

/* line 129, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-55 {
  margin-bottom: 55px;
}

/* line 133, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-60 {
  margin-bottom: 60px;
}

/* line 137, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-70 {
  margin-bottom: 70px;
}

/* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-80 {
  margin-bottom: 80px;
}

/* line 145, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-90 {
  margin-bottom: 90px;
}

/* line 149, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-bottom-100 {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  /* line 154, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .sm-margin-bottom-10 {
    margin-bottom: 10px;
  }
  /* line 158, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .sm-margin-bottom-20 {
    margin-bottom: 20px;
  }
  /* line 162, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .sm-margin-bottom-30 {
    margin-bottom: 30px;
  }
  /* line 166, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .sm-margin-bottom-40 {
    margin-bottom: 40px;
  }
  /* line 170, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .sm-margin-bottom-50 {
    margin-bottom: 50px;
  }
  /* line 174, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .sm-margin-bottom-60 {
    margin-bottom: 60px;
  }
}

@media (max-width: 992px) {
  /* line 180, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .md-margin-bottom-10 {
    margin-bottom: 10px;
  }
  /* line 184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .md-margin-bottom-20 {
    margin-bottom: 20px;
  }
  /* line 188, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .md-margin-bottom-30 {
    margin-bottom: 30px;
  }
  /* line 192, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .md-margin-bottom-40 {
    margin-bottom: 40px;
  }
  /* line 196, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .md-margin-bottom-50 {
    margin-bottom: 50px;
  }
  /* line 200, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
  .md-margin-bottom-60 {
    margin-bottom: 60px;
  }
}

/*Other Spaces*/
/* line 207, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-top-20 {
  margin-top: 20px;
}

/* line 211, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-left-5 {
  margin-left: 5px;
}

/* line 215, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-left-10 {
  margin-left: 10px;
}

/* line 219, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-right-5 {
  margin-right: 5px;
}

/* line 223, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-right-10 {
  margin-right: 10px;
}

/* line 227, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.margin-right-20 {
  margin-right: 20px;
}

/* line 231, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.padding-sm {
  padding: 40px 40px;
}

/* line 235, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.padding-top-5 {
  padding-top: 5px;
}

/* line 239, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.padding-left-5 {
  padding-left: 5px;
}

/* line 249, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.imageright, img[style*="float: right"], img[style*="float:right"] {
  float: right;
  margin: .5em 0 1em 1em;
}

/* line 253, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.imageleft, img[style*="float: left"], img[style*="float:left"] {
  float: left;
  margin: .5em 1em 1em 0;
}

/* line 272, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
img[style*="display: block; margin-left: auto; margin-right: auto;"] {
  margin: 1em 0;
}

/* line 278, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
.overflow-hidden {
  overflow: hidden;
}

/* line 282, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_helpers.scss */
[id] {
  scroll-margin-top: 100px;
}

/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.9.2
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*[-----*** TABLE OF CONTENT ***-----]

1. Promo Blocks
2. Breadcrumbs
  2.1 - Breadcrumbs v1
  2.2 - Breadcrumbs v2
  2.3 - Breadcrumbs v3
3. Image Hover Effects
4. Block Grid
  4.1 - Block Grid v1
  4.2 - Block Grid v2
5. Services Box v1
6. Service Block
  6.1 - Service Block v1
  6.2 - Service Block v2
  6.3 - Service Block v3
  6.4 - Service Block v4
  6.5 - Service Block v5
  6.6 - Service Block v6
  6.7 - Service Block v7
  6.8 - Service Block v8
  6.# - Colored Content Boxes
  6.# - Funny Boxes
7. Process
8. Team
	8.1 - Team v1
	8.2 - Team v2
	8.3 - Team v3
	8.4 - Team v4
	8.5 - Team v5
	8.6 - Team v6
	8.7 - Team v7
9. Call To Action
  9.1 - Call To Action v1
  9.2 - Call To Action v2
10. Portfolio Box
  10.1 - Portfolio Box v1
11. Portfolio Boxes
	11.1 Portfolio Box v1
	11.2 Portfolio Box v2
12. Thumbnail
13. Image Backgrounds
  13.1 - Image Backgrounds v1
  13.2 - Image Backgrounds v2
14. Parallax Quote
15. Parallax Team
16. Parallax Counter
  16.1 - Parallax Counter v1
  16.2 - Parallax Counter v2
  16.3 - Parallax Counter v3
  16.4 - Parallax Counter v4
17. Flat Background Block
18. Flat Testimonials
19. Testimonials
  19.1 - Testimonials v4
  19.2 - Testimonials v5
  19.3 - Testimonials v6
  19.4 - Other Testimonials
  19.5 - Other Testimonials 1
20. Featured Blog
21. Blog Comments
22. Quote
	22.1 - Quote v2
23. Team Social
24. Box Shadows
25. Interactive Slider
  25.1 - Interactive Slider v1
  25.2 - Interactive Slider v2
26. Blog Trending
27. Blog Latest Posts
28. Blog Photostream
29. Newsletter
30. Blog Post Quote
31. Blog Post Author
32. Blog Comments
33. News
  33.1 - News v1
  33.2 - News v2
  33.3 - News v3
34. Blog Grid
35. Blog Thumb
36. Shop Subscribe
37. Parallax Slider (Main)
38. Master Slider
39. Revolution Slider
40. Layer Slider
41. Clients Carousel
42. Pie Chart
43. Counter
44. Back To Top
45. Owl Carousel
	45.1 - Recent Works (Owl Carousel)
	45.2 - Parallax Twitter (Owl Carousel)
	45.3 - Clients Section (Owl Carousel)
	45.4 - Owl Video
46. Bootstrap Carousels
	46.1 - Bootstrap Carousel v1
	46.2 - Bootstrap Carousel v2
47. Tabs
	47.1 - Tab v1
	47.2 - Tab v2
	47.3 - Tab v3
	47.4 - Tab v4
	47.5 - Tab v5
	47.6 - Tab v6
48. Accordions
49. Paginations
50. Pagers
	50.1 - Pagers Default
	50.2 - Pagers v2
	50.3 - Pagers v3
	50.4 - Pagers v4
51. Sidebar Menu
	51.1 - Sidebar Menu v1
52. Content Boxes
	52.1 - Content Boxes v1
	52.2 - Content Boxes v2
	52.3 - Content Boxes v3
	52.4 - Content Boxes v4
	52.5 - Content Boxes v5
	52.6 - Content Boxes v6
53. Tagline Boxes
	53.1 - Tagline Boxes v1
	53.2 - Tagline Boxes v2
	53.3 - Tagline Boxes v3
	53.4 - Tagline Boxes v4
	53.5 - Tagline Boxes v5
	53.6 - Tagline Boxes v6
	53.7 - Tagline Boxes v6
54. Tags
	54.1 - Tags v1
	54.2 - Tags v2
	54.3 - Tags v3
	54.4 - Tags v4

##. Main Page Styles

-------------------------------------------------------------*/
/*--------------------------------------------------
	[1. Promo Blocks]
----------------------------------------------------*/
/*Promo Background Images*/
/* line 153, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-bg-pattern-v1-dark {
  background: url(../img/patterns/5.png) repeat;
}

/* line 157, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-bg-pattern-v1-light {
  background: url(../img/patterns/16.png) repeat;
}

/*Promo Background Images*/
/* line 163, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-bg-img-v1 {
  background: url(../img/bg/img1.jpg) no-repeat;
  background-size: cover;
}

/* line 168, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-bg-img-v2 {
  background: url(../img/bg/img5.jpg) no-repeat;
  background-size: cover;
}

/* line 173, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-bg-img-v3 {
  background: url(../img/bg/img10.jpg) no-repeat;
  background-size: cover;
}

/* line 178, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-bg-img-v4 {
  background: url(../img/bg/img11.jpg) no-repeat;
  background-size: cover;
}

/* line 183, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-bg-img-v5 {
  background: url(../img/bg/img6.jpg) no-repeat;
  background-size: cover;
}

/*Promo Background Fixed*/
/* line 190, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-bg-fixed {
  background-attachment: fixed;
}

/*Promo Texts v1*/
/* line 196, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-text-v1 {
  padding: 3px 0;
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
  border-bottom: solid 2px #fff;
}

/* line 203, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-text-v1.color-dark {
  border-color: #555;
}

/*Promo Texts v2*/
/* line 210, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-text-v2 {
  font-size: 55px;
  font-weight: 600;
  line-height: 63px;
}

/*Promo Video Icon*/
/* line 218, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-video-icon-wrap {
  width: 64px;
  height: 64px;
  font-size: 18px;
  line-height: 70px;
  display: inline-block;
  transition: all .3s ease-in-out;
}

/* line 226, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-video-icon-wrap:hover {
  color: #333;
  text-decoration: none;
}

/* line 231, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-video-icon-wrap.color-light {
  color: #333;
  background: white;
}

/* line 235, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-video-icon-wrap.color-light:hover {
  background: rgba(255, 255, 255, 0.9);
}

/* line 240, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-video-icon-wrap.color-dark {
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
}

/* line 244, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-video-icon-wrap.color-dark:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
}

/* line 251, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-video-icon {
  left: 3px;
  position: relative;
}

/*--------------------------------------------------
	[2. Breadcrumbs]
----------------------------------------------------*/
/*Default Breadcrumbs*/
/* line 261, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs {
  overflow: hidden;
  padding: 10px 0 6px;
  border-bottom: solid 1px #eee;
  background: url(../img/patterns/breadcrumbs.png) repeat;
}

/* line 268, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.video-business-img1 {
  background: url(../video/youtube-business/video-business-img1.jpg) no-repeat center;
  background-size: cover;
  /*Scales the background image to be as large as possible*/
}

/* line 275, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs h1 {
  color: #666;
  font-size: 22px;
  margin-top: 8px;
}

/* line 281, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumb {
  top: 10px;
  z-index: 1;
  padding-right: 0;
  background: none;
  position: relative;
}

/* line 288, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumb a {
  color: #777;
}

/* line 293, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumb li.active, .breadcrumb li a:hover {
  color: #72c02c;
  text-decoration: none;
}

@media (max-width: 550px) {
  /* line 302, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .breadcrumbs h1.pull-left, .breadcrumbs ul.pull-right {
    width: 100%;
    text-align: center;
  }
  /* line 307, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .breadcrumbs h1.pull-left {
    margin-bottom: 0;
  }
  /* line 311, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .breadcrumbs .breadcrumb {
    top: 0;
    margin-bottom: 10px;
  }
}

/*Breadcrumbs Dark and Light*/
/* line 321, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-dark {
  background: #222;
  padding: 30px 0 20px;
  border-bottom: solid 3px #777;
}

/* line 327, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-light {
  background: #222;
  padding: 30px 0 20px;
  border-bottom: solid 3px #777;
  background: #f7f7f7;
  border-bottom: 1px solid #eee;
}

/* line 336, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-dark h1, .breadcrumbs.breadcrumbs-dark a {
  color: #fff;
  font-weight: 200;
}

/* line 343, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-light h1, .breadcrumbs.breadcrumbs-light a {
  color: #fff;
  font-weight: 200;
}

/* line 348, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-light h1, .breadcrumbs.breadcrumbs-light a {
  color: #555;
}

/* line 353, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-dark h1 {
  font-size: 24px;
}

/* line 357, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-light h1 {
  font-size: 26px;
}

/*Breadcrumbs Sizes*/
/* line 364, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-sm {
  padding: 50px 0;
}

/* line 368, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-md {
  padding: 100px 0;
}

/* line 372, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-lg {
  padding: 200px 0;
}

/*Breadcrumbs v1 and v3
------------------------------------*/
/* line 379, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v1, .breadcrumbs-v3 {
  transition: all 0.8s ease-in-out;
}

/*Breadcrumbs v1
------------------------------------*/
/* line 386, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v1 {
  padding: 100px 0;
  position: relative;
  background: url(../img/breadcrumbs/img1.jpg) center no-repeat;
  background-size: cover;
  /*Scales the background image to be as large as possible*/
}

/* line 394, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v1:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
}

/* line 404, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v1 span, .breadcrumbs-v1 h1 {
  color: #fff;
  z-index: 1;
  position: relative;
}

/* line 410, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v1 span {
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 5px;
  display: inline-block;
}

/* line 417, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v1 h1 {
  font-size: 50px;
  margin-bottom: 0;
  line-height: 55px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  /* line 426, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .breadcrumbs-v1 {
    padding: 50px 0;
  }
  /* line 429, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .breadcrumbs-v1 h1 {
    font-size: 40px;
    line-height: 45px;
  }
}

/*Breadcrumbs v2
------------------------------------*/
/* line 439, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v2 {
  text-align: center;
  position: relative;
  background: url(../img/bg/11.jpg) no-repeat center;
}

/* line 445, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v2-in {
  padding: 80px 0;
  position: relative;
}

/* line 450, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v2 h1 {
  color: #fff;
  text-transform: uppercase;
}

/* line 456, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumb-v2 li a, .breadcrumb-v2 li.active {
  color: #fff;
}

/* line 460, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumb-v2 li i {
  color: #fff;
  min-width: 19px;
  padding: 3px 7px;
  margin-right: 5px;
  text-align: center;
  border: 1px solid #fff;
}

/*Breadcrumbs v3
------------------------------------*/
/* line 473, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v3 {
  z-index: 1;
  padding: 100px 0;
  position: relative;
}

/* line 478, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v3.breadcrumbs-lg {
  padding: 200px 0;
}

/* line 482, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v3.img-v1 {
  background: url(../img/breadcrumbs/img1.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 488, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v3.img-v2 {
  background: url(../img/breadcrumbs/img2.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 494, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v3.img-v3 {
  background: url(../img/breadcrumbs/img3.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 500, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v3:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
}

/* line 511, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v3 h1 {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
}

/* line 518, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.breadcrumbs-v3 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 0;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  /* line 528, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 100px 0;
  }
}

@media (max-width: 768px) {
  /* line 534, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .breadcrumbs-v3 {
    padding: 50px 0;
  }
  /* line 537, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 50px 0;
  }
  /* line 541, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .breadcrumbs-v3 h1 {
    font-size: 30px;
    line-height: 40px;
  }
  /* line 546, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .breadcrumbs-v3 p {
    font-size: 18px;
  }
}

/*--------------------------------------------------
	[3. Image Hover Effects]
----------------------------------------------------*/
/*Image Hover v1*/
/* line 558, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.img-hover-v1 span {
  display: block;
  overflow: hidden;
  position: relative;
}

/* line 563, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.img-hover-v1 span:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}

/* line 573, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.img-hover-v1:hover span:after {
  background: rgba(0, 0, 0, 0.3);
}

/* line 577, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.img-hover-v1 img {
  transition: all 0.5s ease-in-out;
}

/* line 582, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.img-hover-v2 img {
  transition: all 0.5s ease-in-out;
}

/* line 587, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.img-hover-v1 span:after {
  transition: all 0.5s ease-in-out;
}

/* line 591, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.img-hover-v1:hover img {
  -transform: scale(1.1);
}

/*Image Hover v2*/
/* line 599, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.img-hover-v2 img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

/* line 604, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.img-hover-v2:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}

/*--------------------------------------------------
	[4. Block Grid]
----------------------------------------------------*/
/*Block Grid v1
------------------------------------*/
/* line 616, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1 {
  background: #fff;
  padding: 15px 15px 5px;
  border-bottom: solid 2px #eee;
  transition: all 0.4s ease-in-out;
}

/* line 622, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1:hover {
  border-color: #72c02c;
}

/* line 626, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1 img {
  margin-bottom: 10px;
}

/* line 629, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1 img.block-grid-v1-img {
  width: 35px;
  height: 35px;
  margin-top: 8px;
}

/* line 636, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1.content-boxes-v1 img.pull-left {
  float: inherit !important;
}

/* line 640, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1 h3 a {
  font-size: 22px;
  font-weight: 200;
  margin-right: 10px;
}

/* line 646, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1.content-boxes-v1 h3 {
  margin-bottom: 0;
}

/* line 649, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1.content-boxes-v1 h3 a {
  color: #555;
}

/* line 654, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1 .star-vote {
  padding: 0;
  margin-bottom: 10px;
  vertical-align: middle;
}

/* line 659, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1 .star-vote li {
  padding: 0;
  font-size: 11px;
  display: inline-block;
}

/*Star Vote*/
/*Additional Info*/
/* line 671, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1-add-info {
  margin: 0;
  padding-top: 15px;
  border-top: solid 1px #eee;
}

/* line 676, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1-add-info li {
  padding: 0;
  margin-right: 3px;
  margin-bottom: 10px;
}

/* line 681, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v1-add-info li a {
  font-size: 11px;
  padding: 4px 7px;
  text-decoration: none;
  border: solid 2px #eee;
  border-radius: 3px !important;
}

/*Block Grid v2
------------------------------------*/
/* line 694, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v2 {
  padding: 0;
}

/* line 697, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v2 li {
  list-style: none;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}

/* line 706, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v2-info {
  padding: 15px;
  text-align: center;
  background: #f9f9f9;
  border-bottom: solid 3px #eee;
  transition: all 0.4s ease-in-out;
}

/* line 714, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v2 li:hover .block-grid-v2-info {
  border-color: #72c02c;
}

/* line 719, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v2-info h3 {
  font-size: 22px;
  font-weight: 200;
  margin-bottom: 10px;
}

/* line 725, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-grid-v2-info .star-vote li {
  padding: 2px;
  font-size: 16px;
}

/*--------------------------------------------------
	[5. Services Box]
----------------------------------------------------*/
/* Services Box v1
------------------------------------*/
/* line 738, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-box-v1 .service-block {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 20px;
}

/* line 744, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-box-v1 p {
  position: relative;
  margin-bottom: 50px;
}

/* line 748, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-box-v1 p:after {
  content: " ";
  left: 50%;
  height: 1px;
  width: 50px;
  bottom: -15px;
  position: absolute;
  margin-left: -25px;
  background: #72c02c;
}

/* line 760, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-box-v1 ul {
  padding: 0 40px;
}

/* line 764, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-box-v1 li {
  margin: 8px 0;
  font-size: 15px;
  padding-top: 8px;
  border-top: dotted 1px #72c02c;
}

/* line 770, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-box-v1 li:hover {
  color: #72c02c;
}

/* line 774, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-box-v1 li:first-child {
  padding-top: 0;
  border-top: none;
}

/*--------------------------------------------------
	[6. Service Block ]
----------------------------------------------------*/
/*Service Block v1
------------------------------------*/
/* line 787, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v1 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  padding: 45px 20px 20px;
  border: 1px solid #f2f2f2;
}

/* line 794, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v1 i {
  left: 50%;
  top: -30px;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 26px;
  line-height: 62px;
  margin-left: -30px;
  text-align: center;
  position: absolute;
  background: #72c02c;
  display: inline-block;
}

/* line 809, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v1 h3, .service-block-v1 p {
  margin-bottom: 20px;
}

/*Service Block v2 (used in page_404_error2.html)
------------------------------------*/
/* line 817, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v2 .service-block-in {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.2);
}

/* line 823, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v2 .service-block-in i {
  font-size: 40px;
}

/* line 827, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v2 .service-block-in h4 {
  line-height: 25px;
}

/* line 831, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v2 .service-block-in p {
  margin-bottom: 20px;
}

/* line 835, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v2 .service-block-in i, .service-block-v2 .service-block-in h4, .service-block-v2 .service-block-in p {
  color: #fff;
}

/*Service Block v3 (used in profile pages)
------------------------------------*/
/* line 843, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 {
  padding: 20px;
}

/* line 846, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 i {
  color: #fff;
  float: left;
  font-size: 50px;
  margin: 0 20px 20px 0;
}

/* line 853, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .service-heading {
  color: #fff;
  opacity: 0.8;
  line-height: 1;
}

/* line 860, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .service-in small {
  color: #fff;
  opacity: 0.8;
  line-height: 1;
}

/* line 866, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .service-in h4 {
  color: #fff;
}

/* line 871, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .counter {
  color: #fff;
}

/* line 875, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .service-heading {
  font-size: 16px;
  text-transform: uppercase;
}

/* line 880, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .counter {
  display: block;
  line-height: 1;
  font-size: 30px;
}

/* line 886, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .progress {
  margin-bottom: 7px;
}

/* line 891, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .service-in small {
  font-size: 16px;
  text-transform: uppercase;
}

/* line 896, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .service-in h4 {
  font-size: 16px;
  line-height: 0.8;
  margin-bottom: 0;
}

/* line 904, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .statistics .heading-xs {
  color: #fff;
  opacity: 0.8;
}

/* line 909, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .statistics small {
  color: #fff;
}

/* line 913, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .statistics .progress {
  background: #bbb;
}

/* line 917, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v3 .statistics .progress-bar-light {
  background: #fff;
}

/*Service Innner*/
/*Statistics*/
/*Service Block v4
------------------------------------*/
/* line 930, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v4 {
  position: relative;
  background: #f7f7f7;
}

/* line 934, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v4:after {
  top: 0;
  width: 0;
  height: 0;
  left: 50%;
  content: '';
  margin-left: -20px;
  position: absolute;
  border-style: solid;
  border-width: 25px 20px 0 20px;
  border-color: #fff transparent transparent transparent;
}

/* line 947, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v4 .service-desc {
  text-align: center;
}

/* line 950, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v4 .service-desc i {
  color: #72c02c;
  display: block;
  font-size: 30px;
  margin-bottom: 20px;
}

/* line 957, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v4 .service-desc h3 {
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

/*Service Block v5
------------------------------------*/
/* line 968, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v5 {
  text-align: center;
  background: #f7f7f7;
}

/* line 972, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v5 .service-inner {
  padding: 80px 40px;
}

/* line 975, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v5 .service-inner i {
  margin-bottom: 30px;
}

/* line 979, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v5 .service-inner span {
  display: block;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 15px;
  text-transform: uppercase;
}

/* line 988, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v5 .service-border {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

@media (max-width: 992px) {
  /* line 995, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .service-block-v5 .service-border {
    border-left: none;
    border-right: none;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
}

/*Service Block v6
------------------------------------*/
/* line 1007, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v6 i {
  float: left;
  margin: 5px 15px 0 0;
}

/* line 1012, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v6 .service-desc {
  overflow: hidden;
}

/* line 1015, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v6 .service-desc h2 {
  font-size: 18px;
  margin: 0 0 10px;
  text-transform: uppercase;
}

/* line 1021, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v6 .service-desc a {
  text-transform: uppercase;
}

/*Service Block v7
------------------------------------*/
/* line 1030, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v7 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  padding: 45px 20px 20px;
}

/* line 1037, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v7 i {
  left: 50%;
  top: -25px;
  color: #fff;
  width: 45px;
  height: 45px;
  font-size: 22px;
  line-height: 46px;
  text-align: center;
  position: absolute;
  background: #72c02c;
  margin-left: -22.5px;
  display: inline-block;
}

/* line 1052, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v7 h3, .service-block-v7 p {
  margin-bottom: 20px;
}

/*Service Block v8
------------------------------------*/
/* line 1060, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v8 {
  padding: 25px;
  background: #fff;
}

/* line 1064, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v8 i {
  color: #555;
  float: left;
  font-size: 22px;
  margin: 5px 25px 0 0;
}

/* line 1071, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v8 .service-block-desc {
  overflow: hidden;
}

/* line 1074, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v8 .service-block-desc h3 {
  font-size: 18px;
  margin: 0 0 30px;
  position: relative;
  text-transform: uppercase;
}

/* line 1080, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-v8 .service-block-desc h3:after {
  left: 0;
  top: 40px;
  height: 1px;
  width: 30px;
  content: " ";
  position: absolute;
  background: #72c02c;
}

/*Colored Content Boxes
------------------------------------*/
/* line 1096, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block {
  padding: 60px 30px;
  text-align: center;
  margin-bottom: 20px;
}

/* line 1101, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block p {
  color: #fff;
}

/* line 1105, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block h2 {
  color: #fff;
}

/* line 1108, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block h2 a:hover {
  text-decoration: none;
}

/* line 1114, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-light {
  background: #fafafa;
  border: solid 1px #eee;
}

/* line 1119, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-default {
  background: #fafafa;
  border: solid 1px #eee;
}

/* line 1123, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-default:hover {
  box-shadow: 0 0 8px #eee;
}

/* line 1129, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-light p, .service-block-light h2 {
  color: #555;
}

/* line 1135, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-default p, .service-block-default h2 {
  color: #555;
}

/* line 1140, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-u {
  background: #72c02c;
}

/* line 1144, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-blue {
  background: #3498db;
}

/* line 1148, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-red {
  background: #e74c3c;
}

/* line 1152, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-sea {
  background: #1abc9c;
}

/* line 1156, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-grey {
  background: #95a5a6;
}

/* line 1160, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-yellow {
  background: #f1c40f;
}

/* line 1164, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-orange {
  background: #e67e22;
}

/* line 1168, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-green {
  background: #2ecc71;
}

/* line 1172, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-purple {
  background: #9b6bcc;
}

/* line 1176, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-aqua {
  background: #27d7e7;
}

/* line 1180, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-brown {
  background: #9c8061;
}

/* line 1184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-dark-blue {
  background: #4765a0;
}

/* line 1188, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-light-green {
  background: #79d5b3;
}

/* line 1192, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-dark {
  background: #555;
}

/* line 1196, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-block-light {
  background: #ecf0f1;
}

/* Funny Boxes
------------------------------------*/
/* line 1203, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes {
  background: #f7f7f7;
  padding: 20px 20px 15px;
  transition: all 0.3s ease-in-out;
}

/* line 1208, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes h2 {
  margin-top: 0;
  font-size: 18px;
  line-height: 20px;
}

/* line 1213, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes h2 a {
  color: #555;
}

/* line 1218, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes p a {
  color: #72c02c;
}

/* line 1223, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes .funny-boxes-img li {
  font-size: 12px;
  margin-bottom: 2px;
}

/* line 1227, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes .funny-boxes-img li i {
  color: #72c02c;
  font-size: 12px;
  margin-right: 5px;
}

/* line 1234, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes .funny-boxes-img img {
  margin: 5px 10px 15px 0;
}

/* line 1239, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes ul.funny-boxes-rating li {
  display: inline-block;
}

/* line 1242, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes ul.funny-boxes-rating li i {
  color: #f8be2c;
  cursor: pointer;
  font-size: 14px;
}

/* line 1247, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes ul.funny-boxes-rating li i:hover {
  color: #f8be2c;
}

@media (max-width: 992px) {
  /* line 1255, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .funny-boxes .funny-boxes-img li {
    display: inline-block;
  }
}

/*Funny Colored Boxes*/
/* line 1263, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-colored p, .funny-boxes-colored h2 a {
  color: #fff;
}

/* line 1267, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-colored .funny-boxes-img li {
  color: #fff;
}

/* line 1270, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-colored .funny-boxes-img li i {
  color: #fff;
}

/*Red Funny Box*/
/* line 1278, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-red {
  background: #e74c3c;
}

/*Dark Red Funny Box*/
/* line 1284, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-purple {
  background: #9b6bcc;
}

/*Blue Funny Box*/
/* line 1290, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-blue {
  background: #3498db;
}

/*Grey Funny Box*/
/* line 1296, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-grey {
  background: #95a5a6;
}

/*Turquoise Funny Box*/
/* line 1302, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-sea {
  background: #1abc9c;
}

/*Turquoise Top Bordered Funny Box*/
/* line 1308, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-top-sea {
  border-top: solid 2px #1abc9c;
}

/* line 1311, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-top-sea:hover {
  border-top-color: #16a085;
}

/*Yellow Top Bordered Funny Box**/
/* line 1318, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-top-yellow {
  border-top: solid 2px #f1c40f;
}

/* line 1321, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-top-yellow:hover {
  border-top-color: #f39c12;
}

/*Red Top Bordered Funny Box**/
/* line 1328, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-top-red {
  border-top: solid 2px #e74c3c;
}

/* line 1331, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-top-red:hover {
  border-top-color: #c0392b;
}

/*Purple Top Bordered Funny Box**/
/* line 1338, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-top-purple {
  border-top: solid 2px #9b6bcc;
}

/* line 1341, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-top-purple:hover {
  border-top-color: #814fb5;
}

/*Orange Left Bordered Funny Box**/
/* line 1348, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-left-orange {
  border-left: solid 2px #e67e22;
}

/* line 1351, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-left-orange:hover {
  border-left-color: #d35400;
}

/*Green Left Bordered Funny Box**/
/* line 1358, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-left-green {
  border-left: solid 2px #72c02c;
}

/* line 1361, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-left-green:hover {
  border-left-color: #5fb611;
}

/*Blue Left Bordered Funny Box**/
/* line 1368, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-left-blue {
  border-left: solid 2px #3498db;
}

/* line 1371, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-left-blue:hover {
  border-left-color: #2980b9;
}

/*Dark Left Bordered Funny Box**/
/* line 1378, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-left-dark {
  border-left: solid 2px #555;
}

/* line 1381, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.funny-boxes-left-dark:hover {
  border-left-color: #333;
}

/*--------------------------------------------------
	[7. Process]
----------------------------------------------------*/
/*Process v1
------------------------------------*/
/* line 1393, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.process-v1 .process-in {
  text-align: center;
}

/* line 1396, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.process-v1 .process-in > li i {
  z-index: 1;
  width: 30px;
  height: 30px;
  position: relative;
  text-align: center;
  background: #72c02c;
  margin-bottom: 15px;
  display: inline-block;
  border: solid 9px #fff;
}

/* line 1407, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.process-v1 .process-in > li i:after {
  top: -9px;
  left: -9px;
  right: -9px;
  bottom: -9px;
  content: " ";
  border-radius: 50%;
  position: absolute;
  border: 1px solid #bbb;
}

/* line 1419, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.process-v1 .process-in .process-desc {
  padding: 0 20px;
}

/* line 1423, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.process-v1 .process-in > li h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px;
  text-transform: uppercase;
}

/* line 1431, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.process-v1 .process-border {
  position: relative;
}

/* line 1434, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.process-v1 .process-border:before {
  top: 15px;
  width: 75%;
  left: 12%;
  content: " ";
  display: block;
  position: absolute;
  border-top: solid 2px #ddd;
}

/*Process Description*/
/*Process Border*/
@media (max-width: 768px) {
  /* line 1451, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .process-v1 .process-border:before {
    border: none;
  }
}

/*--------------------------------------------------
	[8. Team]
----------------------------------------------------*/
/* Team v1
------------------------------------*/
/* line 1463, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 ul {
  position: relative;
}

/* line 1468, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > .team-img {
  position: relative;
}

/* line 1471, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: transparent;
  transition: all 0.4s ease-in-out;
}

/* line 1484, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li:hover > .team-img:after {
  background: rgba(0, 0, 0, 0.5);
}

/* line 1488, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li:hover > .team-img ul {
  top: 50%;
  visibility: visible;
}

/* line 1493, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li:hover > p:before {
  width: 100%;
  background: #72c02c;
}

/* line 1499, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > .team-img ul {
  left: 0;
  top: 45%;
  z-index: 1;
  padding: 0;
  width: 100%;
  height: 36px;
  list-style: none;
  margin-top: -18px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
}

/* line 1516, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > .team-img ul li {
  margin: 0 5px;
  text-align: center;
  display: inline-block;
}

/* line 1522, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > .team-img li i {
  color: #eee;
  border-width: 2px;
  border-color: #eee;
  transition: all 0.1s ease-in-out;
}

/* line 1528, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > .team-img li i.fa-twitter:hover {
  color: #fff;
  background: #3498db;
  border-color: #3498db;
}

/* line 1534, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > .team-img li i.fa-facebook:hover {
  color: #fff;
  background: #4765a0;
  border-color: #4765a0;
}

/* line 1540, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > .team-img li i.fa-google-plus:hover {
  color: #fff;
  background: #e74c3c;
  border-color: #e74c3c;
}

/* line 1548, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > h3 {
  color: #000;
  font-size: 18px;
  line-height: 20px;
  margin: 15px 0 7px;
  text-transform: uppercase;
}

/* line 1556, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > h4 {
  color: #999;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}

/* line 1563, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > p {
  margin: 20px 0;
  position: relative;
}

/* line 1567, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > p:before {
  left: 0;
  top: -10px;
  height: 1px;
  width: 40px;
  content: " ";
  position: absolute;
  background: #bbb;
  transition: all 0.4s ease-in-out;
}

/*Team Image*/
/*Team Socials*/
/*Team Discription*/
/*Team v2
------------------------------------*/
/* line 1591, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v2 {
  position: relative;
  margin-bottom: 20px;
}

/* line 1595, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v2 img {
  width: 100%;
}

/* line 1599, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v2 .inner-team {
  padding: 20px;
  background: #fff;
  text-align: center;
}

/* line 1604, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v2 .inner-team h3 {
  margin: 0;
}

/* line 1608, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v2 .inner-team small {
  display: block;
  font-size: 12px;
  margin-bottom: 7px;
}

/* line 1614, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v2 .inner-team p {
  font-size: 13px;
}

/* line 1618, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v2 .inner-team hr {
  margin: 10px 0 15px;
}

/*Team-Social (Temporary)*/
/* line 1626, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social {
  margin-bottom: 0;
}

/* line 1629, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social li {
  padding: 0 2px;
}

/* line 1632, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social li a {
  font-size: 14px;
  padding: 6px 4px;
  text-align: center;
  border-radius: 50% !important;
}

/* line 1639, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social li i {
  min-width: 20px;
}

/* line 1644, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social li a.fb {
  color: #4862a3;
  border: 1px solid #4862a3;
}

/* line 1649, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social li a.tw {
  color: #159ceb;
  border: 1px solid #159ceb;
}

/* line 1654, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social li a.gp {
  color: #dc4a38;
  border: 1px solid #dc4a38;
}

/* line 1659, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social li a:hover {
  text-decoration: none;
}

/* line 1663, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social li a.fb:hover {
  color: #fff;
  background: #4862a3;
}

/* line 1668, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social li a.tw:hover {
  color: #fff;
  background: #159ceb;
}

/* line 1673, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-social li a.gp:hover {
  color: #fff;
  background: #dc4a38;
}

/*Team v3
------------------------------------*/
/* line 1685, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-img {
  position: relative;
}

/* line 1688, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
}

/* line 1698, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-img:hover:after {
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
}

/* line 1703, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-img > img {
  width: 100%;
}

/* line 1708, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-hover {
  left: 0;
  top: 60%;
  z-index: 1;
  padding: 0;
  width: 100%;
  padding: 20px;
  list-style: none;
  margin-top: -90px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
}

/* line 1723, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-img:hover .team-hover {
  top: 50%;
  visibility: visible;
}

/* line 1729, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-hover span, .team-v3 .team-hover small {
  color: #fff;
  display: block;
}

/* line 1734, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-hover span {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}

/* line 1740, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-hover small {
  font-size: 13px;
  font-weight: 200;
  margin-bottom: 10px;
}

/* line 1746, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-hover p {
  color: #fff;
  font-weight: 200;
  margin-bottom: 20px;
}

/* line 1752, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-hover .team-social-v3 i {
  color: #fff;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border: 1px solid #fff;
}

/* line 1762, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v3 .team-hover .team-social-v3 i:hover {
  color: #666;
  background: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/*Team Hover Gradient*/
/*Team Social*/
/*Team v4
------------------------------------*/
/* line 1779, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v4 {
  text-align: center;
}

/* line 1782, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v4 img {
  width: 100%;
  margin-bottom: 20px;
}

/* line 1787, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v4 span {
  color: #333;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

/* line 1795, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v4 small {
  color: #999;
  display: block;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 10px;
}

/* line 1803, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v4 .team-social-v4 a {
  color: #555;
  font-size: 16px;
}

/* line 1807, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v4 .team-social-v4 a:hover {
  color: #72c02c;
  text-decoration: none;
}

/*Team v5
------------------------------------*/
/* line 1817, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 {
  text-align: center;
}

/* line 1820, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 span {
  color: #555;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

/* line 1828, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 small {
  display: block;
  color: #72c02c;
  font-size: 13px;
  font-style: italic;
  position: relative;
  margin-bottom: 25px;
}

/* line 1836, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 small:after {
  left: 50%;
  top: 30px;
  height: 1px;
  width: 30px;
  content: " ";
  background: #777;
  margin-left: -15px;
  position: absolute;
}

/* line 1848, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 .team-img {
  position: relative;
}

/* line 1851, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
}

/* line 1861, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 .team-img:hover:after {
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
}

/* line 1866, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 .team-img > img {
  width: 100%;
  margin-bottom: 20px;
}

/* line 1872, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 .team-hover {
  left: 0;
  top: 60%;
  z-index: 1;
  padding: 0;
  width: 100%;
  list-style: none;
  margin-top: -16px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
}

/* line 1886, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 .team-img:hover .team-hover {
  top: 50%;
  visibility: visible;
}

/* line 1891, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 .team-hover .team-social-v5 i {
  color: #fff;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border: 1px solid #fff;
}

/* line 1901, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v5 .team-hover .team-social-v5 i:hover {
  color: #666;
  background: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/*Team Hover Gradient*/
/*Social Network-*/
/*Team v6
------------------------------------*/
/* line 1917, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v6 {
  text-align: center;
}

/* line 1920, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v6 img {
  width: 100%;
  margin-bottom: 20px;
}

/* line 1925, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v6 span {
  color: #777;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

/* line 1933, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v6 small {
  display: block;
  color: #72c02c;
  font-size: 13px;
  margin-bottom: 15px;
}

/*Team v7
------------------------------------*/
/* line 1945, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-v7-in {
  padding: 0 50px;
}

/* line 1949, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-v7-name {
  color: #555;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

/* line 1957, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-v7-position {
  display: block;
  color: #72c02c;
  font-style: normal;
  margin-bottom: 15px;
}

/* line 1964, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
}

/* line 1970, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-v7-name {
  margin-top: 60px;
}

/* line 1974, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .social-icons-v1 {
  margin-bottom: 60px;
}

/* line 1978, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-v7-img {
  position: relative;
}

/* line 1981, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-v7-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
}

/* line 1992, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-arrow-right {
  position: relative;
}

/* line 1996, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-arrow-left {
  position: relative;
}

/* line 1999, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-arrow-left:before {
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -25px;
  z-index: 2;
  content: " ";
  position: absolute;
  border-style: solid;
}

/* line 2011, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-arrow-right:before {
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -25px;
  z-index: 2;
  content: " ";
  position: absolute;
  border-style: solid;
  right: -25px;
  border-width: 20px 0 20px 25px;
  border-color: transparent transparent transparent #fff;
}

/* line 2025, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v7 .team-arrow-left:before {
  left: -25px;
  border-width: 20px 25px 20px 0;
  border-color: transparent #fff transparent transparent;
}

/*Team Space*/
/*Team Image*/
/*Team Info*/
/*Team Info Media Queries*/
@media (max-width: 991px) {
  /*Team Arrows*/
  /* line 2043, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .team-v7 .team-arrow-left:before, .team-v7 .team-arrow-right:before {
    top: auto;
    left: 70px;
    bottom: -25px;
    border-width: 25px 20px 0 20px;
    border-color: #fff transparent transparent transparent;
  }
  /* line 2051, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .team-v7 .team-arrow-left:before, .team-v7 .team-arrow-right:before {
    left: 70px;
    bottom: -25px;
    border-width: 25px 20px 0 20px;
  }
  /* line 2057, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .team-v7 .equal-height-column {
    height: auto !important;
  }
  /*Equal Height Columns*/
}

/*--------------------------------------------------
	[9. Call To Action]
----------------------------------------------------*/
/*Call To Action v1
------------------------------------*/
/* line 2072, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.call-action-v1 p {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 0;
}

/* line 2079, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.call-action-v1.call-action-v1-boxed {
  padding: 0 25px;
}

/* line 2083, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.call-action-v1 .call-action-v1-box {
  width: 100%;
  display: table;
  padding: 30px 0;
  vertical-align: middle;
}

/* line 2090, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.call-action-v1 .call-action-v1-in {
  display: table-cell;
  vertical-align: middle;
}

/* line 2094, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.call-action-v1 .call-action-v1-in.inner-btn {
  padding-top: 1px;
  min-width: 250px;
  text-align: right;
}

@media (max-width: 992px) {
  /* line 2103, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .call-action-v1 .call-action-v1-in {
    display: block;
    text-align: center;
  }
  /* line 2107, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .call-action-v1 .call-action-v1-in.inner-btn {
    display: block;
    text-align: center;
  }
  /* line 2112, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .call-action-v1 .call-action-v1-in p {
    margin-bottom: 20px;
  }
}

/*Call To Action v2
------------------------------------*/
/* line 2121, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.call-action-v2 {
  padding: 50px 0;
  text-align: center;
  background: url(../img/patterns/16.png);
}

/* line 2126, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.call-action-v2 h2 {
  font-size: 22px;
  text-transform: uppercase;
}

/* line 2131, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.call-action-v2 p {
  padding: 0 100px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  /* line 2138, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .call-action-v2 p {
    padding: inherit;
  }
}

/*--------------------------------------------------
	[10. Portfolio Box]
----------------------------------------------------*/
/*Portfolio Box*/
@media (min-width: 991px) and (max-width: 1199px) {
  /* line 2148, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .portfolio-box {
    margin-bottom: 60px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  /* line 2154, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .portfolio-box {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  /* line 2160, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .portfolio-box {
    margin-bottom: 60px;
  }
}

/* line 2166, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box a.fancybox {
  overflow: hidden;
  position: relative;
}

/* line 2170, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box a.fancybox:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}

/* line 2179, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box a.fancybox:hover:after {
  background: rgba(0, 0, 0, 0.5);
}

/* line 2183, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box a.fancybox img {
  transition: all .5s ease;
}

/* line 2187, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box a.fancybox:hover img {
  transform: scale(1.1);
}

/* line 2192, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box .portfolio-box-in {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  color: #fff;
  background: #72c02c;
  font-size: 20px;
  text-align: center;
  line-height: 53px;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
}

/* line 2209, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box a.fancybox:hover .portfolio-box-in {
  visibility: visible;
}

/* line 2213, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box small.project-tag {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  color: #999;
}

/* line 2219, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box small.project-tag a {
  color: #999;
}

/* line 2223, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box small.project-tag i {
  margin-right: 5px;
}

/* line 2227, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box small.project-tag a:hover {
  color: #72c02c;
}

/*Project Tag*/
/*--------------------------------------------------
	[11. Portfolio Boxes]
----------------------------------------------------*/
/* Portfolio Box v1
------------------------------------*/
/* line 2241, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 {
  margin: 0;
}

/* line 2244, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 [class^="col-"] {
  padding: 0;
  overflow: hidden;
}

/* line 2249, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 li {
  background: #333;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}

/* line 2256, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}

/* line 2265, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 li:hover:after {
  background: rgba(0, 0, 0, 0.7);
}

/* line 2269, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 li:after, .portfolio-box-v1 li:hover:after {
  transition: all 0.3s ease-in-out;
}

/* line 2273, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 li img {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

/* line 2279, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 li:hover img {
  -transform: scale(1.1);
}

/* line 2284, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 .portfolio-box-v1-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  min-height: 150px;
  margin-top: -75px;
  text-align: center;
  position: absolute;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 2297, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 li:hover .portfolio-box-v1-in {
  visibility: visible;
}

/* line 2302, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 .portfolio-box-v1-in h3 {
  font-size: 22px;
  margin-top: 20px;
  position: relative;
  margin-bottom: 20px;
  text-transform: uppercase;
}

/* line 2309, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 .portfolio-box-v1-in h3:after {
  left: 50%;
  height: 1px;
  width: 40px;
  content: " ";
  bottom: -10px;
  background: #fff;
  margin-left: -20px;
  position: absolute;
}

/* line 2321, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 .portfolio-box-v1-in p, .portfolio-box-v1 .portfolio-box-v1-in h3 {
  color: #fff;
}

/* line 2325, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 .portfolio-box-v1-in p {
  font-size: 16px;
  margin-bottom: 20px;
}

/* line 2330, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v1 .portfolio-box-v1-in a.btn-u {
  font-weight: normal;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}

/*Portfolio Box v2
------------------------------------*/
/* line 2342, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 {
  margin: 0 0 60px;
}

/* line 2345, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 [class^="col-"] {
  padding: 0;
  overflow: hidden;
}

/* line 2350, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 li {
  background: #333;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}

/* line 2358, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}

/* line 2367, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 li:hover:after {
  background: rgba(255, 255, 255, 0.6);
}

/* line 2371, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 li:after, .portfolio-box-v2 li:hover:after {
  transition: all 0.3s ease-in-out;
}

/* line 2375, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 li img {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  -transform: scale(1.1);
}

/* line 2383, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 .portfolio-box-v2-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  min-height: 60px;
  margin-top: -30px;
  text-align: center;
  position: absolute;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 2396, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 li:hover .portfolio-box-v2-in {
  visibility: visible;
}

/* line 2400, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 .portfolio-box-v2-in i {
  color: #fff;
  width: 60px;
  height: 60px;
  padding: 20px;
  font-size: 22px;
  cursor: pointer;
  margin-right: 5px;
  text-align: center;
  display: inline-block;
  background: rgba(114, 192, 44, 0.8);
}

/* line 2412, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-box-v2 .portfolio-box-v2-in i:hover {
  background: #72c02c;
}

/*--------------------------------------------------
	[12. Thumbnail]
----------------------------------------------------*/
/*Thumbnail v1
------------------------------------*/
/* line 2425, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnails-v1 .thumbnail-img {
  margin-bottom: 12px;
}

/* line 2429, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnails-v1 h3 {
  margin-bottom: 5px;
}

/* line 2432, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnails-v1 h3 a {
  color: #555;
  font-size: 15px;
  text-transform: uppercase;
}

/* line 2439, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnails-v1 .read-more {
  color: #72c02c;
  font-size: 12px;
  text-transform: uppercase;
}

/*--------------------------------------------------
	[13. Image Backgrounds]
----------------------------------------------------*/
/* Image Backgrounds v1
------------------------------------*/
/* line 2452, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.bg-image-v1 {
  width: 100%;
  height: auto;
  padding: 50px 0;
  position: relative;
  background: url(../img/bg/11.jpg) repeat fixed;
  background-size: cover;
}

/* line 2460, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.bg-image-v1:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

/*Image Backgrounds v2
------------------------------------*/
/* line 2474, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.bg-image-v2 {
  width: 100%;
  height: auto;
  padding: 50px 0;
  position: relative;
  background: url(../img/bg/7.jpg) repeat fixed;
}

/* line 2481, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.bg-image-v2:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
}

/*Background Dark Cover*/
/* line 2495, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.bg-image-v2-dark:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

/* line 2505, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.bg-image-v2-dark h2, .bg-image-v2-dark p {
  color: #eee !important;
}

/*--------------------------------------------------
	[14. Parallax Quote]
----------------------------------------------------*/
/* line 2514, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote {
  color: #fff;
  padding: 100px 0;
  position: relative;
  text-align: center;
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
}

/* line 2521, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
}

/* line 2531, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: url(../img/patterns/gridtile.png) repeat;
}

/* line 2540, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote .container {
  margin: 0 !important;
  padding: 0 !important;
}

/*Parallax Quote Inner*/
/* line 2548, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote-in {
  z-index: 1;
  padding: 0 80px;
  position: relative;
}

/* line 2553, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote-in p {
  color: #fff;
  font-size: 28px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 2559, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote-in p:after, .parallax-quote-in p:before {
  content: ' " ';
  position: absolute;
  font-family: Tahoma;
}

/* line 2565, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote-in p:after {
  margin-left: 3px;
}

/* line 2569, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote-in p:before {
  margin-left: -15px;
}

/* line 2574, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote-in small {
  color: #bbb;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  /* line 2583, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .parallax-quote-in {
    padding: 0 40px;
  }
  /* line 2586, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .parallax-quote-in p {
    font-size: 20px;
  }
}

/*Parallax Quote Light*/
/* line 2595, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote-light:after {
  background: rgba(255, 255, 255, 0.9);
}

/* line 2599, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote-light .parallax-quote-in {
  background: #fff;
  padding: 30px 80px;
  border-bottom: solid 2px #ddd;
}

/* line 2604, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote-light .parallax-quote-in p {
  color: #555;
  font-size: 28px;
  font-weight: 200;
}

/* line 2610, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-quote-light .parallax-quote-in small {
  color: #555;
}

@media (max-width: 768px) {
  /* line 2617, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .parallax-quote-light .parallax-quote-in {
    padding: 30px 40px;
  }
  /* line 2620, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .parallax-quote-light .parallax-quote-in p {
    font-size: 20px;
  }
  /* line 2624, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .parallax-quote-light .parallax-quote-in small {
    font-weight: 200;
  }
}

/*--------------------------------------------------
	[15. Parallax Team]
----------------------------------------------------*/
/* line 2634, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-team {
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
  position: relative;
}

/* line 2638, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-team .title-box-v2 {
  position: relative;
}

/* line 2642, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-team:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
}

/* line 2653, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-team-dark:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
}

/*Parallax Team*/
/* line 2665, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-team {
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
  position: relative;
}

/* line 2669, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-team .title-box-v2 {
  position: relative;
}

/* line 2674, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-team-dark:before {
  background: rgba(0, 0, 0, 0.8);
}

/*--------------------------------------------------
	[16. Parallax Counter]
----------------------------------------------------*/
/* line 2682, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-bg {
  background: url(../img/patterns/5.png) repeat fixed;
}

/*Parallax Counter
------------------------------------*/
/* line 2689, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter {
  padding: 30px 0;
  background: url(../img/patterns/5.png) repeat fixed;
}

/* line 2693, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter .counters {
  color: #fff;
  padding: 15px 0;
}

/* line 2698, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter h4 {
  color: #999;
  font-size: 16px;
  text-transform: uppercase;
}

/* line 2704, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter span.counter {
  font-size: 48px;
}

/*Parallax Counter v1
------------------------------------*/
/* line 2712, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v1 {
  padding: 60px 0;
  background: url(../img/patterns/5.png) repeat fixed;
}

/* line 2716, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v1 .counters {
  color: #fff;
  padding: 15px 0 10px;
  border: solid 1px #555;
}

/* line 2722, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v1 h4 {
  color: #eee;
  font-size: 16px;
  text-transform: uppercase;
}

/* line 2728, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v1 span.counter {
  font-size: 42px;
  line-height: 48px;
}

/*Parallax Counter v2
------------------------------------*/
/* line 2737, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v2 {
  position: relative;
  padding: 80px 0 60px;
  background: url(../img/patterns/16.png) repeat fixed;
}

/* line 2742, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v2:after {
  top: 0;
  left: 50%;
  content: " ";
  margin-left: -25px;
  position: absolute;
  border-top: 25px solid #fff;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}

/* line 2753, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v2:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.02);
}

/* line 2763, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v2 .counters {
  padding: 20px 0;
  border-bottom: solid 2px #ddd;
  background: rgba(255, 255, 255, 0.9);
}

/* line 2768, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v2 .counters h4 {
  color: #555;
  font-weight: 200;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 2774, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v2 .counters span {
  color: #555;
  font-weight: 200;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 36px;
}

/* line 2781, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v2 .counters h4 {
  font-size: 22px;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  /* line 2789, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .parallax-counter-v2 .counters {
    margin-bottom: 0 !important;
  }
}

/*Parallax Counter v3
------------------------------------*/
/* line 2797, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v3 {
  z-index: 1;
  height: auto;
  padding: 80px 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: url(../img/bg/25.jpg) 50% 0 fixed;
}

/* line 2806, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v3:after {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  content: " ";
  height: 100%;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

/* line 2818, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v3 .features {
  width: 130px;
  height: 130px;
  padding: 25px;
  position: relative;
  text-align: center;
  display: inline-block;
  border-radius: 50% !important;
  background: rgba(255, 255, 255, 0.2);
}

/* line 2828, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v3 .features:after {
  top: -9px;
  left: -9px;
  right: -9px;
  bottom: -9px;
  content: " ";
  border-radius: 50%;
  position: absolute;
  border: 1px solid #fff;
}

/* line 2839, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v3 .features span {
  color: #fff;
  display: block;
  font-weight: 200;
}

/* line 2844, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v3 .features span.counter {
  font-size: 30px;
}

/* line 2848, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v3 .features span.features-info {
  text-transform: uppercase;
}

@media (max-width: 550px) {
  /* line 2856, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .parallax-counter-v3 .features {
    width: 110px;
    height: 110px;
    padding: 22px;
  }
  /* line 2861, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .parallax-counter-v3 .features:after {
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
  }
  /* line 2869, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .parallax-counter-v3 .features span.counter {
    font-size: 20px;
  }
  /* line 2873, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .parallax-counter-v3 .features span.features-info {
    font-size: 11px;
  }
}

/*Parallax Counter v4
------------------------------------*/
/* line 2883, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v4 {
  z-index: 1;
  height: auto;
  padding: 30px 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: url(../img/bg/19.jpg) 50% 0 fixed;
}

/* line 2892, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v4:after {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  content: " ";
  height: 100%;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

/* line 2904, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v4 i {
  color: #fff;
  display: block;
  font-size: 50px;
  margin-bottom: 20px;
}

/* line 2911, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v4 span.counter {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 10px;
}

/* line 2918, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-counter-v4 h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 200;
  text-transform: uppercase;
}

/*--------------------------------------------------
	[17. Flat Background Block]
----------------------------------------------------*/
/* line 2930, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-bg-block-v1 {
  padding: 40px 0;
  background: url(../img/patterns/16.png) repeat;
}

/* line 2935, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-bg-block-v1 .checked-list.first-child {
  float: left;
  margin-right: 100px;
}

/* line 2940, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-bg-block-v1 .checked-list li {
  color: #888;
  margin-bottom: 5px;
}

/* line 2945, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-bg-block-v1 .checked-list i {
  color: #72c02c;
  margin-right: 5px;
}

/* line 2951, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-bg-block-v1 img.img-over {
  top: -70px;
  right: 10px;
  position: absolute;
}

/*Image Over*/
@media (min-width: 991px) and (max-width: 1199px) {
  /* line 2961, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .flat-bg-block-v1 img.img-over {
    top: -18px;
  }
}

@media (max-width: 991px) {
  /* line 2967, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .flat-bg-block-v1 img.img-over {
    position: static;
  }
}

/*--------------------------------------------------
	[18. Flat Testimonials]
----------------------------------------------------*/
/* line 2976, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-testimonials {
  padding: 50px 0;
  background-color: #333;
}

/* line 2980, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-testimonials .flat-testimonials-in {
  padding: 30px;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
}

/* line 2985, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-testimonials .flat-testimonials-in img {
  width: 135px;
  height: 135px;
  margin: 0 auto 25px;
}

/* line 2991, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-testimonials .flat-testimonials-in h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 200;
}

/* line 2997, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-testimonials .flat-testimonials-in span {
  display: block;
  margin-bottom: 5px;
}

/* line 3002, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-testimonials .flat-testimonials-in p {
  color: #fff;
  font-weight: 200;
  position: relative;
}

/* line 3007, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-testimonials .flat-testimonials-in p:before, .flat-testimonials .flat-testimonials-in p:after {
  top: 5px;
  font-size: 22px;
  line-height: 10px;
  position: relative;
}

/* line 3014, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-testimonials .flat-testimonials-in p:before {
  content: "“";
  padding-right: 5px;
}

/* line 3019, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.flat-testimonials .flat-testimonials-in p:after {
  content: "”";
  padding-left: 5px;
}

/*--------------------------------------------------
	[19. Testimonials]
----------------------------------------------------*/
/* Testimonials v4
------------------------------------*/
/* line 3034, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v4 .testimonials-v4-in {
  background: #fff;
  position: relative;
  margin-bottom: 40px;
  padding: 20px 20px 20px 55px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
}

/* line 3041, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v4 .testimonials-v4-in:before {
  color: #bbb;
  font-size: 60px;
  content: "\201C";
  position: absolute;
  margin: -25px 0 0 -40px;
  font-family: Georgia, serif;
}

/* line 3050, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v4 .testimonials-v4-in:after {
  width: 0;
  height: 0;
  left: 80px;
  content: " ";
  bottom: -30px;
  position: absolute;
  border-style: solid;
  border-width: 30px 30px 0 0;
  border-color: #fff transparent transparent transparent;
  -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
}

/* line 3064, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v4 .testimonials-v4-in p {
  color: #777;
  font-size: 14px;
  font-style: italic;
}

/* line 3071, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v4 img {
  float: left;
  width: 60px;
  height: auto;
  margin: 0 20px;
}

/* line 3078, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v4 .testimonials-author {
  color: #555;
  display: block;
  font-size: 14px;
  overflow: hidden;
  padding-top: 8px;
  text-transform: uppercase;
}

/* line 3086, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v4 .testimonials-author em {
  font-size: 13px;
  text-transform: capitalize;
}

/*Testimonials v5
------------------------------------*/
/* line 3096, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v5 {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* line 3101, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v5 .testimonials-desc span {
  font-size: 13px;
  font-weight: 200;
}

/* line 3106, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v5 .testimonials-desc p {
  padding: 15px;
  position: relative;
  background: #f9f9f9;
  margin-bottom: 25px;
}

/* line 3112, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v5 .testimonials-desc p:after {
  width: 0;
  height: 0;
  left: 30px;
  color: #fff;
  bottom: -12px;
  content: " ";
  text-align: center;
  position: absolute;
  border-style: solid;
  border-width: 13px 10px 0 10px;
  border-color: #f9f9f9 transparent transparent transparent;
}

/* line 3128, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v5 .testimonials-by {
  margin-left: 20px;
}

/* line 3131, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v5 .testimonials-by img {
  float: left;
  width: 40px;
  height: auto;
  margin-right: 15px;
}

/* line 3138, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v5 .testimonials-by small {
  color: #72c02c;
  font-size: 12px;
}

/* line 3143, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v5 .testimonials-by span {
  text-transform: uppercase;
}

/*Testimonials By*/
/*Testimonials v6
------------------------------------*/
/* line 3155, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v6.testimonials-wrap {
  padding-left: 70px;
  padding-right: 70px;
}

/* line 3160, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v6 .testimonials-info {
  padding: 20px;
  background: #fff;
  border-bottom: solid 3px #eee;
  transition: all 0.4s ease-in-out;
}

/* line 3166, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v6 .testimonials-info:hover {
  border-color: #72c02c;
}

/* line 3170, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v6 .testimonials-info img {
  float: left;
  width: 80px;
  height: auto;
  margin-right: 20px;
}

/* line 3178, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v6 .testimonials-desc {
  overflow: hidden;
}

/* line 3181, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v6 .testimonials-desc p {
  margin-bottom: 15px;
}

/* line 3185, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v6 .testimonials-desc strong, .testimonials-v6 .testimonials-desc span {
  color: #555;
  display: block;
}

@media (max-width: 768px) {
  /* line 3193, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .testimonials-v6 .testimonials-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}

/*Other Testimonials
------------------------------------*/
/* Testimonials v3, v4 */
/* line 3204, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section3 .owl-stage-outer {
  margin-right: 1px;
}

/* line 3208, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section3 .testimonials-item {
  border-right: solid 1px #eee;
  padding: 15px 15px 20px;
}

/* line 3214, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section4 .testimonials-item {
  border-right: solid 1px #eee;
  padding: 15px 15px 20px;
}

/* line 3218, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section4 .testimonials-item:last-child {
  border-right: none;
}

/* line 3223, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section3 img, .testimonials-section4 img {
  width: 74px !important;
  height: 74px !important;
  border-radius: 50%;
  background: #fff;
  padding: 3px;
  border: solid 1px #E6E6E6;
  margin: 0 auto 5px;
  display: block;
}

/* line 3234, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section3 blockquote, .testimonials-section4 blockquote {
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.5;
  margin-bottom: 20px;
  border: none;
}

/* line 3243, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section3 blockquote p, .testimonials-section4 blockquote p {
  z-index: 9;
  position: relative;
  margin-bottom: 5px;
}

/* line 3250, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section3 .testimonials-item strong, .testimonials-section3 .testimonials-item em {
  display: block;
  font-size: 14px;
  font-weight: 700;
}

/* line 3258, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section4 .testimonials-item strong, .testimonials-section4 .testimonials-item em {
  display: block;
  font-size: 14px;
  font-weight: 700;
}

/* line 3265, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section3 .testimonials-item strong, .testimonials-section4 .testimonials-item strong {
  padding-top: 10px;
}

/* line 3269, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-section3 .testimonials-item em, .testimonials-section4 .testimonials-item em {
  font-style: normal;
  opacity: 0.7;
  font-weight: 400;
}

/* Other Testimonials 1
------------------------------------*/
/*Testimonials*/
/* line 3279, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials {
  margin-bottom: 10px;
}

/* line 3282, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .testimonial-info {
  color: #72c02c;
  font-size: 16px;
  padding: 0 15px;
  margin-top: 18px;
}

/* line 3288, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .testimonial-info span {
  top: 3px;
  position: relative;
}

/* line 3293, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .testimonial-info em {
  color: #777;
  display: block;
  font-size: 13px;
}

/* line 3299, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .testimonial-info img {
  width: 60px;
  float: left;
  height: 60px;
  padding: 2px;
  margin-right: 15px;
  border: solid 1px #ccc;
}

/* line 3309, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .testimonial-author {
  overflow: hidden;
}

/* line 3313, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .carousel-arrow {
  top: -65px;
  position: relative;
}

/* line 3317, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .carousel-arrow i {
  color: #777;
  padding: 2px;
  min-width: 25px;
  font-size: 20px;
  text-align: center;
  background: #f5f5f5;
}

/* line 3325, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .carousel-arrow i:hover {
  color: #fff;
  background: #72c02c;
}

/* line 3332, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .carousel-control {
  opacity: 1;
  width: 100%;
  text-align: right;
  text-shadow: none;
  position: absolute;
  filter: Alpha(opacity=100);
  /*For IE*/
}

/* line 3342, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .carousel-control.left {
  right: 27px;
  left: auto;
}

/* line 3347, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials .carousel-control.right {
  right: 0px;
}

/* line 3352, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-v1 .item p {
  position: relative;
}

/* line 3355, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-v1 .item p:after, .testimonials.testimonials-v1 .item p:before {
  left: 80px;
  bottom: -20px;
}

/* line 3360, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-v1 .item p:after {
  border-top: 22px solid;
  border-left: 0 solid transparent;
  border-right: 22px solid transparent;
}

/* line 3368, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-v2 .testimonial-info {
  padding: 0 20px;
}

/* line 3372, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-v2 p {
  padding-bottom: 15px;
}

/* line 3376, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-v2 .carousel-arrow {
  top: -55px;
}

/* line 3381, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-v2 .item p:after, .testimonials.testimonials-v2 .item p:before {
  left: 8%;
  bottom: 45px;
}

/* line 3386, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-v2 .item p:after {
  border-top: 20px solid;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
}

/* line 3394, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-v1 p, .testimonials.testimonials-v2 p {
  padding: 15px;
  font-size: 14px;
  font-style: italic;
  background: #f5f5f5;
}

/* line 3401, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-v1 .item p:after, .testimonials.testimonials-v2 .item p:after {
  width: 0;
  height: 0;
  content: " ";
  display: block;
  position: absolute;
  border-top-color: #f5f5f5;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/
}

/*Testimonials v1*/
/*Testimonials v2*/
/*General Testimonials v1/v2*/
/*Testimonials Backgrounds*/
/* line 3425, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bg-dark .item p, .testimonials-bg-default .item p {
  color: #fff;
  font-weight: 200;
}

/* line 3430, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bg-dark .carousel-arrow i {
  color: #fff;
}

/* line 3435, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bg-default .carousel-arrow i {
  color: #fff;
}

/* line 3439, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bg-default .item p {
  background: #72c02c;
}

/*Testimonials Default*/
/* line 3446, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-bg-default .item p:after {
  border-top-color: #72c02c;
}

/* line 3450, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bg-default .carousel-arrow i {
  background: #72c02c;
}

/* line 3454, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-bg-default .carousel-arrow i:hover {
  background: #5fb611;
}

/*Testimonials Dark*/
/* line 3460, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bg-dark .item p {
  background: #555;
}

/* line 3464, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-bg-dark .item p:after {
  border-top-color: #555;
}

/* line 3468, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bg-dark .carousel-arrow i {
  color: #fff;
  background: #555;
}

/* line 3474, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-bg-dark .carousel-arrow i:hover {
  background: #333;
}

/* line 3478, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials.testimonials-bg-dark .testimonial-info {
  color: #555;
}

/*--------------------------------------------------
	[20. Featured Blog]
----------------------------------------------------*/
/* line 3488, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.featured-blog h2 {
  font-size: 18px;
  margin: 0 0 25px;
  line-height: 25px;
  position: relative;
}

/* line 3494, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.featured-blog h2:after {
  left: 0;
  z-index: 1;
  width: 30px;
  height: 2px;
  content: " ";
  bottom: -10px;
  position: absolute;
  background: #72c02c;
}

/* line 3506, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.featured-blog .featured-img {
  position: relative;
}

/* line 3509, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.featured-blog .featured-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
}

/* line 3519, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.featured-blog .featured-img:hover:after {
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
}

/* line 3524, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.featured-blog .featured-img > img {
  width: 100%;
}

/* line 3528, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.featured-blog .featured-img i {
  top: 60%;
  left: 50%;
  z-index: 1;
  width: 100%;
  list-style: none;
  text-align: center;
  visibility: hidden;
  position: absolute;
  margin: -17.5px 0 0 -17.5px;
  transition: all 0.2s ease-in-out;
}

/* line 3541, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.featured-blog .featured-img:hover i {
  top: 50%;
  visibility: visible;
}

/* line 3546, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.featured-blog .featured-img i {
  color: #fff;
  width: 35px;
  height: 35px;
  padding: 11px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  background: rgba(255, 255, 255, 0.4);
}

/* line 3556, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.featured-blog .featured-img i:hover {
  color: #fff;
  background: #72c02c;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/*Team Hover Gradient*/
/*--------------------------------------------------
	[21. Blog Comments]
----------------------------------------------------*/
/* line 3573, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments img {
  width: 80px;
  height: auto;
}

/* line 3578, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments .comments-itself {
  background: #fff;
  padding: 20px 30px;
  position: relative;
}

/* line 3583, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments .comments-itself:before {
  width: 0;
  height: 0;
  top: 20px;
  left: -17px;
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 15px 17px 15px 0;
  border-color: transparent #fff transparent transparent;
}

/* line 3595, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments .comments-itself h3 {
  margin: 0 0 10px;
}

/* line 3599, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments .comments-itself span {
  color: #999;
  float: right;
  font-size: 13px;
}

/* line 3605, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments .comments-itself p {
  color: #999;
}

/* line 3610, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments.blog-comments-reply {
  margin-left: 70px;
}

/*Comments Reply*/
/*Media Queries*/
@media (max-width: 768px) {
  /* line 3620, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .blog-comments .comments-itself:before {
    display: none;
  }
  /* line 3624, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .blog-comments .comments-itself:after {
    width: 0;
    height: 0;
    top: -17px;
    left: 20px;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 0 15px 17px 15px;
    border-color: transparent transparent #fff transparent;
  }
}

/*--------------------------------------------------
	[22. Quote]
----------------------------------------------------*/
/* line 3642, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.quote-v1 {
  padding: 170px 0 150px;
  text-align: center;
  background: url(../img/patterns/5.png) repeat;
}

/* line 3647, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.quote-v1 p {
  color: #fff;
  font-size: 22px;
  font-weight: 200;
  padding: 0 100px;
  position: relative;
  margin-bottom: 20px;
}

/* line 3655, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.quote-v1 p:before {
  color: #72c02c;
  font-size: 60px;
  content: "\201C";
  position: absolute;
  margin: -30px 0 0 -40px;
  font-family: Georgia, serif;
}

/* line 3665, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.quote-v1 span {
  color: #fff;
  font-size: 18px;
}

@media (max-width: 768px) {
  /* line 3672, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .quote-v1 p {
    font-size: 18px;
    padding: 0 50px;
  }
}

/*Quote v2
------------------------------------*/
/* line 3681, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.quote-v2 {
  text-align: center;
  padding: 85px 10% 40px;
}

/* line 3685, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.quote-v2 p {
  color: #555;
  font-size: 16px;
  font-style: italic;
  position: relative;
  margin-bottom: 20px;
}

/* line 3692, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.quote-v2 p:before {
  left: 50%;
  top: -75px;
  font-size: 80px;
  content: "\201C";
  position: absolute;
  margin-left: -25px;
  font-family: Georgia, serif;
}

/* line 3703, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.quote-v2 span {
  color: #555;
  font-weight: 400;
}

/*--------------------------------------------------
	[23. Team Social]
----------------------------------------------------*/
/* line 3713, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.social-icons-v1 i {
  color: #555;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  background: #f7f7f7;
  display: inline-block;
}

/* line 3723, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.social-icons-v1 i:hover {
  color: #fff;
  background: #72c02c;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/* line 3731, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.social-icons-v1--dark i {
  color: #fff;
  background: #333;
}

/*--------------------------------------------------
	[24. Box Shadows]
----------------------------------------------------*/
/* line 3740, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shadow-wrapper {
  z-index: 1;
  position: relative;
}

/*Common Style*/
/* line 3747, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.box-shadow {
  background: #fff;
  position: relative;
}

/* line 3751, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.box-shadow:after, .box-shadow:before {
  top: 80%;
  left: 5px;
  width: 50%;
  z-index: -1;
  content: "";
  bottom: 15px;
  max-width: 300px;
  background: #999;
  position: absolute;
}

/*Effect 1*/
/* line 3766, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shadow-effect-1 {
  box-shadow: 0 10px 6px -6px #bbb;
}

/*Effect 2,3,4*/
/* line 3772, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shadow-effect-2 {
  position: relative;
}

/* line 3775, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shadow-effect-2:after, .shadow-effect-2:before {
  transform: rotate(-3deg);
  box-shadow: 0 15px 10px #999;
}

/* line 3781, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shadow-effect-3:before, .shadow-effect-4:after {
  transform: rotate(-3deg);
  box-shadow: 0 15px 10px #999;
}

/* line 3786, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shadow-effect-2:after, .shadow-effect-4:after {
  left: auto;
  right: 5px;
  transform: rotate(3deg);
}

/*Effect 5*/
/* line 3794, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shadow-effect-5 {
  box-shadow: 0 0 2px #ccc;
}

/*--------------------------------------------------
	[25. Interactive Slider]
----------------------------------------------------*/
/* Interactive Slider v1
------------------------------------*/
/* line 3804, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v1 {
  z-index: 1;
  padding: 130px 0;
  position: relative;
}

/* line 3809, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v1.img-v1 {
  background: url(../img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 3815, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v1.img-v2 {
  background: url(../img/bg/19.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 3821, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v1.img-v3 {
  background: url(../img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 3827, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v1:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
}

/* line 3838, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v1 h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
}

/* line 3845, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v1 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  /* line 3855, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .interactive-slider-v1 h2 {
    font-size: 30px;
  }
  /* line 3859, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .interactive-slider-v1 p {
    font-size: 18px;
  }
}

/*Interactive Slider v2
------------------------------------*/
/* line 3868, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v2 {
  z-index: 1;
  padding: 200px 0;
  position: relative;
  text-align: center;
  background: url(../img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 3878, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v2-md {
  padding: 250px 0;
}

/* line 3883, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v2.img-v1 {
  background: url(../img/bg/25.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 3889, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v2.img-v2 {
  background: url(../img/bg/14.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 3895, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v2.img-v3 {
  background: url(../img/bg/img8.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 3901, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v2.img-v4 {
  background: url(../img/bg/img9.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/* line 3907, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v2.intro-promo-img {
  padding: 80px 0;
  background: url(../img/intro/promo.jpg) repeat-x fixed center center/cover;
  -webkit-animation: infiniteBackground 65s linear infinite;
  -moz-animation: infiniteBackground 65s linear infinite;
  animation: infiniteBackground 65s linear infinite;
}

/* line 3915, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v2:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 992px) {
  /* line 3928, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .interactive-slider-v2.intro-promo-img {
    -webkit-animation: none;
    -moz-animation: none;
    animation: none;
  }
}

@keyframes infiniteBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1024px 0;
  }
}

@-webkit-keyframes infiniteBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1024px 0;
  }
}

@-moz-keyframe infiniteBackground {
  /* line 3956, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  from {
    background-position: 0 0;
  }
  /* line 3960, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  to {
    background-position: 1024px 0;
  }
}

/* line 3965, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.intro-promo-cover-dark:after {
  background: rgba(0, 0, 0, 0.8);
}

/* line 3969, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.intro-promo-cover-light:after {
  background: rgba(255, 255, 255, 0.9);
}

/* line 3974, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v2 h1 {
  color: #fff;
  font-size: 70px;
  font-weight: 600;
  line-height: 80px;
  text-transform: uppercase;
}

/* line 3982, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.interactive-slider-v2 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  font-family: "Open Sans", Arial, sans-serif;
}

@media (max-width: 768px) {
  /* line 3991, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .interactive-slider-v2 h1 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
  }
}

/*--------------------------------------------------
	[26. Blog Trending]
----------------------------------------------------*/
/* line 4003, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-trending li {
  margin-bottom: 20px;
}

/* line 4007, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-trending h3 {
  font-size: 14px;
  margin: 0 0 5px;
  line-height: 17px;
}

/* line 4013, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-trending small {
  color: #777;
}

/* line 4016, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-trending small a {
  color: #777;
}

/* line 4019, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-trending small a:hover {
  color: #72c02c;
}

/*--------------------------------------------------
	[27. Blog Latest Posts]
----------------------------------------------------*/
/* line 4031, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-latest-posts li {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}

/* line 4036, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-latest-posts li:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}

/* line 4043, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-latest-posts h3 {
  font-size: 14px;
  margin: 0 0 3px;
  line-height: 20px;
}

/* line 4049, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-latest-posts small {
  color: #777;
  margin-bottom: 8px;
  display: inline-block;
}

/* line 4052, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-latest-posts small a {
  color: #777;
}

/* line 4060, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-latest-posts p {
  font-size: 12px;
}

/*--------------------------------------------------
	[28. Blog Photostream]
----------------------------------------------------*/
/* line 4069, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-photostream {
  margin-right: -5px;
}

/* line 4072, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-photostream li {
  padding: 0;
  margin: 3px 5px 5px;
  position: relative;
}

/* line 4077, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-photostream li img {
  width: 78px;
  height: auto;
}

/*--------------------------------------------------
	[29. Newsletter]
----------------------------------------------------*/
/* line 4089, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-newsletter p {
  color: #555;
  margin-bottom: 15px;
}

/* line 4094, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-newsletter .form-control {
  border: none;
}

/* line 4097, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-newsletter .form-control:focus {
  box-shadow: none;
}

/*--------------------------------------------------
	[30. Blog Post Quote]
----------------------------------------------------*/
/* line 4107, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-post-quote {
  padding: 35px 60px;
  background: #fff;
  text-align: center;
}

/* line 4112, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-post-quote p {
  color: #555;
  font-size: 32px;
  font-weight: 200;
  line-height: 45px;
  position: relative;
  margin-bottom: 20px;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 4121, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-post-quote p:before, .blog-post-quote p:after {
  top: -3px;
  font-size: 30px;
  position: relative;
}

/* line 4127, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-post-quote p:before {
  left: -10px;
  content: "\0022";
}

/* line 4132, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-post-quote p:after {
  right: -10px;
  content: "\0022";
}

/* line 4138, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-post-quote span {
  color: #555;
  font-size: 20px;
  font-weight: 200;
}

@media (max-width: 768px) {
  /* line 4146, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .blog-post-quote {
    padding: 35px 25px;
  }
  /* line 4149, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .blog-post-quote p {
    font-size: 26px;
    line-height: 35px;
  }
}

/*--------------------------------------------------
	[31. Blog Post Author]
----------------------------------------------------*/
/* line 4160, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-author {
  padding: 20px;
  background: #fff;
}

/* line 4164, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-author img {
  float: left;
  width: 80px;
  height: auto;
  margin-right: 20px;
}

/* line 4171, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-author .blog-author-desc {
  overflow: hidden;
}

/* line 4174, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-author .blog-author-desc h4 {
  float: left;
  font-size: 18px;
  margin: 0 0 10px;
  text-transform: uppercase;
}

/* line 4181, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-author .blog-author-desc ul {
  float: right;
  margin-top: 6px;
}

/* line 4186, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-author .blog-author-desc p {
  color: #555;
}

/*--------------------------------------------------
	[32. Blog Comments]
----------------------------------------------------*/
/* line 4197, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments img {
  width: 80px;
  height: auto;
}

/* line 4202, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments .comments-itself {
  background: #fff;
  padding: 20px 30px;
  position: relative;
}

/* line 4207, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments .comments-itself:before {
  width: 0;
  height: 0;
  top: 20px;
  left: -17px;
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 15px 17px 15px 0;
  border-color: transparent #fff transparent transparent;
}

/* line 4219, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments .comments-itself h3 {
  margin: 0 0 10px;
}

/* line 4223, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments .comments-itself span {
  color: #555;
  float: right;
  font-size: 13px;
}

/* line 4229, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments .comments-itself p {
  color: #555;
}

/* line 4234, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-comments.blog-comments-reply {
  margin-left: 70px;
}

/*Comments Reply*/
/*Media Queries*/
@media (max-width: 768px) {
  /* line 4244, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .blog-comments .comments-itself:before {
    display: none;
  }
  /* line 4248, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .blog-comments .comments-itself:after {
    width: 0;
    height: 0;
    top: -17px;
    left: 20px;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 0 15px 17px 15px;
    border-color: transparent transparent #fff transparent;
  }
}

/*--------------------------------------------------
	[33. News]
----------------------------------------------------*/
/*News v1
------------------------------------*/
/* line 4268, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-section {
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

/* line 4274, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v1 .news-v1-in {
  background: #fafafa;
}

/* line 4278, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v1 h3 {
  margin-bottom: 0;
  font-weight: 200;
  line-height: 28px;
  padding: 10px 15px 15px;
}

/* line 4284, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v1 h3 a {
  color: #555;
}

/* line 4287, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v1 h3 a:hover {
  color: #72c02c;
}

/* line 4293, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v1 p {
  color: #555;
  padding: 0 15px;
  margin-bottom: 20px;
}

/* line 4299, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v1 .news-v1-info {
  margin-left: 0;
  margin-top: 30px;
  overflow: hidden;
  padding: 8px 10px;
  border-top: solid 1px #eee;
}

/* line 4306, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v1 .news-v1-info li {
  font-size: 12px;
}

/* line 4309, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v1 .news-v1-info li a:hover {
  color: #72c02c;
  text-decoration: none;
}

/*News v2
------------------------------------*/
/*News Badge*/
/* line 4322, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v2 .news-v2-badge {
  overflow: hidden;
  position: relative;
}

/* line 4326, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v2 .news-v2-badge p {
  left: 0;
  top: 20px;
  background: #fff;
  padding: 5px 15px;
  text-align: center;
  position: absolute;
}

/* line 4335, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v2 .news-v2-badge span {
  color: #555;
  display: block;
  font-size: 16px;
  line-height: 16px;
}

/* line 4342, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v2 .news-v2-badge small {
  color: #555;
  display: block;
  font-size: 10px;
  text-transform: uppercase;
}

/* line 4350, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v2 .news-v2-desc {
  padding: 20px;
  background: #fff;
}

/* line 4354, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v2 .news-v2-desc h3 {
  margin: 0 0 3px;
  font-size: 16px;
}

/* line 4358, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v2 .news-v2-desc h3 a {
  color: #555;
}

/* line 4361, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v2 .news-v2-desc h3 a:hover {
  color: #72c02c;
}

/* line 4367, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v2 .news-v2-desc small {
  color: #555;
  display: block;
  margin-bottom: 15px;
  text-transform: uppercase;
}

/* line 4374, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v2 .news-v2-desc p {
  color: #555;
}

/*News Description*/
/*News v3
------------------------------------*/
/*News Posts*/
/* line 4387, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .news-v3-in {
  padding: 35px 30px;
}

/* line 4391, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .news-v3-in-sm {
  padding: 20px;
}

/* line 4395, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 h2 {
  font-size: 32px;
  font-weight: 200;
  margin: 0 0 20px;
  line-height: 45px;
  text-transform: uppercase;
}

/* line 4403, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .news-v3-in-sm h2 {
  font-size: 22px;
  margin: 0 0 15px;
  line-height: 30px;
  text-transform: inherit;
}

/* line 4410, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 h2 a {
  color: #555;
}

/* line 4413, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 h2 a:hover {
  color: #72c02c;
  text-decoration: none;
}

/* line 4419, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 p {
  color: #555;
  margin-bottom: 20px;
}

/* line 4424, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .posted-info li {
  color: #555;
  font-style: italic;
}

/* line 4429, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .news-v3-in-sm .posted-info li {
  padding: 0;
  font-size: 12px;
  padding-left: 5px;
}

/* line 4435, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .posted-info li:before, .news-v3 .news-v3-in-sm .posted-info li:before {
  content: '/';
}

/* line 4439, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .posted-info li:before {
  font-size: 12px;
  line-height: 12px;
  margin-right: 13px;
}

/* line 4445, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .news-v3-in-sm .posted-info li:before {
  font-size: 11px;
  line-height: 11px;
  margin-right: 9px;
}

/* line 4451, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .posted-info li:first-child:before, .news-v3 .news-v3-in-sm .posted-info li:first-child:before {
  content: " ";
  margin-right: 0;
}

/* line 4456, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .posted-info li a {
  color: #555;
}

/* line 4459, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .posted-info li a:hover {
  color: #72c02c;
}

/* line 4464, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .post-shares {
  padding-left: 0;
  list-style: none;
  margin: 40px 0 0 -5px;
}

/* line 4470, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .news-v3-in-sm .post-shares {
  margin: 20px 0 0 -5px;
}

/* line 4474, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .post-shares li {
  position: relative;
  padding: 0 10px 0 5px;
  display: inline-block;
}

/* line 4479, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .post-shares li i {
  color: #555;
  width: 40px;
  height: 40px;
  padding: 13px;
  font-size: 16px;
  background: #eee;
  text-align: center;
  display: inline-block;
}

/* line 4489, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .post-shares li i:hover {
  background: #ddd;
}

/* line 4494, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .post-shares li span {
  top: -5px;
  right: 6px;
  color: #fff;
  width: 22px;
  height: 22px;
  padding: 4px;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  text-align: center;
  background: #72c02c;
  border: 2px solid #fff;
  border-radius: 50% !important;
}

/* line 4511, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.news-v3 .post-shares-lg li span {
  top: -10px;
  right: 0px;
  width: 29px;
  height: 29px;
  padding: 4px;
  font-size: 10px;
  line-height: 16px;
}

@media (max-width: 768px) {
  /* line 4523, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .news-v3 h2 {
    font-size: 22px;
    line-height: 30px;
  }
}

/*News Info*/
/*News Shares*/
/*--------------------------------------------------
	[34. Blog Grid]
----------------------------------------------------*/
/* line 4538, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid img {
  margin-bottom: 15px;
}

/* line 4542, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid h3 {
  font-size: 22px;
  margin: 0 0 10px;
  line-height: 1.4;
}

/* line 4548, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid h2.blog-grid-title-lg {
  font-size: 28px;
  line-height: 1.4;
  margin: 0 0 10px;
}

/* line 4555, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid h3.blog-grid-title-sm {
  font-size: 15px;
  line-height: 1.4;
}

/* line 4560, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid h3 a {
  color: #333;
}

/* line 4565, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid p {
  margin-bottom: 15px;
}

/* line 4570, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid-inner {
  padding: 20px;
  background: #fff;
  margin: -70px 40px 0;
  position: relative;
}

/*Blog Grid Gradient*/
/* line 4580, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid .blog-grid-grad {
  position: relative;
}

/* line 4583, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid .blog-grid-grad i {
  top: 10px;
  left: 10px;
  z-index: 1;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
}

/* line 4599, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid .blog-grid-info {
  padding-left: 0;
  list-style: none;
}

/* line 4603, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid .blog-grid-info li {
  color: #888;
  padding: 0 2px;
  font-size: 12px;
  display: inline-block;
}

/* line 4609, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid .blog-grid-info li:before {
  content: '/';
  font-size: 12px;
  line-height: 1.4;
  margin-right: 9px;
}

/* line 4616, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid .blog-grid-info li:first-child:before {
  content: " ";
  margin-right: 0;
}

/* line 4621, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid .blog-grid-info li a {
  color: #888;
}

/* line 4624, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid .blog-grid-info li a:hover {
  text-decoration: none;
}

/* line 4631, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid a.r-more {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  display: inline-block;
}

/* line 4637, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid a.r-more:hover {
  text-decoration: none;
}

/*Blog Grid Info*/
/*Read More Link*/
/*--------------------------------------------------
  [35. Blog Thumb]
----------------------------------------------------*/
/* line 4651, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb {
  overflow: hidden;
}

/* line 4654, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-hover {
  float: left;
  position: relative;
  margin-right: 15px;
}

/* line 4659, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-hover:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.35s, transform 0.35s;
}

/* line 4669, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-hover img {
  width: 120px;
  height: auto;
}

/* line 4673, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-hover img.video-play-btn {
  width: 30px;
  height: 30px;
}

/* line 4679, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-hover .hover-grad {
  left: 0;
  top: 50%;
  z-index: 1;
  opacity: 0;
  width: 100%;
  color: #fff;
  font-size: 20px;
  margin-top: -14px;
  text-align: center;
  position: absolute;
  display: inline-block;
  transition: all 0.65s, transform 0.65s;
}

/* line 4694, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-hover:hover .hover-grad {
  opacity: 1;
  transform: 0.55s;
  transition: opacity 0.55s;
}

/* line 4701, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-desc {
  overflow: hidden;
}

/* line 4704, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-desc h3 {
  font-size: 14px;
  margin: 0 0 5px;
  font-weight: 400;
  line-height: 1.4;
}

/* line 4710, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-desc h3 a {
  color: #555;
}

/* line 4716, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-info {
  padding-left: 0;
  list-style: none;
}

/* line 4720, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-info li {
  color: #888;
  padding: 0 2px;
  font-size: 12px;
  display: inline-block;
}

/* line 4726, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-info li:before {
  content: '/';
  font-size: 12px;
  line-height: 1.4;
  margin-right: 9px;
}

/* line 4733, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-info li:first-child:before {
  content: " ";
  margin-right: 0;
}

/* line 4738, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-info li a {
  color: #888;
}

/* line 4741, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-info li a:hover {
  color: #e74c3c;
}

/* line 4749, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb.blog-thumb-circle .blog-thumb-hover:after {
  border-radius: 50% !important;
}

/* line 4753, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb.blog-thumb-circle .blog-thumb-hover img {
  width: 50px;
  height: 50px;
}

/* line 4758, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb.blog-thumb-circle .blog-thumb-hover .hover-grad {
  font-size: 15px;
  margin-top: -10px;
}

/* line 4764, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-thumb .blog-thumb-hover:hover:after {
  background: rgba(114, 192, 44, 0.9);
}

/*Blog Grid Hover*/
/*Icon Hover Gradient*/
/*Blog Thumb Description*/
/*Blog Grid Info*/
/*Blog Thumb Circle
------------------------------------*/
/*Blog Thumb Colors
------------------------------------*/
/* line 4783, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-grid .blog-grid-grad i:hover {
  background: #72c02c;
}

/*--------------------------------------------------
  [36. Shop Subscribe]
----------------------------------------------------*/
/* line 4791, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shop-subscribe {
  padding: 25px 0;
}

/* line 4794, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shop-subscribe h2 {
  margin: 0;
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  text-transform: uppercase;
}

/* line 4802, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shop-subscribe input {
  border-color: #fff;
  border-right: none;
  background: transparent;
}

/* line 4808, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shop-subscribe .form-control {
  color: #fff;
  font-size: 14px;
  font-weight: 200;
}

/* line 4813, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shop-subscribe .form-control:focus {
  box-shadow: none;
  border-color: #fff;
}

/* line 4818, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shop-subscribe .form-control::-moz-placeholder, .shop-subscribe .form-control:-ms-input-placeholder, .shop-subscribe .form-control::-webkit-input-placeholder {
  color: #fff;
}

/* line 4823, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shop-subscribe .input-group-btn {
  border-color: #fff;
  background: transparent;
}

/* line 4827, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shop-subscribe .input-group-btn .btn {
  border: 1px solid #fff;
  background: transparent;
}

/* line 4832, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.shop-subscribe .input-group-btn i {
  color: #fff;
  font-size: 16px;
  font-weight: 200;
}

/*Input Form Control*/
/*--------------------------------------------------
  [37. Parallax Slider (Main)]
----------------------------------------------------*/
/* line 4847, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.slider-inner .da-slider {
  box-shadow: none;
  border-bottom: 2px solid #ddd;
}

/* line 4852, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.slider-inner .da-dots {
  z-index: 10;
}

@media (max-width: 450px) {
  /* line 4858, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .da-slide p {
    display: none;
  }
}

@media (max-width: 900px) {
  /*Slider Parallax*/
  /* line 4866, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .da-slide .da-img {
    display: none;
  }
}

/*--------------------------------------------------
	[38. Master Slider]
----------------------------------------------------*/
/* line 4875, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.ms-promo-info {
  font-size: 45px;
  font-weight: 300;
  line-height: 1.5;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 4883, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.ms-promo-info-in {
  font-size: 60px;
  font-weight: 600;
}

/* line 4888, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.ms-promo-sub {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 4895, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.ms-promo-sub-in {
  font-size: 15px;
}

/* line 4899, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.ms-img-bordered {
  background: #fff;
  padding: 7px;
}

/*--------------------------------------------------
  [39. Revolution Slider]
----------------------------------------------------*/
/* line 4908, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tp-dottedoverlay {
  z-index: 1 !important;
}

/* line 4912, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tp-bullets.preview4 .bullet {
  border-radius: 10px !important;
}

/* line 4916, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tp-banner-container ul li, .fullscreenbanner-container ul li {
  list-style: none;
  visibility: hidden;
}

/* line 4921, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tp-banner-container {
  position: relative;
}

/* line 4923, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tp-banner-container a.btn-u {
  color: #fff;
  border: solid 1px transparent;
}

/* line 4927, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tp-banner-container a.btn-u:hover {
  color: #fff;
  border: solid 1px transparent;
}

/* line 4933, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tp-banner-container a.re-btn-brd {
  background: none;
  border: solid 1px #fff;
}

/* line 4937, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tp-banner-container a.re-btn-brd:hover {
  color: #555;
  background: #fff;
}

/* line 4944, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tp-banner-container .btn-u.btn-brd {
  font-weight: 400;
}

/*Fullwidth*/
/*Fullscreen*/
/* line 4955, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.fullscreen-container {
  padding: 0;
  width: 100%;
  position: relative;
}

/*Slider Contents v1*/
/* line 4963, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.revolution-mch-1:after {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
}

/* line 4974, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.revolution-ch1 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 4983, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.revolution-ch2 {
  color: #fff;
  font-size: 20px;
  max-width: 470px;
  line-height: 32px;
  position: relative;
  text-align: center;
  font-weight: normal;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 4993, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.revolution-ch2:after {
  left: 50%;
  width: 70px;
  height: 2px;
  content: ' ';
  bottom: -20px;
  background: #fff;
  margin-left: -35px;
  position: absolute;
}

/*Slider Contents v2*/
/* line 5007, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.re-title-v1 {
  color: #fff;
  font-size: 75px;
  font-weight: 600;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 5016, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.re-title-v2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 5026, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.re-text-v1 {
  color: #fff;
  font-size: 24px;
  text-align: center;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 5033, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.re-text-v2 {
  color: #fff;
  font-size: 24px;
  font-family: "Open Sans", Arial, sans-serif;
}

/*Slides*/
/* line 5041, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.rs-caption-1 {
  color: #fff;
  font-size: 32px;
  font-weight: 200;
  padding: 6px 75px;
  position: relative;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff;
}

/* line 5050, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.rs-caption-1:after, .rs-caption-1:before {
  font-size: 16px;
  content: "\f005";
  position: absolute;
  font-family: FontAwesome;
}

/* line 5057, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.rs-caption-1:after {
  right: 4%;
}

/* line 5061, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.rs-caption-1:before {
  left: 4%;
}

@media (max-width: 768px) {
  /* line 5068, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .rs-caption-1:after, .rs-caption-1:before {
    content: "";
  }
}

/* line 5074, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.rs-caption-2 {
  color: #fff;
  position: relative;
  font-size: 26px;
  font-weight: 200;
  line-height: 40px;
  padding: 10px 50px;
  text-align: center;
}

/* line 5083, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.rs-caption-2:after {
  left: 50%;
  content: "";
  height: 1px;
  width: 100px;
  bottom: -10px;
  background: #fff;
  margin-left: -50px;
  position: absolute;
}

/* line 5095, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.rs-caption-3 a {
  margin: 0 5px;
  padding: 7px 20px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  /* line 5102, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .rs-caption-1, .rs-caption-2, .rs-caption-3 {
    font-weight: normal;
  }
}

/*--------------------------------------------------
  [40. Layer Slider]
----------------------------------------------------*/
/* line 5111, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.layer_slider {
  overflow: hidden;
}

/* line 5115, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.ls-ct-half, .ls-ct-center {
  border-radius: 100px !important;
}

/*--------------------------------------------------
  [41. Clients Carousel]
----------------------------------------------------*/
/* line 5123, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients {
  border: 0;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 30px;
  box-shadow: none !important;
  margin-bottom: 0 !important;
}

/* line 5131, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients li {
  overflow: hidden;
  margin-left: 1px;
  position: relative;
  background: #fafafa;
}

/* line 5137, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients li:hover {
  background: #f6f6f6;
}

/* line 5141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients li img.color-img {
  top: 0;
  left: 0;
  display: none;
  position: absolute;
}

/* line 5148, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients li:hover img.color-img {
  display: block;
}

/* line 5152, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients li img {
  filter: gray;
  /* IE6-9 */
  float: none !important;
  margin: 0 auto !important;
}

/* line 5159, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients li img:hover {
  filter: none;
}

/*--------------------------------------------------
  [42. Pie Chart]
----------------------------------------------------*/
/* line 5171, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pie-progress-charts .inner-pchart {
  text-align: center;
}

/* line 5175, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pie-progress-charts .circle {
  color: #555;
  font-weight: 200;
  margin-bottom: 20px;
}

/* line 5181, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pie-progress-charts .circle-title {
  font-size: 22px;
}

@media (max-width: 767px) {
  /* line 5187, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .pie-progress-charts .inner-pchart {
    margin-bottom: 40px;
  }
}

/*--------------------------------------------------
  [43. Counter]
----------------------------------------------------*/
/* line 5196, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.counters {
  color: #555;
  text-align: center;
}

/* line 5200, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.counters span {
  font-size: 35px;
}

/* line 5203, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.counters span.counter-icon {
  display: block;
  margin-bottom: 10px;
}

/* line 5207, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.counters span.counter-icon i {
  color: #fff;
  padding: 16px;
  min-width: 65px;
  font-size: 30px;
  position: relative;
  background: #72c02c;
}

/* line 5215, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.counters span.counter-icon i:after {
  width: 0;
  height: 0;
  left: 38%;
  content: " ";
  bottom: -7px;
  display: block;
  position: absolute;
  border-top: 7px solid #72c02c;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/
}

/* line 5237, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.counters:hover span.counter-icon i {
  border-color: #000;
}

@media (max-width: 767px) {
  /* line 5243, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .counters {
    margin-bottom: 30px;
  }
  /* line 5246, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .counters:last-child {
    margin-bottom: inherit;
  }
}

/*--------------------------------------------------
	[44. Back To Top]
----------------------------------------------------*/
/* line 5256, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
#topcontrol {
  color: #fff;
  z-index: 99;
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: #222;
  position: relative;
  right: 14px !important;
  bottom: 11px !important;
  border-radius: 3px !important;
}

/* line 5268, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
#topcontrol:after {
  top: -2px;
  left: 8.5px;
  content: "\f106";
  position: absolute;
  text-align: center;
  font-family: FontAwesome;
}

/* line 5277, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
#topcontrol:hover {
  color: #fff;
  background: #72c02c;
  transition: all 0.3s ease-in-out;
}

/*--------------------------------------------------
	[45. Owl Carousel]
----------------------------------------------------*/
/* line 5288, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-btn {
  color: #777;
  cursor: pointer;
  font-size: 18px;
  padding: 2px 9px;
  text-align: center;
  background: #f5f5f5;
}

/* line 5296, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-btn:hover {
  color: #fff;
  background: #72c02c;
}

/*Owl Carousel v1*/
/* line 5305, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v1 .owl-navigation {
  text-align: right;
  margin-bottom: 20px;
}

/* line 5310, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v1 .item {
  margin: 0 5px;
}

/* line 5313, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v1 .item img {
  text-align: center;
}

/* line 5318, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v1 .owl-pagination {
  display: none;
}

/*Owl Carousel v2*/
/* line 5326, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v2 .owl-slider-v2 {
  margin-bottom: 20px;
}

/* line 5330, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v2 .item {
  margin: 0 15px;
}

/* line 5333, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v2 .item img {
  text-align: center;
}

/* line 5338, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v2 .owl-navigation {
  text-align: center;
}

/* line 5342, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v2 .owl-pagination {
  display: none;
}

/*Owl Carousel v3*/
/* line 5350, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v3 .item {
  margin: 0 15px;
}

/* line 5353, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v3 .item img {
  text-align: center;
}

/* line 5358, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v3 .owl-pagination {
  display: none;
}

/*Owl Carousel v4*/
/* line 5366, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v4 .owl-wrapper-outer {
  margin-bottom: 20px;
}

/* line 5370, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v4 .item {
  margin: 0 3px;
}

/* line 5373, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v4 .item img {
  width: 100%;
}

/*Owl Carousel v5*/
/* line 5382, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v5 .owl-wrapper-outer {
  margin-bottom: 20px;
}

/* line 5387, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v5 .owl-controls .owl-page.active span, .owl-carousel-v5 .owl-controls.clickable .owl-page:hover span {
  background: #72c02c;
}

/* line 5391, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v5 .owl-controls .owl-page span {
  margin: 5px 3px !important;
}

/*Owl Slider v6*/
/* line 5400, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v6 .owl-carousel-item {
  margin: 0 10px;
  padding: 7px 0;
  text-align: center;
  position: relative;
  border: 1px solid #e4e2e2;
}

/* line 5407, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v6 .owl-carousel-item img {
  width: 150px;
  height: auto;
}

/* line 5413, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v6 .owl-wrapper-outer {
  margin-bottom: 20px;
}

/* line 5417, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-v6 .owl-controls .owl-page span {
  margin: 5px 3px !important;
}

/*Owl Pagination*/
/* line 5424, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-pagination {
  text-align: center !important;
}

/* line 5429, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-theme .owl-controls .owl-page.active span, .owl-theme .owl-controls.clickable .owl-page:hover span {
  opacity: 1;
  filter: Alpha(Opacity=100);
  /*IE7 fix*/
}

/* line 5436, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-theme .owl-controls .owl-page {
  zoom: 1;
  display: inline-block;
  *display: inline;
  /*IE7 life-saver */
}

/* line 5443, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-theme .owl-controls .owl-page span {
  width: 12px;
  height: 12px;
  opacity: 0.3;
  display: block;
  margin: 5px 7px;
  background: #bbb;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  border-radius: 50% !important;
}

/*Owl Carousel Style v1*/
/* line 5460, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-style-v1 img {
  width: 120px;
  height: auto;
}

/*Owl Carousel Style v2*/
/* line 5468, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-style-v2 .item {
  margin: 0 3px;
  background: #fafafa;
  text-align: center;
  border: solid 1px #eee;
}

/* line 5474, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-style-v2 .item img {
  padding: 0 3px;
  display: inline-block;
}

/* line 5479, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-style-v2 .item:hover {
  background: #f7f7f7;
  border-color: #e5e5e5;
  transition: all 0.4s ease-in-out;
}

/* line 5486, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-carousel-style-v2 .owl-pagination {
  display: none;
}

/*Owl Clients v2
------------------------------------*/
/* line 5495, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-clients-v1 img {
  width: 120px;
  height: auto;
}

/* line 5500, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-clients-v1 .owl-pagination {
  display: none;
}

/*Owl Carousel - Testimonials v3
------------------------------------*/
/*Testimonials Section*/
/* line 5509, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 {
  padding: 100px 0;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 5513, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 ul {
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
}

/* line 5521, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 li img {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

/* line 5527, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 li > p {
  font-size: 14px;
}

/* line 5532, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 .testimonials-v3-title {
  font-size: 22px;
  font-weight: 200;
  margin-bottom: 20px;
}

/* line 5537, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 .testimonials-v3-title p {
  color: #72c02c;
  margin-bottom: 0;
}

/* line 5542, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 .testimonials-v3-title span {
  color: #777;
  display: block;
  font-size: 14px;
}

/* line 5549, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 .owl-pagination {
  display: none;
}

/* line 5554, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 .owl-buttons .owl-prev, .testimonials-v3 .owl-buttons .owl-next {
  padding: 13px;
  position: relative;
  margin: 15px 2px 0;
  background: #f5f5f5;
  display: inline-block;
}

/* line 5562, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 .owl-buttons .owl-prev:after, .testimonials-v3 .owl-buttons .owl-next:after {
  top: 0;
  color: #bbb;
  font-size: 16px;
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome;
}

/* line 5572, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 .owl-buttons .owl-prev:after {
  left: 10px;
  content: "\f104";
}

/* line 5577, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 .owl-buttons .owl-next:after {
  right: 10px;
  content: "\f105";
}

/* line 5582, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 .owl-buttons .owl-prev:hover:after, .testimonials-v3 .owl-buttons .owl-next:hover:after {
  color: #fff;
}

/* line 5586, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-v3 .owl-buttons .owl-prev:hover, .testimonials-v3 .owl-buttons .owl-next:hover {
  background: #72c02c;
  transition: background 0.4s ease-in-out;
}

/*Testimonials Owl Navigation*/
/*Recent Works (Owl Carousel)
------------------------------------*/
/* line 5599, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .owl-navigation {
  top: 3px;
  position: relative;
  margin-bottom: 10px;
}

/* line 5606, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .item a {
  text-align: center;
}

/* line 5609, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .item a:hover {
  text-decoration: none;
}

/* line 5612, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .item a:hover strong {
  color: #555;
}

/* line 5618, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .item em.overflow-hidden {
  display: block;
}

/* line 5623, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .item a span {
  display: block;
  padding: 10px;
  border-bottom: solid 2px #eee;
}

/* line 5629, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .item a strong {
  color: #555;
  display: block;
}

/* line 5634, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .item a i {
  color: #777;
}

/* line 5638, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .item a img {
  transition: all 0.8s ease-in-out;
}

/* line 5643, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .item a:hover img {
  opacity: 0.8;
  transform: scale(1.2) rotate(3deg);
}

/* line 5648, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-work-v1 .item a:hover span {
  border-bottom: solid 2px #72c02c;
  transition: all 0.3s ease-in-out;
}

/*Parallax Twitter (Owl Carousel)
------------------------------------*/
/* line 5660, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter {
  color: #fff;
  padding: 100px 0;
  position: relative;
  text-align: center;
  background: #333 url(../img/bg/19.jpg) 50% 0 fixed;
  background-size: cover;
  background-position: center center;
}

/* line 5669, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

/* line 5679, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: url(../img/patterns/gridtile.png) repeat;
}

/* line 5690, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter-in {
  z-index: 1;
  position: relative;
}

/* line 5694, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter-in ul {
  margin: 0;
}

/* line 5698, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter-in li {
  color: #fff;
}

/* line 5701, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter-in li p {
  color: #fff;
  font-size: 18px;
  font-weight: 200;
}

/* line 5707, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter-in li a {
  color: #eee;
  text-decoration: underline;
}

/* line 5711, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter-in li a:hover {
  text-decoration: none;
}

/* line 5716, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter-in li span {
  color: #ddd;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}

/* line 5725, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.parallax-twitter-in .owl-page {
  width: 18px;
}

/*Twitter Block (owl-controls)*/
/*Clients Section (Owl Carousel)
------------------------------------*/
/* line 5735, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients-section {
  padding: 60px 0;
  position: relative;
  background: #333 url(../img/bg/7.jpg) 50% fixed;
  background-size: cover;
  background-position: center center;
}

/* line 5742, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients-section:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
}

/* line 5752, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients-section:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: url(../img/patterns/gridtile.png) repeat;
}

/* line 5763, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients-section .title-v1 p, .clients-section .title-v1 h2 {
  color: #fff;
}

/* line 5768, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients-section ul {
  padding: 0;
  z-index: 1;
  list-style: none;
}

/* line 5774, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients-section li {
  margin: 0 3px;
  background: #fff;
}

/* line 5778, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients-section li a {
  padding: 10px;
  display: block;
  overflow: hidden;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

/* line 5785, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients-section li a:hover {
  -transform: scale(1.2);
}

/* line 5791, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients-section img {
  width: 100px;
  height: 100px;
}

/* line 5796, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.clients-section .owl-pagination {
  display: none;
}

/* Owl Video
------------------------------------*/
/* line 5805, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-video img {
  position: relative;
  text-align: center;
  margin: 0 auto 20px;
}

/* line 5810, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-video img.video-play {
  top: 50%;
  left: 50%;
  margin-top: -52px;
  margin-left: -52px;
  position: absolute;
}

/* line 5819, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.owl-video .owl-controls .owl-page span {
  margin: 5px 3px;
}

/*--------------------------------------------------
	[46. Bootstrap Carousels]
----------------------------------------------------*/
/* Bootstrap Carousel v1
------------------------------------*/
/* line 5831, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v1 .carousel-caption {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 7px 15px;
  background: rgba(0, 0, 0, 0.7);
}

/* line 5838, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v1 .carousel-caption p {
  color: #fff;
  margin-bottom: 0;
}

/* line 5845, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v1 .carousel-arrow a.carousel-control {
  opacity: 1;
  font-size: 30px;
  height: inherit;
  width: inherit;
  background: none;
  text-shadow: none;
  position: inherit;
}

/* line 5855, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v1 .carousel-arrow a i {
  top: 50%;
  opacity: 0.6;
  background: #000;
  margin-top: -18px;
  padding: 2px 12px;
  position: absolute;
}

/* line 5863, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v1 .carousel-arrow a i:hover {
  opacity: 0.8;
}

/* line 5868, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v1 .carousel-arrow a.left i {
  left: 0;
}

/* line 5872, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v1 .carousel-arrow a.right i {
  right: 0;
}

/* Bootstrap Carousel v2
------------------------------------*/
/* line 5881, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v2 .carousel-control {
  opacity: 1;
  text-shadow: none;
}

/* line 5885, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v2 .carousel-control:hover {
  opacity: 1;
  text-shadow: none;
}

/* line 5890, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v2 .carousel-control.left, .carousel-v2 .carousel-control.right {
  top: 50%;
  z-index: 5;
  color: #eee;
  width: 45px;
  height: 45px;
  font-size: 30px;
  margin-top: -22px;
  position: absolute;
  text-align: center;
  display: inline-block;
  border: 2px solid #eee;
  background: rgba(0, 0, 0, 0.1);
}

/* line 5905, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v2 .carousel-control:hover {
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}

/* line 5910, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v2 .carousel-control.left {
  left: 20px;
}

/* line 5914, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v2 .carousel-control.right {
  right: 20px;
}

/* line 5918, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v2 .carousel-control .arrow-prev {
  top: -5px;
  position: relative;
}

/* line 5923, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.carousel-v2 .carousel-control .arrow-next {
  top: -5px;
  position: relative;
  right: -2px;
}

@media (min-width: 768px) {
  /* line 5931, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .carousel-indicators {
    bottom: 10px;
  }
}

/*--------------------------------------------------
	[47. Tabs]
----------------------------------------------------*/
/*Tabs*/
/* line 5941, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.nav-tabs > li > a, .nav-pills > li > a, .nav-tabs.nav-justified > li > a {
  border-radius: 0;
}

/*Tabs v1
------------------------------------*/
/* line 5949, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v1 .nav-tabs {
  border: none;
  background: none;
  border-bottom: solid 2px #72c02c;
}

/* line 5954, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v1 .nav-tabs a {
  font-size: 14px;
  padding: 5px 15px;
}

/* line 5960, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v1 .nav-tabs > .active > a {
  color: #fff;
  border: none;
  background: #72c02c;
}

/* line 5965, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v1 .nav-tabs > .active > a:hover, .tab-v1 .nav-tabs > .active > a:focus {
  color: #fff;
  border: none;
  background: #72c02c;
}

/* line 5972, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v1 .nav-tabs > li > a {
  border: none;
}

/* line 5975, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v1 .nav-tabs > li > a:hover {
  color: #fff;
  background: #72c02c;
}

/* line 5983, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v1 .tab-content {
  padding: 10px 0;
}

/* line 5986, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v1 .tab-content img {
  margin-top: 4px;
  margin-bottom: 15px;
}

/* line 5990, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v1 .tab-content img.img-tab-space {
  margin-top: 7px;
}

/*Tabs v2
------------------------------------*/
/* line 6001, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v2 .nav-tabs {
  border-bottom: none;
}

/* line 6005, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v2 .nav-tabs li a {
  padding: 9px 16px;
  background: none;
  border: none;
}

/* line 6011, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v2 .nav-tabs li.active a {
  background: #fff;
  padding: 7px 15px 9px;
  border: solid 1px #eee;
  border-top: solid 2px #72c02c;
  border-bottom: none !important;
}

/* line 6021, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v2 .tab-content {
  padding: 10px 16px;
  border: solid 1px #eee;
}

/*Tabs v3
------------------------------------*/
/* line 6032, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v3 .nav-pills li a {
  color: #777;
  font-size: 17px;
  padding: 4px 8px;
  margin-bottom: 3px;
  background: #fafafa;
  border: solid 1px #eee;
}

/* line 6040, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v3 .nav-pills li a:hover {
  color: #fff;
  background: #72c02c;
  border: solid 1px #68af28;
}

/* line 6047, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v3 .nav-pills li.active a {
  color: #fff;
  background: #72c02c;
  border: solid 1px #68af28;
}

/* line 6053, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v3 .nav-pills li i {
  width: 1.25em;
  margin-right: 5px;
  text-align: center;
  display: inline-block;
}

/* line 6061, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v3 .tab-content {
  padding: 15px;
  background: #fafafa;
  border: solid 1px #eee;
}

/*Tab v4
------------------------------------*/
/* line 6072, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v4 .tab-heading {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  border-bottom: 3px solid #333;
}

/* line 6080, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v4 .tab-heading h2 {
  margin: 0;
  float: left;
  font-size: 18px;
  font-weight: bold;
}

/* line 6088, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v4 .nav-tabs {
  top: 3px;
  border: none;
  float: right;
  overflow: hidden;
  position: relative;
}

/* line 6095, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v4 .nav-tabs a {
  color: #555;
  font-size: 12px;
  padding: 6px 15px;
  text-transform: uppercase;
}

/* line 6103, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v4 .nav-tabs > .active > a {
  border: none;
  background: inherit;
}

/* line 6107, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v4 .nav-tabs > .active > a:hover, .tab-v4 .nav-tabs > .active > a:focus {
  border: none;
  background: inherit;
}

/* line 6113, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v4 .nav-tabs > li > a {
  border: none;
}

/* line 6116, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v4 .nav-tabs > li > a:hover {
  background: inherit;
}

/* line 6123, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v4 .tab-content {
  padding: 20px 0 10px;
}

/*Nav Tabs*/
/*Tab v5
------------------------------------*/
/* line 6134, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v5 .nav-tabs {
  border-bottom: none;
}

/* line 6138, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v5 .nav-tabs li a {
  border: none;
  font-size: 14px;
  background: none;
  padding: 9px 16px;
}

/* line 6145, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v5 .nav-tabs li.active a {
  background: #fff;
  padding: 7px 15px 9px;
  border: solid 1px #eee;
  border-top: solid 3px #222;
  border-bottom: none !important;
}

/* line 6155, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v5 .tab-content {
  padding: 13px;
  border: solid 1px #eee;
}

/*Tab v6
------------------------------------*/
/* line 6165, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v6 .nav-tabs {
  border-bottom-color: #dedede;
}

/* line 6168, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v6 .nav-tabs > li {
  margin-right: 30px;
}

/* line 6171, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v6 .nav-tabs > li > a {
  border: none;
  color: #687074;
  padding: 6px 0;
  font-size: 18px;
  margin-right: 0;
  background: none;
  text-transform: uppercase;
  border-bottom: solid 1px transparent;
}

/* line 6182, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v6 .nav-tabs > li.active > a {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #18ba9b;
}

/* line 6188, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v6 .nav-tabs > li.active > a:hover, .tab-v6 .nav-tabs > li.active > a:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #18ba9b;
}

/* line 6197, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v6 .nav-tabs > li > a:hover {
  border-bottom: 1px solid #18ba9b;
}

/* line 6201, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v6 .nav-tabs > li > a:focus {
  border: none;
}

/* line 6208, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tab-v6 .tab-content {
  padding: 30px 0;
}

/*--------------------------------------------------
	[48. Accordions]
----------------------------------------------------*/
/*Accordion v1
------------------------------------*/
/* line 6219, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.acc-v1 .panel-heading {
  padding: 0;
  box-shadow: none;
}

/* line 6223, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.acc-v1 .panel-heading a {
  display: block;
  font-size: 14px;
  padding: 5px 15px;
  background: #fefefe;
}

/* line 6232, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.acc-icon a.accordion-toggle i {
  color: #555;
  margin-right: 8px;
}

/* line 6237, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.acc-icon a.accordion-toggle:hover i {
  color: #39414c;
}

/*--------------------------------------------------
	[49. Paginations]
----------------------------------------------------*/
/*Pegination*/
/* line 6249, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-radius: 0;
}

/* line 6256, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span {
  border-radius: 0;
}

/* line 6262, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span {
  border-radius: 0;
}

/* line 6270, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-radius: 0;
}

/* line 6275, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pagination li a {
  color: #777;
  padding: 5px 15px;
}

/* line 6279, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pagination li a:hover {
  color: #fff;
  background: #5fb611;
  border-color: #5fb611;
}

/* line 6287, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  border-color: #72c02c;
  background-color: #72c02c;
}

/*Pagination Without Space*/
/* line 6296, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pagination-no-space .pagination {
  margin: 0;
}

/*--------------------------------------------------
	[50. Pagers]
----------------------------------------------------*/
/*Pager*/
/* line 6307, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager li > a, .pager li > span {
  border-radius: 0;
}

/* line 6312, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager li > a:hover, .pager li > a:focus {
  color: #fff;
  background: #5fb611;
  border-color: #5fb611;
}

/* line 6320, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v2 li > a {
  border: none;
  transition: all 0.1s ease-in-out;
}

/* line 6325, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v3 li > a {
  transition: all 0.1s ease-in-out;
}

/* line 6330, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v2 li > a:hover, .pager.pager-v2 li > a:focus {
  color: #fff;
  background: #72c02c;
}

/* line 6337, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v3 li > a:hover, .pager.pager-v3 li > a:focus {
  color: #fff;
  background: #72c02c;
}

/* line 6343, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v2 li.page-amount, .pager.pager-v3 li.page-amount {
  font-size: 16px;
  font-style: italic;
}

/* line 6348, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v2 li.page-amount {
  top: 7px;
  color: #777;
  position: relative;
}

/* line 6353, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v2 li.page-amount:hover, .pager.pager-v2 li.page-amount:focus {
  top: 7px;
  color: #777;
  position: relative;
}

/* line 6360, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v3 li.page-amount {
  top: 7px;
  color: #777;
  position: relative;
}

/* line 6365, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v3 li.page-amount:hover, .pager.pager-v3 li.page-amount:focus {
  top: 7px;
  color: #777;
  position: relative;
}

/* line 6372, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v2.pager-md li a, .pager.pager-v3.pager-md li a {
  font-size: 16px;
  padding: 8px 18px;
}

/* line 6378, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v4 li > a {
  line-height: normal;
  transition: all 0.1s ease-in-out;
}

/* line 6382, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v4 li > a:hover, .pager.pager-v4 li > a:focus {
  color: #fff;
  background: #222;
  border-color: #222;
}

/* line 6389, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v4 li.page-amount {
  font-size: 14px;
  font-style: italic;
  top: 7px;
  color: #777;
  position: relative;
}

/* line 6396, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.pager.pager-v4 li.page-amount:hover, .pager.pager-v4 li.page-amount:focus {
  top: 7px;
  color: #777;
  position: relative;
}

/*Pager v2 and v3
------------------------------------*/
/*Pager Amount*/
/*Pager Size*/
/*Pager v4
------------------------------------*/
/*Pager Amount*/
/*--------------------------------------------------
	[51. Sidebar Menu]
----------------------------------------------------*/
/* Sidebar Menu v1
------------------------------------*/
/* line 6424, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 li {
  padding: 0;
}

/* line 6427, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 li a {
  display: block;
  padding: 8px 30px 8px 10px;
}

/* line 6431, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 li a:hover {
  text-decoration: none;
}

/* line 6436, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 li ul {
  padding: 0;
  list-style: none;
  background: #f8f8f8;
}

/* line 6442, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 li.active ul a {
  background: #f8f8f8;
}

/* line 6446, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 li ul a {
  color: #555;
  font-size: 12px;
  border-top: solid 1px #ddd;
  padding: 6px 30px 6px 17px;
}

/* line 6454, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 > li.active {
  background: #717984;
  border-color: #ddd;
}

/* line 6457, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 > li.active:hover {
  background: #717984;
  border-color: #ddd;
}

/* line 6464, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 > li.active:focus {
  border-color: #ddd;
}

/* line 6468, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 > li.active > a {
  color: #fff;
}

/* line 6474, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 ul li:hover a, .sidebar-nav-v1 ul li.active a {
  color: #72c02c;
}

/*Sidebar Sub Navigation*/
/*Sidebar Badges*/
/* line 6484, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.list-group-item li > .badge {
  float: right;
}

/* line 6489, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 span.badge {
  margin-top: 8px;
  margin-right: 10px;
}

/* line 6494, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 .list-toggle > span.badge {
  margin-right: 25px;
}

/* line 6498, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.sidebar-nav-v1 ul li span.badge {
  margin-top: 8px;
  font-size: 11px;
  padding: 3px 5px;
  margin-right: 10px;
}

/* Sidebar List Toggle
------------------------------------*/
/* line 6510, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.list-group-item:first-child, .list-group-item:last-child {
  border-radius: 0;
}

/* line 6516, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.list-toggle:after {
  top: 7px;
  right: 10px;
  color: #777;
  font-size: 14px;
  content: "\f107";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome;
}

/* line 6528, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.list-toggle.active:after {
  color: #fff;
  content: "\f107";
}

/*--------------------------------------------------
	[52. Content Boxes]
----------------------------------------------------*/
/*Content Boxes v1*/
/* line 6539, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v1 {
  text-align: center;
}

/* line 6542, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v1 span {
  display: block;
  margin-top: 5px;
}

/*Content Boxes v2*/
/* line 6551, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v2-o span {
  display: block;
  overflow: hidden;
}

/* line 6556, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v2-o small {
  display: block;
  line-height: 1.6;
}

/* line 6561, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v2-o .icon-md + span {
  margin-top: 9px;
}

/* line 6565, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v2-o .icon-lg + span {
  margin-top: 11px;
}

/* line 6569, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v2-o .icon-custom {
  float: left;
  margin-top: 3px;
  margin-right: 15px;
}

@media (max-width: 992px) {
  /* line 6577, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .content-boxes-v2 {
    text-align: center;
  }
  /* line 6580, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .content-boxes-v2 .text-justify {
    text-align: center;
  }
  /* line 6584, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .content-boxes-v2 span {
    display: block;
    margin-top: 5px;
  }
}

/*Content Boxes v3*/
/* line 6594, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v3 i.icon-custom {
  top: 8px;
  float: left;
  position: relative;
}

/* line 6600, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v3 .content-boxes-in-v3 {
  padding: 0 20px;
  overflow: hidden;
}

/* line 6604, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v3 .content-boxes-in-v3 h3 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 3px;
  text-transform: capitalize;
}

/* line 6610, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v3 .content-boxes-in-v3 h3 a {
  color: #555;
}

/* line 6616, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v3.content-boxes-v3-right {
  text-align: right;
}

/* line 6619, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v3.content-boxes-v3-right i.icon-custom {
  float: right;
}

/*Content Boxes Right v3*/
@media (max-width: 768px) {
  /* line 6628, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .content-boxes-v3.content-boxes-v3-right {
    text-align: inherit;
  }
  /* line 6631, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .content-boxes-v3.content-boxes-v3-right i.icon-custom {
    float: left;
    margin-left: 0;
  }
}

/*Content Boxes v4*/
/* line 6641, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v4 h2 {
  color: #555;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

/* line 6648, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v4 a {
  color: #777;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

/* line 6655, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v4 i {
  width: 25px;
  color: #72c02c;
  font-size: 35px;
  margin-top: 10px;
}

/* line 6663, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-in-v4 {
  padding: 0 10px;
  overflow: hidden;
}

/* line 6668, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v4-sm i {
  font-size: 26px;
  margin-top: 10px;
  margin-right: 5px;
}

/*Content Boxes v5*/
/* line 6677, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v5 i {
  float: left;
  color: #999;
  width: 50px;
  height: 50px;
  padding: 11px;
  font-size: 22px;
  background: #eee;
  line-height: 28px;
  text-align: center;
  margin-right: 15px;
  display: inline-block;
}

/* line 6691, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v5:hover i {
  color: #fff;
  background: #72c02c;
}

/*Content Boxes v6*/
/* line 6699, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v6 {
  padding-top: 25px;
  text-align: center;
}

/* line 6703, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v6 i {
  color: #fff;
  width: 90px;
  height: 90px;
  padding: 30px;
  font-size: 30px;
  line-height: 30px;
  position: relative;
  text-align: center;
  background: #dedede;
  margin-bottom: 25px;
  display: inline-block;
}

/* line 6716, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v6 i:after {
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  content: " ";
  position: absolute;
  border: 1px solid #dedede;
  border-radius: 50% !important;
}

/* line 6728, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v6:hover i {
  transition: all 0.3s ease-in-out;
  background: #72c02c;
}

/* line 6731, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.content-boxes-v6:hover i:after {
  transition: all 0.3s ease-in-out;
  border-color: #72c02c;
}

/*--------------------------------------------------
	[53. Tagline Boxes]
----------------------------------------------------*/
/*Tagline Boxes*/
/* line 6745, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box {
  padding: 20px;
  background: #fff;
  margin-bottom: 30px;
}

/* line 6750, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box h2 {
  font-size: 20px;
  line-height: 25px;
}

/* line 6755, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box p {
  margin-bottom: 0;
}

/* line 6759, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box.tag-text-space p {
  margin-bottom: 10px;
}

/*Tagline Boxes v1*/
/* line 6766, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box-v1 {
  border: solid 1px #eee;
  border-top: solid 2px #72c02c;
}

/*Tagline Boxes v2*/
/* line 6773, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box-v2 {
  background: #fafafa;
  border: solid 1px #eee;
  border-left: solid 2px #72c02c;
}

/*Tagline Boxes v3*/
/* line 6781, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box-v3 {
  border: solid 2px #eee;
}

/*Tagline Boxes v4*/
/* line 6787, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box-v4 {
  border: dashed 1px #bbb;
}

/*Tagline Boxes v5*/
/* line 6793, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box-v5 {
  margin: 20px 0;
  text-align: center;
  border: dashed 1px #ccc;
}

/* line 6798, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box-v5 span {
  color: #555;
  font-size: 28px;
  margin-bottom: 0;
}

/*Tagline Boxes v6*/
/* line 6807, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box-v6 {
  background: #fafafa;
  border: solid 1px #eee;
}

/*Tagline Boxes v7*/
/* line 6814, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tag-box-v7 {
  border: solid 1px #eee;
  border-bottom: solid 2px #72c02c;
}

/*--------------------------------------------------
	[54. Tags]
----------------------------------------------------*/
/*Tags v1
------------------------------------*/
/* line 6825, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v1 li {
  margin: 0;
  padding: 0;
}

/* line 6829, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v1 li a {
  font-size: 13px;
  padding: 4px 8px;
  line-height: 32px;
  border: solid 2px #eee;
  border-radius: 20px !important;
  transition: all 0.2s ease-in-out;
}

/* line 6837, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v1 li a:hover {
  text-decoration: none;
  border-color: #e0e0e0;
}

/*Tags v2
------------------------------------*/
/* line 6847, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v2 li {
  padding: 7px 0 7px 4px;
}

/* line 6850, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v2 li a {
  color: #555;
  font-size: 13px;
  padding: 5px 10px;
  border: solid 1px #bbb;
}

/* line 6856, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v2 li a:hover {
  color: #fff;
  background: #555;
  border-color: #555;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/*Tags v3
------------------------------------*/
/* line 6869, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v3 li {
  padding: 7px 0 7px 4px;
}

/* line 6872, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v3 li a {
  color: #555;
  font-size: 12px;
  padding: 4px 12px;
  border: solid 1px #eee;
}

/* line 6878, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v3 li a:hover {
  color: #fff;
  background: #222;
  border-color: #222;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/*Tags v4
------------------------------------*/
/* line 6891, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v4 {
  padding-left: 0;
  list-style: none;
}

/* line 6895, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v4 li {
  padding: 3px 2px;
  line-height: 28px;
  display: inline-block;
}

/* line 6901, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v4 a {
  color: #bbb;
  font-size: 13px;
  font-weight: 400;
  padding: 4px 10px;
  border: 1px solid #bbb;
}

/* line 6908, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.tags-v4 a:hover {
  color: #fff;
  background: #e74c3c;
  border-color: #e74c3c;
  text-decoration: none;
  transition: all 0.25s, transform 0.25s;
}

/*--------------------------------------------------
	[##. Main Page Styles]
----------------------------------------------------*/
/* Purchase Block
------------------------------------*/
/* line 6924, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.purchase {
  padding: 25px 0 30px;
  border-bottom: solid 1px #eee;
  background: url(../img/patterns/breadcrumbs.png) repeat;
}

/* line 6929, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.purchase p {
  margin-bottom: 0;
}

/* line 6933, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.purchase span {
  color: #666;
  display: block;
  font-size: 24px;
  line-height: 35px;
  font-weight: normal;
  margin-bottom: 12px;
  font-family: "Open Sans", Arial, sans-serif;
}

/* line 6943, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.purchase .btn-buy {
  text-align: center;
}

/* line 6946, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.purchase .btn-buy a {
  margin-top: 27px;
}

/* line 6949, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.purchase .btn-buy a i {
  margin-right: 5px;
}

@media (max-width: 992px) {
  /* line 6957, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .purchase {
    text-align: center;
  }
  /* line 6960, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .purchase span {
    font-size: 22px;
    line-height: 29px;
  }
  /* line 6965, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .purchase .btn-buy a {
    margin-top: 25px;
    font-size: 16px;
  }
}

/* Service Block
------------------------------------*/
/* line 6975, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service {
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px 15px 10px;
}

/* line 6980, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service:hover {
  background: #fcfcfc;
  box-shadow: 0 0 5px #ddd;
  transition: box-shadow 0.2s ease-in-out;
}

/* line 6985, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service:hover i {
  color: #656565;
}

/* line 6990, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service .desc {
  padding: 0 15px;
  overflow: hidden;
}

/* line 6994, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service .desc h4 {
  font-size: 22px;
  line-height: 25px;
}

/* line 7000, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service .service-icon {
  float: left;
  padding: 10px;
  color: #72c02c;
  font-size: 35px;
  text-align: center;
  transition: all 0.4s ease-in-out;
}

/* line 7008, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service .service-icon.icon-cogs {
  font-size: 37px;
}

/* line 7012, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service .service-icon.icon-plane {
  font-size: 43px;
}

/* Recent Work
------------------------------------*/
/* line 7022, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.recent-work a {
  text-align: center;
  background: #fcfcfc;
  display: inline-block;
}

/* line 7027, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.recent-work a:hover {
  text-decoration: none;
}

/* line 7030, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.recent-work a:hover strong {
  color: #555;
}

/* line 7036, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.recent-work em.overflow-hidden {
  display: block;
}

/* line 7041, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.recent-work a span {
  display: block;
  padding: 10px;
  border-bottom: solid 2px #eee;
}

/* line 7047, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.recent-work a strong {
  color: #555;
  display: block;
}

/* line 7052, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.recent-work a i {
  color: #777;
}

/* line 7056, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.recent-work a img {
  transition: all 0.8s ease-in-out;
}

/* line 7061, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.recent-work a:hover img {
  opacity: 0.8;
  transform: scale(1.2) rotate(3deg);
}

/* line 7066, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.recent-work a:hover span {
  border-bottom: solid 2px #72c02c;
  transition: all 0.3s ease-in-out;
}

/* Work */
/* line 7076, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.work {
  margin-top: 6px;
}

/* line 7079, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.work .details {
  padding: 10px;
}

/* line 7083, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.work .caption {
  display: block;
  padding-top: 5px;
  color: #585f69 !important;
  font-size: 18px !important;
}

/* line 7089, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.work .caption:hover {
  cursor: pointer;
  color: #444 !important;
  text-decoration: underline;
}

/*Other Footer Common Classes
------------------------------------*/
/*Thumb Headline*/
/* line 7101, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumb-headline h2 {
  color: #eee;
  font-size: 16px;
}

/*Footer Logo*/
/* line 7108, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.footer-logo {
  margin: 17px 0 20px;
}

/*Latest List*/
/* line 7115, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.latest-list li {
  padding: 8px 0;
  border-top: 1px solid #353535;
}

/* line 7119, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.latest-list li:first-child {
  padding-top: 0;
  border-top: none;
}

/* line 7124, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.latest-list li a {
  color: #eee;
}

/* line 7129, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.latest-list small {
  color: #999;
  display: block;
}

/*Link List*/
/* line 7138, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.link-list li {
  border-top: solid 1px #353535;
}

/* line 7141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.link-list li:first-child {
  border-top: none !important;
}

/* line 7146, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.link-list a {
  color: #eee;
  font-size: 11px;
  padding: 6px 0px;
  display: inline-block;
  text-transform: uppercase;
}

/* line 7154, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.link-list li i {
  color: #bbb;
  float: right;
  margin-top: 10px;
}

/*Latest Tweets*/
/* line 7164, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.latest-tweets .latest-tweets-inner p {
  color: #555;
  font-size: 13px;
}

/* line 7169, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.latest-tweets .latest-tweets-inner a {
  /*color: #3498db;*/
  text-decoration: none;
}

/* line 7173, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.latest-tweets .latest-tweets-inner a:hover {
  text-decoration: underline;
}

/* line 7178, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.latest-tweets .latest-tweets-inner i.fa {
  top: 2px;
  float: left;
  color: #bbb;
  color: #55acee;
  font-size: 18px;
  margin-right: 5px;
  position: relative;
}

/* line 7188, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.latest-tweets .latest-tweets-inner small {
  color: #777;
  display: block;
}

/*Social Icons*/
/* line 7196, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.social-icons {
  margin-top: 5px;
}

/* line 7199, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.social-icons li {
  margin: 0;
}

/* line 7202, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.social-icons li a {
  margin: 0 3px;
}

/*Thumbnails
------------------------------------*/
/* line 7211, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
a.thumbnail {
  padding: 0;
  border: none;
  margin-bottom: 15px;
}

/* line 7217, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail-style {
  padding: 7px;
  margin-bottom: 20px;
}

/* line 7221, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail-style:hover {
  box-shadow: 0 0 8px #ddd;
  transition: box-shadow 0.2s ease-in-out;
}

/* line 7227, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail h3, .thumbnail-style h3 {
  margin: 6px 0 8px 0;
}

/* line 7231, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail h3 a, .thumbnail-style h3 a {
  color: #585f69;
  font-size: 18px;
}

/* line 7236, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail h3 a:hover {
  color: #72c02c;
  text-decoration: none;
}

/* line 7242, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail-style h3 a:hover {
  color: #72c02c;
  text-decoration: none;
}

/* line 7247, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail-style .thumbnail-img {
  position: relative;
  margin-bottom: 11px;
}

/* line 7252, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail-style a.btn-more {
  right: -10px;
  bottom: 10px;
  color: #fff;
  padding: 1px 6px;
  position: absolute;
  background: #72c02c;
  display: inline-block;
}

/* line 7261, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail-style a.btn-more:hover {
  text-decoration: none;
  box-shadow: 0 0 0 2px #5fb611;
}

/* line 7267, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail-style:hover a.btn-more {
  right: 10px;
}

/* line 7273, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail-kenburn img {
  left: 10px;
  margin-left: -10px;
  position: relative;
  transition: all 0.8s ease-in-out;
}

/* line 7280, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.thumbnail-kenburn:hover img {
  transform: scale(1.2) rotate(2deg);
}

/*Servive Blocks
------------------------------------*/
/*Service Alternative Block*/
/* line 7289, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-alternative .service:hover {
  background: #76ca2c;
  transition: all 0.4s ease-in-out;
}

/* line 7293, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-alternative .service:hover i, .service-alternative .service:hover p, .service-alternative .service:hover h4 {
  color: #fff;
}

/*Servive Block Versions*/
/* line 7301, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-v1 h2 {
  margin: 15px 0 10px;
  font-size: 20px;
  line-height: 28px;
}

/* line 7307, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-v1 p {
  text-align: justify;
}

/*Blog Posts
------------------------------------*/
/* line 7315, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.posts .dl-horizontal {
  margin-bottom: 15px;
  overflow: hidden;
}

/* line 7316, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.posts .dl-horizontal a {
  color: #555;
}

/* line 7323, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.posts .dl-horizontal dt {
  width: 60px;
  float: left;
}

/* line 7327, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.posts .dl-horizontal dt img {
  width: 60px;
  height: 60px;
  padding: 2px;
  margin-top: 2px;
  border: solid 1px #ddd;
}

/* line 7336, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.posts .dl-horizontal dd {
  margin-left: 70px;
}

/* line 7339, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.posts .dl-horizontal dd p {
  margin: 0;
}

/* line 7343, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.posts .dl-horizontal dd a {
  font-size: 14px;
  line-height: 16px !important;
}

/* line 7347, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.posts .dl-horizontal dd a:hover {
  color: #72c02c;
  text-decoration: none;
}

/* line 7355, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.posts .dl-horizontal:hover dt img, .posts .dl-horizontal:hover dd a {
  color: #72c02c;
  border-color: #72c02c !important;
  transition: all 0.2s ease-in-out;
}

/*About Page
------------------------------------*/
/* line 7367, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team ul.team-socail {
  text-align: right;
}

/* line 7370, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team ul.team-socail li {
  margin: 0 !important;
  padding: 0 !important;
}

/* line 7374, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team ul.team-socail li a {
  text-decoration: none;
}

/* line 7377, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team ul.team-socail li a:hover {
  text-decoration: none;
}

/* line 7382, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team ul.team-socail li i {
  color: #aaa;
  padding: 6px 7px;
  min-width: 30px;
  font-size: 18px;
  text-align: center;
  background: #f7f7f7;
  display: inline-block;
}

/* line 7391, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team ul.team-socail li i:hover {
  color: #fff;
  background: #72c02c;
  transition: all 0.2s ease-in-out;
}

/* line 7400, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team .thumbnail-style {
  padding: 12px;
}

/* line 7403, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team .thumbnail-style img {
  margin-bottom: 7px;
}

/* line 7407, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team .thumbnail-style h3 {
  margin-bottom: 5px;
}

/* line 7411, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team .thumbnail-style:hover h3 a {
  color: #72c02c !important;
}

/* line 7415, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team .thumbnail-style small {
  display: block;
  margin-top: 5px;
  font-size: 12px;
}

/*About Me Page
------------------------------------*/
/* line 7426, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.about-me {
  overflow: hidden;
}

/* line 7429, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.about-me h2 {
  margin: 0;
  font-size: 20px;
  text-transform: uppercase;
}

/* line 7435, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.about-me span {
  color: #999;
  display: inline;
  font-size: 14px;
  margin-bottom: 10px;
}

/* line 7442, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.about-me .social-icons {
  margin-top: 7px;
}

/*About My Goals*/
/* line 7449, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.about-my-goals h2 {
  text-transform: uppercase;
}

/*About Skills*/
/* line 7455, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.about-skills {
  padding: 40px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background: url(../img/breadcrumbs.png) repeat;
}

/*Gallery Page
------------------------------------*/
/* line 7466, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.gallery-page .thumbnail {
  padding: 0;
  border: none;
  box-shadow: none;
}

/* line 7471, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.gallery-page .thumbnail:hover {
  padding: 0;
  border: none;
  box-shadow: none;
}

/* line 7478, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.gallery-page .thumbnails {
  margin-bottom: 6px;
}

/*Privacy Page
------------------------------------*/
/* line 7486, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.privacy a {
  text-decoration: underline;
}

/* line 7489, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.privacy a:hover {
  color: #72c02c;
  text-decoration: none;
}

/*Portfolio Item Page v1
------------------------------------*/
/* line 7499, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-item h2 {
  color: #555;
  font-size: 24px;
  margin: 0 0 15px;
}

/* line 7505, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-item li {
  padding: 3px 0;
}

/* line 7509, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-item p a {
  text-decoration: underline;
}

/* line 7512, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-item p a:hover {
  text-decoration: none;
}

/*Portfolio Item v1*/
/* line 7521, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-item1 ul {
  margin: 10px 0 20px;
}

/* line 7525, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-item1 li {
  font-size: 16px;
  margin-bottom: 3px;
}

/* line 7529, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.portfolio-item1 li i {
  font-size: 18px;
  margin-right: 4px;
}

/*Sidebar Features
------------------------------------*/
/*Blog Tags*/
/* line 7541, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
ul.blog-tags li {
  display: inline-block;
}

/* line 7545, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
ul.blog-tags a {
  font-size: 13px;
  padding: 2px 7px;
  background: #f7f7f7;
  margin: 0 3px 6px 0;
  display: inline-block;
}

/* line 7552, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
ul.blog-tags a i {
  color: #666;
}

/* line 7556, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
ul.blog-tags a:hover {
  background: #72c02c;
  color: #fff !important;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/* line 7562, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
ul.blog-tags a:hover i {
  color: #fff !important;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

/*Blog Photo Stream*/
/* line 7573, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-photos li {
  display: inline;
}

/* line 7576, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-photos li img {
  width: 58px;
  height: 58px;
  margin: 0 2px 8px;
}

/* line 7581, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-photos li img:hover {
  box-shadow: 0 0 0 2px #72c02c;
}

/*Blog Latest Tweets*/
/* line 7589, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner {
  padding: 10px;
  position: relative;
  background: #f7f7f7;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}

/* line 7596, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner:after, .blog-twitter .blog-twitter-inner:before {
  transition: all 0.3s ease-in-out;
}

/* line 7600, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner:after, .blog-twitter .blog-twitter-inner:before {
  width: 0;
  height: 0;
  right: 0px;
  bottom: 0px;
  content: " ";
  display: block;
  position: absolute;
}

/* line 7610, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner:after {
  border-top: 15px solid #eee;
  border-right: 15px solid transparent;
  border-left: 0px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/
}

/* line 7622, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner:before {
  border-bottom: 15px solid #fff;
  border-right: 0 solid transparent;
  border-left: 15px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-bottom-style: inset;
  /*FF fixes*/
}

/* line 7634, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner:hover {
  border-color: #72c02c;
  border-top-color: #72c02c;
}

/* line 7638, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner:hover:after {
  border-top-color: #72c02c;
}

/* line 7643, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner span.twitter-time {
  color: #777;
  display: block;
  font-size: 11px;
}

/* line 7649, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner a {
  color: #72c02c;
  text-decoration: none;
}

/* line 7653, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner a:hover {
  text-decoration: underline;
}

/* line 7658, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.blog-twitter .blog-twitter-inner i.fa {
  top: 2px;
  color: #bbb;
  font-size: 18px;
  position: relative;
}

/*Choose Block*/
/* line 7668, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.who li {
  margin-bottom: 6px;
}

/* line 7671, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.who li i {
  color: #72c02c;
  font-size: 13px;
  min-width: 15px;
  margin-right: 8px;
  text-align: center;
  position: relative;
}

/* line 7681, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.who li:hover i {
  color: #777;
}

/* line 7685, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.who li:hover a {
  color: #777;
}

/* line 7688, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.who li:hover a:hover {
  text-decoration: none;
}

/*Promo Page
------------------------------------*/
/*Slider Part*/
/* line 7699, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.fullwidthbanner-container.slider-promo {
  max-height: 460px !important;
}

/* line 7703, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.slider-promo-info-bg {
  padding: 25px 20px 20px;
  background: url(../img/bg-black.png) repeat;
}

/* line 7709, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.slider-promo-info span.big-size {
  color: #fff;
  display: block;
  font-size: 30px;
  margin-bottom: 25px;
  text-shadow: none !important;
}

/* line 7717, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.slider-promo-info p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
}

/*Promo Box*/
/* line 7726, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-box {
  padding: 20px;
  margin: 25px 0 20px;
  text-align: center;
}

/* line 7731, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-box i {
  display: block;
  font-size: 50px;
}

/* line 7736, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-box p {
  font-size: 14px;
  margin-bottom: 0;
}

/* line 7741, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-box strong {
  color: #555;
  display: block;
  font-size: 20px;
  font-weight: 200;
  margin: 20px 0 15px;
}

/* line 7748, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-box strong a {
  color: #555;
  display: block;
  font-size: 20px;
  font-weight: 200;
  margin: 20px 0 15px;
}

/* line 7757, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-box:hover strong {
  color: #72c02c;
}

/* line 7760, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-box:hover strong a {
  color: #72c02c;
}

/*Promo Service*/
/* line 7768, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-service {
  margin-bottom: 20px;
}

/* line 7771, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-service li {
  margin-bottom: 5px;
}

/* line 7775, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.promo-service a.btn-u {
  margin: 0 20px 10px 0;
}

/*Promo Elements*/
/* line 7782, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.title-box {
  font-size: 16px;
  text-align: center;
  padding: 10px 0 30px;
  border-bottom: solid 1px #eee;
}

/* line 7788, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.title-box .title-box-text {
  color: #555;
  display: block;
  font-size: 35px;
  font-weight: 200;
}

@media (max-width: 768px) {
  /* line 7798, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .title-box .title-box-text {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
  }
  /* line 7804, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .title-box p {
    font-size: 15px;
  }
}

/* line 7810, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-blockquote {
  margin: 20px 0;
  text-align: center;
  border: dashed 1px #ccc;
}

/* line 7816, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-blockquote-inner {
  padding: 15px;
}

/* line 7820, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.block-blockquote p {
  font-size: 26px;
  margin-bottom: 0;
}

/*Info Blocks*/
/* line 7827, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.info-blocks {
  margin-bottom: 15px;
}

/* line 7830, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.info-blocks:hover i.icon-info-blocks {
  color: #72c02c;
  transition: all 0.2s ease-in-out;
}

/* line 7835, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.info-blocks i.icon-info-blocks {
  float: left;
  color: #777;
  font-size: 30px;
  min-width: 50px;
  margin-top: 10px;
  text-align: center;
}

/* line 7844, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.info-blocks .info-blocks-in {
  padding: 0 10px;
  overflow: hidden;
}

/* line 7848, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.info-blocks .info-blocks-in h3 {
  color: #555;
  font-size: 20px;
  line-height: 28px;
}

/* line 7854, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.info-blocks .info-blocks-in p {
  font-size: 12px;
}

/*Brand Page
------------------------------------*/
/* line 7863, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.brand-page .btn {
  margin-bottom: 10px;
}

/*Form Pages
------------------------------------*/
/* line 7871, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.form-page input, .form-page select {
  margin-bottom: 10px;
}

/*Labels and Badges Page
------------------------------------*/
/* line 7879, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
ul.labels-demo {
  margin: 5px 0;
}

/* line 7882, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
ul.labels-demo li {
  list-style: none;
  display: inline-block;
  margin: 0 5px 2px 0;
}

/* line 7889, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.badge-sidebar {
  border-left: solid 1px #eee;
}

@media (max-width: 991px) {
  /* line 7894, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .badge-sidebar {
    border-left: none;
  }
}

/*Line Icon Page
------------------------------------*/
/* line 7903, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.line-icon-page .item-box {
  width: 25%;
  float: left;
  height: 90px;
  text-align: center;
  padding: 20px 10px;
  margin: 0 -1px -1px 0;
  word-wrap: break-word;
  border: 1px solid #ddd;
}

/* line 7914, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.line-icon-page .item-box--higher {
  height: 120px;
}

/* line 7918, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.line-icon-page .item-box:hover {
  background: #fafafa;
}

/* line 7923, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.line-icon-page .item span {
  display: block;
  font-size: 28px;
  margin-bottom: 10px;
}

/* line 7929, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.line-icon-page .item:hover {
  color: #72c02c;
}

@media (max-width: 768px) {
  /* line 7936, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .line-icon-page .item-box {
    height: 115px;
  }
}

@media (max-width: 450px) {
  /* line 7942, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .line-icon-page .item-box {
    height: 130px;
  }
}

/*Line Icon Page
------------------------------------*/
/* line 7950, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.icon-page-fa {
  margin-bottom: 20px;
}

/* line 7953, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.icon-page-fa .item-box {
  float: left;
  height: 100px;
  width: 16% !important;
}

/* line 7959, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.icon-page-fa .item {
  font-size: 12px;
}

/* line 7962, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.icon-page-fa .item i {
  color: #555;
  display: block;
  font-size: 24px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  /* line 7972, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .icon-page-fa .item-box {
    width: 20% !important;
  }
}

@media (max-width: 600px) {
  /* line 7978, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .icon-page-fa .item-box {
    width: 30% !important;
  }
}

@media (max-width: 400px) {
  /* line 7984, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .icon-page-fa .item-box {
    width: 50% !important;
  }
}

/*GLYPHICONS Icons Page
------------------------------------*/
/*Special styles for displaying the icons and their classes*/
/* line 7993, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.bs-glyphicons {
  padding-left: 0;
  list-style: none;
  overflow: hidden;
  padding-bottom: 1px;
  margin-bottom: 20px;
}

/* line 8000, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.bs-glyphicons li {
  width: 25%;
  float: left;
  height: 115px;
  padding: 10px;
  font-size: 11px;
  line-height: 1.4;
  text-align: center;
  margin: 0 -1px -1px 0;
  border: 1px solid #ddd;
}

/* line 8012, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.bs-glyphicons .glyphicon {
  display: block;
  font-size: 18px;
  margin: 5px auto 10px;
}

/* line 8018, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.bs-glyphicons li:hover {
  color: #72c02c;
  background-color: #fafafa;
}

@media (min-width: 768px) {
  /* line 8025, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
  .bs-glyphicons li {
    width: 12.5%;
  }
}

/*Glyphicons Pro*/
/* line 8033, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.demo-pro-glyphicons li {
  width: 170px;
  height: 100px;
  padding: 20px 10px 30px;
}

/* line 8039, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.demo-pro-glyphicons span {
  height: 40px;
  display: block;
}

/* line 8043, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.demo-pro-glyphicons span:before {
  color: #555;
  padding: 10px 62px;
}

/* line 8048, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.demo-pro-glyphicons span.glyphicons:before {
  font-size: 22px;
}

/* line 8052, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.demo-pro-glyphicons span.halflings {
  height: 30px;
}

/* line 8055, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.demo-pro-glyphicons span.halflings:before {
  padding: 10px 70px;
}

/*Glyphicons Pro Halflings*/
/*Contact Pages
------------------------------------*/
/* line 8067, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.map {
  width: 100%;
  height: 350px;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

/* line 8074, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.map-box {
  height: 250px;
}

/* line 8078, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.map-box-space {
  margin-top: 15px;
}

/* line 8082, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.map-box-space1 {
  margin-top: 7px;
}

/*Background Opacity
------------------------------------*/
/* line 8089, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-or {
  overflow: hidden;
  position: relative;
}

/* line 8094, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-bg {
  width: 250px;
  padding: 60px;
  position: absolute;
  top: -10px;
  right: -100px;
  background-color: rgba(255, 255, 255, 0.1);
  transform: rotate(55deg);
}

/*Easy Block (used for image badges)
------------------------------------*/
/* line 8107, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v1 {
  position: relative;
}

/* line 8110, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v1 img {
  width: 100%;
}

/* line 8114, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v1 .overflow-h h3 {
  width: 60%;
  float: left;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 5px;
}

/* line 8122, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v1 .easy-block-v1-badge {
  left: 0px;
  top: 10px;
  z-index: 1;
  color: #fff;
  padding: 4px 10px;
  position: absolute;
}

/*Easy Block v2 (used for image badges)
------------------------------------*/
/* line 8135, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v2 {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

/* line 8140, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v2 img {
  width: 100%;
}

/* line 8144, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v2 .easy-bg-v2 {
  top: 5px;
  color: #fff;
  width: 100px;
  padding: 5px;
  right: -32px;
  font-size: 13px;
  position: absolute;
  text-align: center;
  letter-spacing: 2px;
  transform: rotate(45deg);
}

/*Easy Block v3 (used for image badges)
------------------------------------*/
/* line 8161, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v3 {
  text-align: left;
  background: #585f69;
  padding: 30px 20px 20px;
}

/* line 8166, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v3.first {
  background: #696f77;
}

/* line 8170, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v3.second {
  background: #707780;
}

/* line 8174, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v3.third {
  background: #787f88;
}

/* line 8178, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v3 i {
  color: #fff;
  float: left;
  font-size: 30px;
  min-width: 45px;
  padding-top: 10px;
}

/* line 8186, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v3 .inner-faq-b {
  overflow: hidden;
}

/* line 8189, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v3 .inner-faq-b h2, .easy-block-v3 .inner-faq-b h3 {
  font-size: 21px;
  margin-bottom: 8px;
}

/* line 8194, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v3 .inner-faq-b h4 {
  color: #fff;
  font-size: 17px;
  margin-bottom: 2px;
}

/* line 8200, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.easy-block-v3 .inner-faq-b h2, .easy-block-v3 .inner-faq-b h3, .easy-block-v3 .inner-faq-b p {
  color: #fff;
}

/*Full Width Block
------------------------------------*/
/* line 8209, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.full-w-block {
  padding: 30px 0;
  background: #282828;
}

/* line 8213, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.full-w-block .info-blocks i {
  color: #fff;
}

/* line 8218, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.full-w-block .info-blocks-in h3 {
  color: #fff;
}

/* line 8222, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.full-w-block .info-blocks-in p {
  color: #ccc;
}

/* line 8227, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.full-w-block .info-blocks:hover i.icon-info-blocks {
  color: #fff;
}

/*Simple Block
------------------------------------*/
/* line 8235, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.simple-block {
  margin-bottom: 20px;
}

/* line 8238, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.simple-block img {
  width: 100%;
}

/* line 8242, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.simple-block p {
  text-align: center;
}

/* line 8246, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.simple-block .carousel-indicators {
  top: 10px;
  left: 65%;
  text-align: right;
}

/* line 8251, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.simple-block .carousel-indicators .active {
  background: #555;
}

/* line 8255, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.simple-block .carousel-indicators li {
  border-color: #555;
}

/* line 8260, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.simple-block .carousel-inner > .item {
  margin: 0;
}

/* line 8264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.simple-block .responsive-video {
  margin-bottom: 3px;
}

/*Two Blocks
------------------------------------*/
/* line 8272, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.two-blocks {
  text-align: center;
}

/* line 8276, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.two-blocks-in {
  margin-bottom: 30px;
}

/* line 8279, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.two-blocks-in i {
  color: #fff;
  padding: 11px;
  font-size: 30px;
  min-width: 50px;
  text-align: center;
  background: #585f69;
  margin-bottom: 10px;
  display: inline-block;
}

/*Three Blocks
------------------------------------*/
/* line 8294, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.three-blocks {
  text-align: center;
}

/* line 8298, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.three-blocks-in {
  margin-bottom: 30px;
}

/* line 8301, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.three-blocks-in i {
  color: #777;
  padding: 9px 6px;
  font-size: 20px;
  min-width: 40px;
  margin-bottom: 10px;
  border: 1px solid #777;
}

/* line 8310, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.three-blocks-in h3 {
  font-size: 20px;
  margin-bottom: 7px;
}

/*Temperory Classes
------------------------------------*/
/*Banner-info*/
/* line 8320, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.banner-info {
  margin-bottom: 10px;
}

/* line 8323, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.banner-info i {
  float: left;
  color: #fff;
  padding: 11px;
  /*min-width works with line-icon*/
  min-width: 40px;
  font-size: 22px;
  text-align: center;
  margin: 7px 20px 0 0;
}

/* line 8334, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.banner-info i.fa {
  min-width: 46px;
}

/* line 8339, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.banner-info.light i {
  border: 1px solid #fff;
}

/* line 8343, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.banner-info.dark i {
  background: #555;
}

/* line 8347, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.banner-info h3 {
  font-size: 21px;
  margin-bottom: 5px;
}

/* line 8353, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.banner-info.light h3 {
  color: #fff;
}

/* line 8357, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.banner-info.light p {
  color: #eee;
}

/*min-width works with FontAwesome*/
/*Title-Box-V2*/
/* line 8367, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.title-box-v2 {
  text-align: center;
  margin-bottom: 40px;
}

/* line 8371, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.title-box-v2 h2 {
  font-size: 25px;
  text-transform: uppercase;
}

/* line 8376, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.title-box-v2 p {
  font-size: 13px;
}

/*remove padding from "Col-"*/
/* line 8383, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.no-space {
  padding: 0;
}

/* line 8387, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.no-space-left {
  padding-left: 0;
  padding-right: 15px !important;
}

/* line 8392, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.no-space-right {
  padding-right: 0;
  padding-left: 15px !important;
}

/*List Style v1*/
/* line 8400, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.list-style-v1 li {
  margin-bottom: 7px;
}

/* line 8404, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.list-style-v1 i {
  margin-right: 5px;
}

/*Social Contacts
------------------------------------*/
/* line 8412, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.social-contacts li {
  padding: 5px 0;
  border-top: 1px solid #ddd;
}

/* line 8416, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.social-contacts li:first-child {
  border-top: none;
}

/* line 8420, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.social-contacts li i {
  color: #777;
  font-size: 13px;
  min-width: 13px;
  margin-right: 12px;
  text-align: center;
}

/*Status Bar
------------------------------------*/
/* line 8432, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.status-bar li {
  padding-left: 9px;
  border-left: 1px solid #eee;
}

/* line 8436, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.status-bar li:first-child {
  border-left: none;
}

/* line 8440, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.status-bar li i {
  color: #72c02c;
  margin-right: 5px;
}

/* line 8445, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.status-bar li a:hover {
  text-decoration: none;
}

/* line 8450, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.status-bar-in {
  padding: 10px;
  position: relative;
  background: #f0f0f0;
}

/* line 8455, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.status-bar-in:after {
  width: 0;
  height: 0;
  top: -10px;
  left: 50px;
  content: " ";
  display: block;
  position: absolute;
  border-bottom: 11px solid #f0f0f0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/
}

/*Share List*/
/* line 8477, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.share-list {
  margin-bottom: 0;
}

/* line 8480, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.share-list li {
  padding-left: 20px;
}

/* line 8483, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.share-list li:first-child {
  padding-left: 0;
}

/* line 8487, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.share-list li i {
  color: #72c02c;
  margin-right: 5px;
}

/*People Say
------------------------------------*/
/* line 8498, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.people-say img {
  float: left;
  width: 40px;
  height: auto;
  margin: 6px 10px 0 0;
}

/* line 8506, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.people-say .overflow-h span {
  font-weight: 700;
}

/* line 8510, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.people-say .overflow-h p {
  font-style: italic;
  line-height: 1.3;
}

/* line 8515, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.people-say .overflow-h small {
  text-align: right;
  font-style: italic;
}

/*Inspire Page
------------------------------------*/
/* line 8525, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
img.img-mouse {
  width: 150px;
  height: auto;
  margin: 0 auto 60px;
}

/*Desire Page
------------------------------------*/
/*Service Info*/
/* line 8535, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-info {
  position: relative;
  padding: 40px 0 50px;
  background: url(../img/patterns/17.png) repeat;
}

/* line 8540, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-info .info-description {
  overflow: hidden;
}

/* line 8544, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-info i.service-info-icon {
  color: #777;
  float: left;
  width: 50px;
  height: 50px;
  padding: 15px;
  font-size: 20px;
  line-height: 17px;
  margin-right: 20px;
  text-align: center;
  display: inline-block;
  border: 1px solid #777;
}

/* line 8559, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-info .info-description h3 {
  margin: 0 0 10px;
}

/* line 8563, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.service-info .info-description p {
  color: #777;
}

/*Portfolio Single Item Page
------------------------------------*/
/*Project Details*/
/* line 8574, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.project-details li {
  color: #555;
  font-weight: 200;
  margin-bottom: 5px;
}

/* line 8580, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.project-details strong {
  min-width: 100px;
  display: inline-block;
}

/* line 8585, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.project-details a {
  text-decoration: underline;
}

/*Bootstrap Testimonials
------------------------------------*/
/* line 8593, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bs {
  padding: 50px 0;
  background: url(../img/bg/16.jpg) 50% 0 repeat fixed;
}

/* line 8597, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bs .headline-center-v2 span.author {
  color: #777;
  font-size: 14px;
}

/* line 8602, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bs .item {
  margin-bottom: 30px;
}

/* line 8606, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bs .carousel-arrow {
  text-align: center;
}

/* line 8611, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bs .carousel-control-v2 i {
  color: #888;
  width: 30px;
  height: 30px;
  padding: 2px;
  font-size: 22px;
  display: inline-block;
  border: 2px solid #888;
}

/* line 8620, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bs .carousel-control-v2 i:hover {
  color: #72c02c;
  border-color: #72c02c;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

/* line 8628, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.testimonials-bs .carousel-control-v2.left, .testimonials-bs .carousel-control-v2.right {
  text-decoration: none;
  background-image: none;
}

/*Removes Bootstrap carousel background images*/
/*Team v1*/
/* line 8639, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/unify/_blocks.scss */
.team-v1 li > h3 {
  color: #555;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
body {
  font-family: "ProximaNova",Corbel,Arial,sans-serif;
  font-size: 14px;
}

@media (min-width: 768px) {
  /* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  body {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  /* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  body {
    font-size: 18px;
  }
}

/* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
p, ol, ul {
  font-family: "ProximaNova",Corbel,Arial,sans-serif;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
ol, ul {
  margin-bottom: 1.5em;
}

/* line 23, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: .02em;
  margin: 0 0 .25em 0;
}

/* line 31, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.content-container h1, .content-container h2, .content-container h3, .content-container h4, .content-container h5, .content-container h6 {
  margin-top: 1em;
}

/* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.title.page-title {
  margin-bottom: .75em;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
strong {
  letter-spacing: .02em;
}

/* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.mceContentBody, .mceContentBody p, .mceContentBody ol, .mceContentBody ul {
  line-height: 1.5em;
  color: #60616d;
  font-size: 16px;
  margin: 0;
  margin-bottom: 1em !important;
}

/* line 63, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.pane-page-content .field-type-text-with-summary .field-item p, .pane-page-content .field-type-text-with-summary .field-item ol, .pane-page-content .field-type-text-with-summary .field-item ul,
.pane-page-content .field-type-text-long .field-item p,
.pane-page-content .field-type-text-long .field-item ol,
.pane-page-content .field-type-text-long .field-item ul,
.pane-page-content .paragraphs-item-paragraph-snippet p,
.pane-page-content .paragraphs-item-paragraph-snippet ol,
.pane-page-content .paragraphs-item-paragraph-snippet ul,
.pane-page-content .paragraphs-item-paragraph-content p,
.pane-page-content .paragraphs-item-paragraph-content ol,
.pane-page-content .paragraphs-item-paragraph-content ul,
.pane-page-content .oa-event-description p,
.pane-page-content .oa-event-description ol,
.pane-page-content .oa-event-description ul,
.pane-page-content .oa-news-body p,
.pane-page-content .oa-news-body ol,
.pane-page-content .oa-news-body ul,
.pane-page-content .paragraphs-item-viu-text-section p,
.pane-page-content .paragraphs-item-viu-text-section ol,
.pane-page-content .paragraphs-item-viu-text-section ul {
  margin: 0;
  margin-bottom: 1em !important;
}

/* line 68, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.pane-page-content .field-type-text-with-summary .field-item, .pane-page-content .field-type-text-with-summary .field-item p, .pane-page-content .field-type-text-with-summary .field-item ol, .pane-page-content .field-type-text-with-summary .field-item ul,
.pane-page-content .field-type-text-long .field-item,
.pane-page-content .field-type-text-long .field-item p,
.pane-page-content .field-type-text-long .field-item ol,
.pane-page-content .field-type-text-long .field-item ul,
.pane-page-content .paragraphs-item-paragraph-snippet,
.pane-page-content .paragraphs-item-paragraph-snippet p,
.pane-page-content .paragraphs-item-paragraph-snippet ol,
.pane-page-content .paragraphs-item-paragraph-snippet ul,
.pane-page-content .paragraphs-item-paragraph-content,
.pane-page-content .paragraphs-item-paragraph-content p,
.pane-page-content .paragraphs-item-paragraph-content ol,
.pane-page-content .paragraphs-item-paragraph-content ul,
.pane-page-content .oa-event-description,
.pane-page-content .oa-event-description p,
.pane-page-content .oa-event-description ol,
.pane-page-content .oa-event-description ul,
.pane-page-content .oa-news-body,
.pane-page-content .oa-news-body p,
.pane-page-content .oa-news-body ol,
.pane-page-content .oa-news-body ul,
.pane-page-content .paragraphs-item-viu-text-section,
.pane-page-content .paragraphs-item-viu-text-section p,
.pane-page-content .paragraphs-item-viu-text-section ol,
.pane-page-content .paragraphs-item-viu-text-section ul {
  line-height: 1.5em;
  font-size: 18px;
}

/* line 73, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.pane-page-content .field-type-text-with-summary .field-item ol, .pane-page-content .field-type-text-with-summary .field-item ul,
.pane-page-content .field-type-text-long .field-item ol,
.pane-page-content .field-type-text-long .field-item ul,
.pane-page-content .paragraphs-item-paragraph-snippet ol,
.pane-page-content .paragraphs-item-paragraph-snippet ul,
.pane-page-content .paragraphs-item-paragraph-content ol,
.pane-page-content .paragraphs-item-paragraph-content ul,
.pane-page-content .oa-event-description ol,
.pane-page-content .oa-event-description ul,
.pane-page-content .oa-news-body ol,
.pane-page-content .oa-news-body ul,
.pane-page-content .paragraphs-item-viu-text-section ol,
.pane-page-content .paragraphs-item-viu-text-section ul {
  padding-left: 25px;
}

/* line 77, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.pane-page-content .paragraphs-item-paragraph-content {
  font-size: 14px;
}

/* line 80, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.pane-page-content .entity-paragraphs-item {
  margin-bottom: 1em;
}

/* line 83, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.pane-page-content .panel-panel-inner > .panel-pane.pane-node-field-oa-related {
  padding-top: 0;
}

/* line 86, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.pane-page-content .pane-node-comments .oa-list .oa-comment-reply-body,
.pane-page-content .pane-content #comments .oa-list .oa-comment-reply-body,
.pane-page-content .oa-comment-reply-body {
  line-height: 1.5em;
  color: #60616d;
  font-size: 15px;
  margin: 0 0 10px 0;
}

/* line 93, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.pane-page-content .pane-node-comments .oa-list .oa-comment-reply-body p,
.pane-page-content .pane-content #comments .oa-list .oa-comment-reply-body p,
.pane-page-content .oa-comment-reply-body p {
  font-size: 15px;
  margin: 0 0 1em 0;
}

/* line 97, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.pane-page-content .pane-node-comments .oa-list .oa-comment-reply-body .user-info, .pane-page-content .pane-node-comments .oa-list .oa-comment-reply-body .links,
.pane-page-content .pane-content #comments .oa-list .oa-comment-reply-body .user-info,
.pane-page-content .pane-content #comments .oa-list .oa-comment-reply-body .links,
.pane-page-content .oa-comment-reply-body .user-info,
.pane-page-content .oa-comment-reply-body .links {
  font-size: 13px;
}

/* line 104, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
h1 {
  color: #2a2c37;
  line-height: 1.1em;
  font-size: 24px;
}

@media (min-width: 768px) {
  /* line 104, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  h1 {
    font-size: 42px;
  }
}

@media (min-width: 992px) {
  /* line 104, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  h1 {
    font-size: 50px;
  }
}

/* line 114, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
h1.title {
  color: #003B5C;
  margin-top: 0;
}

/* line 120, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
h2 {
  font-weight: bold;
  background-image: none;
  font-size: 20px;
}

@media (min-width: 768px) {
  /* line 120, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  h2 {
    font-size: 30px;
  }
}

@media (min-width: 992px) {
  /* line 120, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  h2 {
    font-size: 36px;
  }
}

/* line 131, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.oa-pane .pane-title {
  font-size: 20px;
  margin: 0 0 0.8em 0;
  padding: 0;
  letter-spacing: .04em;
  font-weight: bold;
  background-image: none;
}

/*
.radix-layouts-sidebar {
  opacity: 0.85;
  h2, h3 {
    font-size: 16px;
    padding: 0;
    letter-spacing: .04em;
    font-weight: bold;
    background-image: none;
  }
  h3 {
    font-size: 14px;
    margin: 5px 0;
  }
  .pane-title {
    font-size: 14px;
    margin: 0 -15px 0;
    padding: 10px 15px 5px;
    a{
      text-decoration: none;
    }
  }
  .field-type-text-with-summary .field-item p {
    font-size: 14px;
  }
  .group-content li {
    font-size: 14px;
  }
}
*/
/* line 169, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
h3, h3.panel-title, h3.pane-title {
  font-weight: 800;
  font-size: 14px;
}

@media (min-width: 768px) {
  /* line 169, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  h3, h3.panel-title, h3.pane-title {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  /* line 169, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  h3, h3.panel-title, h3.pane-title {
    font-size: 26px;
  }
}

/* line 180, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.view-oa-team-list h3 a {
  color: #777;
  text-transform: uppercase;
  font-weight: normal;
}

/* line 186, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
h4 {
  letter-spacing: .04em;
  font-size: 1em;
}

@media (min-width: 768px) {
  /* line 186, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  h4 {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  /* line 186, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  h4 {
    font-size: 22px;
  }
}

/* line 197, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.entity-paragraphs-item .field-label-heading .field-label {
  font-size: 14px;
}

/*
.radix-layouts-sidebar {
  color: #60616d;
  font-size: 15px;
}
*/
/* line 206, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.group-header {
  margin-top: 0;
}

/* line 208, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.group-header h3, .group-header h4 {
  font-size: 12px;
  line-height: 1.1;
  color: #6D7074;
  font-weight: bold;
  font-style: normal;
  border-top: 4px solid #EAEAEA;
  margin-bottom: 0;
  padding-top: 1.3em;
  padding-bottom: 1.3em;
}

/* line 221, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.view-content table > caption {
  font-size: 12px;
  line-height: 1.1;
  color: #6D7074;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 1.3em;
}

/* line 232, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.oa-responsive-region .fa {
  margin-right: 0;
}

/* line 236, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
.more-link {
  font-style: italic;
  font-size: 15px;
}

@media screen and (max-width: 1024px) {
  /* line 242, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  abbr[title] {
    position: relative;
    /* ensure consistent styling across browsers */
    text-decoration: underline dotted;
  }
  /* line 249, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_typography.scss */
  abbr[title]:hover::after,
  abbr[title]:focus::after {
    content: attr(title);
    /* position tooltip like the native one */
    position: absolute;
    left: 0;
    bottom: -30px;
    width: auto;
    white-space: nowrap;
    background-color: #1e1e1e;
    color: #fff;
    border-radius: 3px;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
    font-size: 14px;
    padding: 3px 5px;
  }
}

/* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_component_fixes.scss */
.panel.panel-bootstrap-pane {
  border-width: 1px;
  border-style: solid;
}

/* line 6, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_component_fixes.scss */
.panel.panel-bootstrap-pane .panel-title {
  font-size: 1em;
  margin-top: 0;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_component_fixes.scss */
.panel.panel-bootstrap-pane .panel-title a {
  text-decoration: none;
}

/* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_component_fixes.scss */
.panel-bootstrap-pane.service-block.rounded {
  border-radius: 5px;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_component_fixes.scss */
.panel-bootstrap-pane.service-block.rounded-2x {
  border-radius: 10px;
}

/* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_component_fixes.scss */
.panel-bootstrap-pane.service-block .service-block-title {
  margin-top: 0;
}

/* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/boostrap_overrides/_component_fixes.scss */
.service-block__text-left {
  text-align: left;
}

/* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_audience_nav.scss */
.viu-audience-nav {
  display: none;
}

@media (min-width: 1200px) {
  /* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_audience_nav.scss */
  .viu-audience-nav {
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 101;
  }
}

@media (min-width: 1200px) {
  /* line 17, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_audience_nav.scss */
  .viuNavigation__audience_nav {
    display: none;
  }
}

/* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_audience_nav.scss */
.logged-in .viu-audience-nav {
  background: #007DBA;
}

/* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_audience_nav.scss */
.viu-audience-nav__list {
  margin: 0 auto;
  text-align: center;
  line-height: 1.25em;
}

/* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_audience_nav.scss */
.viu-audience-nav__list li {
  display: inline-block;
}

/* line 32, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_audience_nav.scss */
.viu-audience-nav__list li a {
  padding: .2em .5em;
  font-size: .8em;
  color: white;
}

/* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_audience_nav.scss */
.viu-audience-nav__list li a:hover {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.2);
}

/* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_audience_nav.scss */
.viu-audience-nav form {
  display: none;
}

/* line 49, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_audience_nav.scss */
#audience-search {
  background: #007DBA;
}

/* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
.viu-brand {
  position: relative;
  float: left;
  width: 16.66667%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  top: 20px;
  z-index: 2;
}

@media (min-width: 768px) {
  /* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
  .viu-brand {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
  .viu-brand {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 8.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
  .viu-brand {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
  .viu-brand {
    top: 60px;
  }
}

@media (min-width: 992px) {
  /* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
  .viu-brand {
    top: 75px;
  }
}

/* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
.page-admin .viu-brand {
  display: none;
}

/* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
.viu-logo-link {
  pointer-events: all;
}

/* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
.viu-logo {
  width: 100%;
}

@media (min-width: 768px) {
  /* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
  .viu-logo {
    position: relative;
    width: 60%;
    left: 20%;
  }
}

@media (min-width: 992px) {
  /* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
  .viu-logo {
    width: 100%;
    left: 0;
  }
}

/* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
.logo-grid-container--fixed {
  position: fixed;
  pointer-events: none;
  width: calc(100% - 10px * 2);
  top: 10px;
  z-index: 101;
  *zoom: 1;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.logo-grid-container--fixed:before, .logo-grid-container--fixed:after {
  content: " ";
  display: table;
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.logo-grid-container--fixed:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
  .logo-grid-container--fixed {
    width: calc(100% - 20px * 2);
    top: 20px;
  }
}

@media (min-width: 992px) {
  /* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_brand.scss */
  .logo-grid-container--fixed {
    width: calc(100% - 25px * 2);
    top: 25px;
  }
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_colored_page_frame.scss */
body.not-logged-in {
  padding: 10px;
}

@media (min-width: 768px) {
  /* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_colored_page_frame.scss */
  body.not-logged-in {
    padding: 20px;
  }
}

@media (min-width: 992px) {
  /* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_colored_page_frame.scss */
  body.not-logged-in {
    padding: 25px;
  }
}

/* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_colored_page_frame.scss */
body.not-logged-in:before {
  z-index: 100;
  content: '';
  user-select: none;
  pointer-events: none;
  position: fixed;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  border: solid 11px #003B5C;
  border-color: #003B5C;
  transition: border-color 0.5s ease;
}

@media (min-width: 768px) {
  /* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_colored_page_frame.scss */
  body.not-logged-in:before {
    border-width: 21px;
  }
}

@media (min-width: 992px) {
  /* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_colored_page_frame.scss */
  body.not-logged-in:before {
    border-width: 26px;
  }
}

@media (max-width: 767px) {
  /* line 39, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_colored_page_frame.scss */
  body.not-logged-in .container-fluid > .row:not(#footer):not(.header-hero-container) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* line 48, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_colored_page_frame.scss */
body.not-logged-in.darkblue:before {
  border-color: #003B5C;
}

/* line 51, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_colored_page_frame.scss */
body.not-logged-in.white:before {
  border-color: white;
}

/* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_colored_page_frame.scss */
body.not-logged-in.white .viu-audience-nav__list li a {
  color: #007DBA;
}

/* line 5, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter, body #footer {
  background: white;
  text-align: center;
  color: #003B5C;
}

/* line 11, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter-content {
  width: 100%;
}

/* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter a {
  color: #003B5C;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__linkList__heading {
  font-weight: bold;
  font-size: 14px;
}

@media (min-width: 768px) {
  /* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter__linkList__heading {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  /* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter__linkList__heading {
    font-size: 22px;
  }
}

/* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__logo {
  display: block;
  max-width: 150px;
  margin: 0 auto 2em auto;
}

/* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.footer__linkList__list {
  margin-left: 0;
  margin-bottom: 2em;
}

/* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__contact__heading {
  display: block;
  margin: 1em 0;
}

/* line 46, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__contact__address {
  margin-bottom: 1.5em;
}

/* line 48, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__contact__address address {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  /* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter__contact__address address {
    float: left;
    width: 50%;
  }
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter__contact__phone-email {
    float: left;
    width: 50%;
  }
}

/* line 67, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
#footer-google-map {
  max-width: 100%;
}

/* line 71, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__acknowledgement {
  text-align: center;
  margin-bottom: .5em;
}

/* line 76, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__primary {
  margin-bottom: 2em;
}

/* line 79, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__primary__inner {
  position: relative;
  float: left;
  width: 66.66667%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 16.66667%;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 79, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter__primary__inner {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 83.33333%;
  }
}

@media (min-width: 992px) {
  /* line 79, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter__primary__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 79, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter__primary__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 79, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter__primary__inner {
    margin-left: 8.33333%;
  }
}

/* line 86, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__links-secondary {
  text-align: center;
}

/* line 88, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__links-secondary li {
  display: inline-block;
}

/* line 90, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__links-secondary li:not(:last-child):after {
  content: " |";
}

/* line 94, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viuFooter__links-secondary a {
  font-size: 0.8em;
  font-weight: bold;
}

/* line 100, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
.viu-copyright {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  *zoom: 1;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.viu-copyright:before, .viu-copyright:after {
  content: " ";
  display: table;
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.viu-copyright:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 110, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter {
    text-align: left;
  }
  /* line 113, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter__logo {
    max-width: 200px;
  }
  /* line 117, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viu-copyright {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 101;
    color: white;
  }
  /* line 124, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viu-copyright a {
    color: white;
  }
  /* line 128, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter .page-section__content {
    padding-bottom: 1em;
  }
}

@media (min-width: 992px) {
  /* line 140, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viuFooter__logo {
    display: block;
    max-width: 250px;
    margin: 0 auto 4em auto;
  }
  /* line 145, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_footer.scss */
  .viu-copyright {
    line-height: 25px;
  }
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs {
  z-index: 1;
  position: relative;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs .page-section__content__inner {
  position: relative;
  float: left;
  width: 66.66667%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 16.66667%;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 66.66667%;
  }
}

@media (min-width: 992px) {
  /* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs .page-section__content__inner {
    margin-left: 16.66667%;
  }
}

@media (min-width: 768px) {
  /* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 83.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs .page-section__content__inner {
    margin-left: 8.33333%;
  }
}

/* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs.cols-2 .page-section__content__inner {
  position: relative;
  float: left;
  width: 66.66667%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 16.66667%;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs.cols-2 .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 75%;
  }
}

@media (min-width: 992px) {
  /* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs.cols-2 .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs.cols-2 .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs.cols-2 .page-section__content__inner {
    margin-left: 16.66667%;
  }
}

@media (min-width: 768px) {
  /* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs.cols-2 .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs.cols-2 .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 66.66667%;
  }
}

@media (min-width: 1200px) {
  /* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs.cols-2 .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs.cols-2 .page-section__content__inner {
    margin-left: 16.66667%;
  }
}

/* line 29, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs__icon, .globalCTAs__intro {
  display: none;
}

/* line 33, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs__cta {
  display: block;
  text-align: center;
  padding-right: 2em;
}

/* line 37, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs__cta p {
  margin-bottom: 1em;
}

/* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs__heading {
  font-size: 1.5em;
}

@media (max-width: 767px) {
  /* line 46, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .cta-button.globalCTAs__link {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  /* line 54, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs__cta {
    text-align: center;
    margin-bottom: 2em;
  }
  /* line 58, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs__icon {
    display: block;
    font-size: 3em;
    line-height: 1.5em;
  }
  /* line 63, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs__intro {
    display: inline;
  }
  /* line 65, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs__intro strong {
    display: block;
  }
  /* TODO - top selector to be deprecated */
  /* line 71, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .pane-viu-global-ctas .globalCTAs__link.cta-button,
  .globalCTAs--centered .globalCTAs__link.cta-button {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  /* line 81, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs__container {
    padding: 0;
  }
  /* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs__icon {
    font-size: 4em;
  }
  /* line 89, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs__cta {
    border: 0;
    margin-bottom: 0;
  }
  /* line 92, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs__cta:hover, .globalCTAs__cta:active, .globalCTAs__cta:focus {
    background: none;
    text-decoration: none;
  }
  /* line 98, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs__link {
    margin: 0;
  }
}

/* Flex CTAs left aligned */
/* line 105, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex .globalCTAs__cta {
  text-align: left;
}

/* Flex CTAs center aligned */
/* line 110, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex.ctas--text-centered .globalCTAs__cta {
  text-align: center;
}

/* line 112, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex.ctas--text-centered .globalCTAs__cta .globalCTAs__link {
  margin-left: auto;
  margin-right: auto;
}

/* line 120, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex.cols-3 .globalCTAs__cta, .globalCTAs--flex.cols-3 .image-block-ctas__cta, .image-block-ctas.cols-3 .globalCTAs__cta, .image-block-ctas.cols-3 .image-block-ctas__cta {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 120, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs--flex.cols-3 .globalCTAs__cta, .globalCTAs--flex.cols-3 .image-block-ctas__cta, .image-block-ctas.cols-3 .globalCTAs__cta, .image-block-ctas.cols-3 .image-block-ctas__cta {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 120, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs--flex.cols-3 .globalCTAs__cta, .globalCTAs--flex.cols-3 .image-block-ctas__cta, .image-block-ctas.cols-3 .globalCTAs__cta, .image-block-ctas.cols-3 .image-block-ctas__cta {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 120, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs--flex.cols-3 .globalCTAs__cta, .globalCTAs--flex.cols-3 .image-block-ctas__cta, .image-block-ctas.cols-3 .globalCTAs__cta, .image-block-ctas.cols-3 .image-block-ctas__cta {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 125, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex.cols-2 .globalCTAs__cta, .globalCTAs--flex.cols-2 .image-block-ctas__cta, .image-block-ctas.cols-2 .globalCTAs__cta, .image-block-ctas.cols-2 .image-block-ctas__cta {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 125, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs--flex.cols-2 .globalCTAs__cta, .globalCTAs--flex.cols-2 .image-block-ctas__cta, .image-block-ctas.cols-2 .globalCTAs__cta, .image-block-ctas.cols-2 .image-block-ctas__cta {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 125, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs--flex.cols-2 .globalCTAs__cta, .globalCTAs--flex.cols-2 .image-block-ctas__cta, .image-block-ctas.cols-2 .globalCTAs__cta, .image-block-ctas.cols-2 .image-block-ctas__cta {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  /* line 125, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs--flex.cols-2 .globalCTAs__cta, .globalCTAs--flex.cols-2 .image-block-ctas__cta, .image-block-ctas.cols-2 .globalCTAs__cta, .image-block-ctas.cols-2 .image-block-ctas__cta {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 130, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex.cols-1 .globalCTAs__cta, .globalCTAs--flex.cols-1 .image-block-ctas__cta, .image-block-ctas.cols-1 .globalCTAs__cta, .image-block-ctas.cols-1 .image-block-ctas__cta {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 130, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs--flex.cols-1 .globalCTAs__cta, .globalCTAs--flex.cols-1 .image-block-ctas__cta, .image-block-ctas.cols-1 .globalCTAs__cta, .image-block-ctas.cols-1 .image-block-ctas__cta {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 130, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs--flex.cols-1 .globalCTAs__cta, .globalCTAs--flex.cols-1 .image-block-ctas__cta, .image-block-ctas.cols-1 .globalCTAs__cta, .image-block-ctas.cols-1 .image-block-ctas__cta {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 130, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs--flex.cols-1 .globalCTAs__cta, .globalCTAs--flex.cols-1 .image-block-ctas__cta, .image-block-ctas.cols-1 .globalCTAs__cta, .image-block-ctas.cols-1 .image-block-ctas__cta {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Flex CTAs multi-row */
/* line 138, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex.cols-3 .globalCTAs__cta:nth-child(3n+1),
.globalCTAs--flex.cols-2 .globalCTAs__cta:nth-child(2n+1),
.globalCTAs--flex.col-1 .globalCTAs__cta:nth-child(1n+1) {
  clear: left;
}

/* line 144, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex.cols-3 .globalCTAs__cta:nth-child(n+4),
.globalCTAs--flex.cols-2 .globalCTAs__cta:nth-child(n+3) {
  margin-top: 3em;
}

/* line 149, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex.col-1 .globalCTAs__cta {
  margin-top: 1em;
  margin-bottom: 2em;
}

/* line 154, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex__heading {
  margin-bottom: 1em;
  text-align: center;
  font-size: 3em;
}

/* line 159, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.image-block-ctas__heading {
  margin-bottom: 1em;
  text-align: center;
  font-size: 2em;
}

/* line 164, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs--flex .globalCTAs__cta--has-image {
  text-align: center;
}

/* line 167, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs__cta--has-image .globalCTAs__link {
  margin-left: auto;
  margin-right: auto;
}

/* line 172, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
.globalCTAs__cta__image {
  display: none;
}

@media (min-width: 992px) {
  /* line 172, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_global_ctas.scss */
  .globalCTAs__cta__image {
    display: block;
    border-radius: 50%;
    max-width: 80%;
    margin: 0 auto;
  }
}

/* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_hero.scss */
.header-hero-container, .page-section.page-section--hero {
  padding: 0;
}

/* line 7, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_hero.scss */
.page-section.page-section--hero {
  position: relative;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 0;
}

@media (max-width: 767px) {
  /* line 7, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_hero.scss */
  .page-section.page-section--hero {
    padding-left: 14px;
    padding-right: 14px;
  }
}

/* line 20, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_hero.scss */
.page-section--hero .page-section__content {
  width: 100%;
}

/* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_hero.scss */
.page-section--hero .page-section__content__inner {
  position: relative;
  float: left;
  width: 66.66667%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 16.66667%;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: static;
}

@media (min-width: 768px) {
  /* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_hero.scss */
  .page-section--hero .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_hero.scss */
  .page-section--hero .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_hero.scss */
  .page-section--hero .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_hero.scss */
  .page-section--hero .page-section__content__inner {
    margin-left: 33.33333%;
  }
}

/* line 32, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_hero.scss */
.page-section--notch-height {
  border-bottom: 5px solid #003B5C;
}

/* line 6, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
.viu_title {
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 1em;
  position: absolute;
  top: 20px;
  margin-top: 0;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  font-weight: normal;
  letter-spacing: 0.2em;
  line-height: 1.2em;
}

@media (min-width: 768px) {
  /* line 6, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
  .viu_title {
    font-size: 1.25em;
    top: 60px;
    width: auto;
    left: 0;
    right: 0;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  /* line 6, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
  .viu_title {
    font-size: 1.5em;
    top: 75px;
  }
}

/* line 31, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
.viu_title strong {
  display: block;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px) and (max-height: 600px) {
  /* line 6, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
  .viu_title {
    position: static;
  }
}

/* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
.homepageHero__slogan {
  text-transform: none;
  font-size: 25px;
  margin-bottom: .4em;
}

@media (min-width: 768px) {
  /* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
  .homepageHero__slogan {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  /* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
  .homepageHero__slogan {
    font-size: 50px;
  }
}

/* line 57, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
.homepageHero__cta {
  width: 100%;
}

/* line 61, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
.page-section--search-hero .page-section__content {
  padding: 80px 0;
}

/* line 67, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
.page-section.page-section--search-hero {
  z-index: 3;
}

/* line 72, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
.homepageHero__search {
  margin-bottom: .5em;
}

@media (min-width: 768px) {
  /* line 72, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
  .homepageHero__search {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 83, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
.homepageHero__scroll {
  text-align: center;
  font-size: 25px;
  margin-top: 3em;
}

/* line 90, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
.homepageHero__scroll i {
  display: block;
  margin-top: .5em;
}

/* line 94, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_search_hero.scss */
.homepageHero__scroll a:hover, .homepageHero__scroll a:focus {
  text-decoration: none;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_video_hero.scss */
.homepageHero__video {
  display: none;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_video_hero.scss */
.page-section--homepage-video .page-section__bg {
  background-size: cover;
}

@media (min-width: 768px) {
  /* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_video_hero.scss */
  .homepageHero__video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* 1. No object-fit support: */
@media (min-width: 768px) and (min-aspect-ratio: 16 / 9) {
  /* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_video_hero.scss */
  .homepageHero__video {
    height: 300%;
    top: -100%;
  }
}

@media (min-width: 768px) and (max-aspect-ratio: 16 / 9) {
  /* line 29, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_video_hero.scss */
  .homepageHero__video {
    width: 300%;
    left: -100%;
  }
}

/* 2. If supporting object-fit, overriding (1): */
@supports (object-fit: cover) {
  /* line 33, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_header_video_hero.scss */
  .homepageHero__video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* line 2, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
html, body {
  height: auto;
  min-height: 100%;
}

/*
body.oa-responsive-region-left-on {
  padding-left: 42px - $frame-width-xs;
  @include breakpoint("sm"){
    padding-left: 42px - $frame-width-sm;
  }
  @include breakpoint("md"){
    padding-left: 42px - $frame-width-md;
  }
}

.panel-display .oa-responsive-desktop.oa-responsive-region-left {
  background: rgba(48, 48, 48, 1);
}
*/
/* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
.oa-fullwidth {
  margin-left: -4px;
  margin-right: -4px;
}

@media (min-width: 768px) {
  /* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
  .oa-fullwidth {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
  .oa-fullwidth {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
  .oa-fullwidth {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 39, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
body .container-fluid #footer {
  margin-left: -4px;
  margin-right: -4px;
  *zoom: 1;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
body .container-fluid #footer:before, body .container-fluid #footer:after {
  content: " ";
  display: table;
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
body .container-fluid #footer:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 39, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
  body .container-fluid #footer {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 39, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
  body .container-fluid #footer {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 39, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
  body .container-fluid #footer {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 44, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
.row.oa-responsive-region-row-left.oa-responsive-region-row-right {
  overflow: hidden;
}

/* line 48, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
.panel-display .oa-responsive-desktop.oa-responsive-region-left {
  background: #007DBA;
}

/* line 54, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
.oa-toolbar-style.navbar {
  z-index: 999;
}

@media (min-width: 992px) {
  /* line 54, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
  .oa-toolbar-style.navbar {
    margin-top: 25px;
  }
}

@media (min-width: 992px) {
  /* line 63, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
  .navbar .oa-responsive-regions-toggle-left {
    top: 41px !important;
  }
  /* line 66, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
  body.oa-responsive-region-left-on .panel-display .oa-responsive-desktop.oa-responsive-region-left, .panel-display .oa-responsive-desktop.oa-responsive-region-left.oa-responsive-expand {
    top: 25px;
  }
}

/* line 71, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
ul {
  list-style: disc;
}

/* line 74, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
ol {
  list-style: decimal;
}

/* line 78, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
.panel-pane.pane-views-panes, .panel-pane {
  border-right: 0 !important;
}

/* line 80, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_oa_basetheme_overrides.scss */
.panel-pane.pane-views-panes:after, .panel-pane:after {
  border: 0 !important;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
html {
  overflow-x: hidden;
}

/* line 7, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
body {
  overflow-x: auto;
}

/* line 11, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.page-admin .pane-page-content {
  padding: 20px 20px;
  border-right: 1px solid #E4E3E6;
  border-bottom: 1px solid #E4E3E6;
}

/* line 17, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.breadcrumb {
  margin-bottom: 10px;
}

/* line 23, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
#main-wrapper #main:focus {
  outline: none;
}

@media (min-width: 992px) {
  /* line 29, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  #main-wrapper.oa-layout-fluid {
    max-width: 95%;
  }
}

/* line 37, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
body.oa-home .pane-page-content .radix-layouts-content > .panel-panel-inner {
  background-color: transparent;
}

/* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
body #footer {
  margin: 0 -4px;
  font-size: 1em;
  line-height: 1.5;
  background-color: #003853;
  border-top: 0;
  overflow: visible;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
body.oa-no-page-title #main-wrapper #main {
  padding: 40px 0;
}

/* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.panel-display .oa-responsive-desktop.oa-responsive-region-left .btn {
  color: white;
}

/* line 57, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.panel-display .oa-responsive-desktop.oa-responsive-region-left .btn:hover {
  color: #5bbd5b;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

/* line 61, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.panel-display .oa-responsive-desktop.oa-responsive-region-left .btn.btn-circle {
  width: 36px;
  height: 36px;
  margin-left: 2px;
  padding: 7px;
}

/* line 71, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.oa-layout-sidebar1 {
  width: 42px;
  left: -50;
}

/* line 76, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.navbar-tray {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.59);
}

/* line 80, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.navbar .oa-responsive-regions-toggle-left {
  position: fixed;
  padding: 0 10px;
  left: 0;
  top: 16px;
}

/* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.navbar .oa-responsive-regions-toggle-left.btn {
  color: #DDD;
  background: transparent;
  border-color: transparent;
  z-index: 1001;
}

/* line 90, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.navbar .oa-responsive-regions-toggle-left.btn.btn-circle {
  width: 36px;
  height: 36px;
  padding: 3px;
  margin-left: 2px;
}

/* line 97, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.navbar .oa-responsive-regions-toggle-left .fa {
  font-size: 2em;
  margin-right: 0;
}

@media (max-width: 767px) {
  /* line 103, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .navbar .oa-responsive-regions-toggle-left {
    top: 8px;
  }
}

/* line 111, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
body.oa-responsive-region-left-on .navbar .oa-navbar .oa-responsive-regions-toggle-left.btn.btn-circle:hover, body.oa-responsive-region-left-on .navbar .oa-navbar .oa-responsive-regions-toggle-left.btn.btn-circle:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background: transparent !important;
  border-color: gray;
}

/* line 121, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
#toolbar-menu-button.btn-circle {
  padding: 7px 10px;
}

/** VIU Layout **/
/* line 128, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
body {
  position: relative;
}

/* line 132, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
#main-wrapper {
  background: transparent !important;
}

/* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
body:not(.fullscreen-hero) .row.oa-flex-grow,
.header-style-all_large:not(.fullscreen-hero) .row.oa-flex-grow,
.header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
  margin-top: -45vh;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  /* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  body:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-all_large:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -460.8px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  /* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  body:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-all_large:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -345.6px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  /* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  body:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-all_large:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -216px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  /* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  body:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-all_large:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -144px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  /* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  body:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-all_large:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -255.6px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  /* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  body:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-all_large:not(.fullscreen-hero) .row.oa-flex-grow,
  .header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -144px;
  }
}

/* line 150, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.node-type-viu-content-page.header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
  margin-top: -85vh;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  /* line 150, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .node-type-viu-content-page.header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -870.4px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  /* line 150, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .node-type-viu-content-page.header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -652.8px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  /* line 150, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .node-type-viu-content-page.header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -408px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  /* line 150, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .node-type-viu-content-page.header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -272px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  /* line 150, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .node-type-viu-content-page.header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -482.8px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  /* line 150, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .node-type-viu-content-page.header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -272px;
  }
}

/* line 155, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.node-type-viu-content-page.header-style-home_large:not(.fullscreen-hero).no_notch .page-section--notch-height, .node-type-viu-content-page.header-style-home_large:not(.fullscreen-hero).no_notch .page-section--notch-height .page-section__content {
  min-height: 35vh;
}

/* line 163, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.header-style-all_small:not(.fullscreen-hero) .row.oa-flex-grow {
  margin-top: -85vh;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  /* line 163, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .header-style-all_small:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -870.4px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  /* line 163, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .header-style-all_small:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -652.8px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  /* line 163, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .header-style-all_small:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -408px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  /* line 163, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .header-style-all_small:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -272px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  /* line 163, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .header-style-all_small:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -482.8px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  /* line 163, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .header-style-all_small:not(.fullscreen-hero) .row.oa-flex-grow {
    margin-top: -272px;
  }
}

/* line 168, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.header-style-all_small:not(.fullscreen-hero).no_notch .page-section--notch-height, .header-style-all_small:not(.fullscreen-hero).no_notch .page-section--notch-height .page-section__content {
  min-height: 35vh;
}

/* line 175, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
body.no_notch .row.oa-flex-grow {
  margin-top: 0 !important;
}

/* line 178, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
body.no_notch .row.oa-flex-grow .main {
  padding-top: 0 !important;
}

/* line 184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.content-canvas {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-canvas {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 91.66667%;
  }
}

@media (min-width: 992px) {
  /* line 184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-canvas {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-canvas {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-canvas {
    margin-left: 8.33333%;
  }
}

@media (min-width: 768px) {
  /* line 184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-canvas {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-canvas {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 83.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-canvas {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 184, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-canvas {
    margin-left: 8.33333%;
  }
}

/* line 191, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.content-canvas .content-canvas-inner {
  background: white;
  padding-top: 2em;
  border-top: 5px solid #003B5C;
}

/* line 198, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
#site-context-heading {
  text-align: left;
  font-size: 20px;
  display: inline-block;
  background: #003B5C;
  color: white;
  padding: .5em 1em;
  margin: 0;
}

/* line 206, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
#site-context-heading a {
  color: white;
  font-weight: bold;
}

/* line 214, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.fullscreen-hero #site-context-heading {
  display: none;
}

/* line 217, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.fullscreen-hero .content-canvas .content-canvas-inner {
  border: 0;
}

/* line 222, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.no_notch #site-context-heading {
  display: none;
}

@media (min-width: 992px) {
  /* line 226, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .no_notch .content-canvas {
    position: relative;
  }
  /* line 229, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .no_notch #site-context-heading {
    display: block;
    position: absolute;
    bottom: 100%;
    left: -10px;
  }
}

/* line 236, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.no_notch .content-canvas .content-canvas-inner {
  border: 0;
}

/** Actual content container within the canvas
    Because this is nested within the canvas above,
    and design specs require it to conform to the
    outer 12 col grid, we must redefine the grid-columns
    for the nested grids, depending on the breakpoint of
    its container above.
**/
/* line 249, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.content-container, .no_notch .site-context-container {
  position: relative;
  float: left;
  width: 91.66667%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 8.33333%;
}

/* line 256, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.content-container, .no_notch .site-context-container {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 256, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-container, .no_notch .site-context-container {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 81.81818%;
  }
}

@media (min-width: 992px) {
  /* line 256, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-container, .no_notch .site-context-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 256, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-container, .no_notch .site-context-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 256, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-container, .no_notch .site-context-container {
    margin-left: 9.09091%;
  }
}

/* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.content-container, .no_notch .site-context-container {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-container, .no_notch .site-context-container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-container, .no_notch .site-context-container {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 80%;
  }
}

@media (min-width: 1200px) {
  /* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-container, .no_notch .site-context-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
  .content-container, .no_notch .site-context-container {
    margin-left: 10%;
  }
}

/* line 270, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.page-admin .content-container, .page-admin.no_notch .site-context-container {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 0%;
}

/* Adjustments for admin interfaces */
/* line 278, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.page-admin .container,
.page-node-edit .container {
  width: auto;
}

/* line 281, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.page-admin #main-wrapper,
.page-node-edit #main-wrapper {
  margin-top: 0px;
}

/* line 284, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.page-admin .viu-audience-nav,
.page-node-edit .viu-audience-nav {
  position: absolute;
}

/* line 290, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.content .panel-pane.pane-page-content {
  z-index: 2;
  padding-bottom: 1em;
}

/* line 297, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
#coffee-form.hide-form {
  display: none;
}

/* line 305, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.no-content-padding #main-wrapper #main {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* line 309, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.no-content-padding h1.title {
  display: none;
}

/* line 312, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.no-content-padding .content-canvas .content-canvas-inner {
  padding-top: 0;
}

/* line 315, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_page.scss */
.no-content-padding .content .panel-pane.pane-page-content {
  padding-bottom: 0;
}

/**
 * Fix for vw, vh, vmin, vmax on iOS 7.
 * http://caniuse.com/#feat=viewport-units
 *
 * This fix works by replacing viewport units with px values on known screen sizes.
 *
 * iPhone 6 and 6 Plus cannot run iOS 7, so are not targeted by this fix.
 * Target devices running iOS 8+ will incidentally execute the media query,
 * but this will still produce the expected result; so this is not a problem.
 *
 * As an example, replace:
 *
 *   height: 50vh;
 *   font-size: 5vmin;
 *
 * with:
 *
 *   @include viewport-unit(height, 50vh);
 *   @include viewport-unit(font-size, 5vmin);
 */
/* line 7, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.program-header ul {
  margin-left: 0;
}

/* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.program-header li {
  margin-bottom: 1em;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 992px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 25%;
  }
}

@media (min-width: 1200px) {
  /* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .program-header li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.program-header .field-item {
  font-weight: bold;
  color: #003B5C;
}

/* line 20, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.field-label:before {
  font-family: FontAwesome;
  margin-right: .5em;
}

/* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.field-program-length .field-label:before {
  content: "\f133";
}

/* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.field-credential .field-label:before {
  content: "\f19d";
}

/* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.field-program-options .field-label:before {
  content: "\f14a";
}

/* line 33, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.field-campus .field-label:before {
  content: "\f041";
}

/* line 37, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.field-intake-next .field-label:before {
  content: "\f252";
}

/* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.field-program-website a {
  display: table;
  margin: 1.5em 0;
  padding: 0.6em 1em;
  border-radius: 500px;
  border: 1px solid;
  line-height: 1.2em;
  text-align: center;
}

/* line 37, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.field-program-website a:hover, .field-program-website a:focus {
  text-decoration: none;
}

/* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.program-website-links {
  clear: both;
}

/* line 47, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.program-website-links .field, .program-website-links .cta-button {
  display: inline-block;
  vertical-align: top;
  margin-right: .5em;
}

/* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.node-program .view-display-id-intake_pre_open .view-content, .node-program .view-display-id-intake_open .view-content {
  margin: 1.5em 0;
  padding: 0.6em 0;
}

/* line 65, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.row--apps_accepting .program_node__intake_next {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 65, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 65, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 65, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 65, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__intake_next {
    margin-left: 33.33333%;
  }
}

/* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.row--apps_accepting .program_node__accepting_apply {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  /* adjust for above mixin not actually implemented properly in twbs 3 */
}

@media (min-width: 768px) {
  /* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    margin-left: -33.33333%;
  }
}

@media (min-width: 992px) {
  /* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__accepting_apply {
    margin-left: -66.6666%;
  }
}

/* line 77, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.row--apps_accepting .program_node__program_website {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 77, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__program_website {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 77, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__program_website {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 77, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_accepting .program_node__program_website {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 82, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.row--apps_open .program_node__applications_open {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 82, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__applications_open {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 82, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__applications_open {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  /* line 82, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__applications_open {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.row--apps_open .program_node__program_website {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__program_website {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__program_website {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  /* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .row--apps_open .program_node__program_website {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 91, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .node-program .field-intake-pre {
    float: left;
    margin-right: 2em;
  }
  /* line 95, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
  .node-program .field-program-website {
    float: right;
  }
}

/* Intake tables */
/* line 102, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.clickable {
  cursor: pointer;
}

/* line 106, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.clickable:not(.collapsed) .fa-plus-square:before {
  content: "\f146";
}

/* line 111, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_program_node.scss */
.viuIntake__detail {
  margin-bottom: 0.4em;
}

/* line 8, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.pane-node-body {
  margin-bottom: 1em;
}

/* line 12, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.panel-group {
  padding-bottom: 1.0em;
}

/* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.paragraphs-items-field-content-paragraphs > .entity-paragraphs-item {
  margin-top: 1.0em;
  margin-bottom: 1.0em;
  padding-top: 0;
  padding-bottom: 0;
}

/* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.paragraphs-items-field-content-paragraphs > .section-spacing {
  margin-top: 1.0em;
}

/* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.paragraphs-items-field-content-paragraphs > .section-spacing + .section-spacing,
.paragraphs-items-field-content-paragraphs > .section-spacing:first-child {
  margin-top: 0 !important;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.paragraphs-items-field-content-paragraphs .panel-group {
  margin-bottom: 0;
}

/* line 38, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.paragraphs-items-field-content-paragraphs h1:first-child, .paragraphs-items-field-content-paragraphs h2:first-child, .paragraphs-items-field-content-paragraphs h3:first-child {
  margin-top: 0;
}

/*
.fullscreen-hero .oa-flex-grow{
  margin-top: -2em;
  .content-canvas .content-canvas-inner{
    background-color:transparent;
  }
}
*/
/* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section__content__inner {
  position: relative;
  float: left;
  width: 91.66667%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 8.33333%;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  z-index: 2;
}

@media (min-width: 768px) {
  /* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 75%;
  }
}

@media (min-width: 992px) {
  /* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__content__inner {
    margin-left: 16.66667%;
  }
}

@media (min-width: 768px) {
  /* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 66.66667%;
  }
}

@media (min-width: 1200px) {
  /* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__content__inner {
    margin-left: 16.66667%;
  }
}

@media (max-width: 767px) {
  /* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__content__inner {
    padding-right: 25px;
  }
}

/* line 71, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  /* line 71, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section {
    padding-left: 24px;
    padding-right: 24px;
  }
}

/* line 84, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.parallax-combo__inner {
  width: 100%;
}

/* line 88, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  z-index: 1;
  opacity: 0.5;
  overflow: hidden;
}

@media (min-width: 768px) {
  /* line 88, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__bg {
    opacity: 0.7;
  }
}

/* line 101, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--no-dim .page-section__bg {
  opacity: 1;
}

@media (min-width: 768px) {
  /* line 101, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--no-dim .page-section__bg {
    opacity: 1;
  }
}

/* line 108, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--hero:not(.px--text-light):not(.px--text-dark) .page-section__bg {
  opacity: 1;
}

@media (min-width: 992px) {
  /* line 114, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin .page-section__bg,
  .page-section--bg-image.page-section--pull .page-section__bg {
    opacity: 1;
  }
  /* line 117, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin .page-section__bg:before,
  .page-section--bg-image.page-section--pull .page-section__bg:before {
    opacity: 0;
    transition: opacity 1s;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  /* line 127, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin .page-section__bg.dim:before,
  .page-section--bg-image.page-section--pull .page-section__bg.dim:before {
    opacity: 1;
  }
  /* line 131, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin .page-section__bg:before {
    background-color: rgba(0, 0, 0, 0.4);
  }
  /* line 134, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin.page-section--color-3 .page-section__bg:before {
    background: rgba(255, 255, 255, 0.4);
  }
  /* line 137, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin .page-section__bg:before {
    background-color: rgba(0, 0, 0, 0.4);
  }
  /* line 140, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin.page-section--color-3 .page-section__bg:before {
    background: rgba(255, 255, 255, 0.4);
  }
}

@media only screen and (min-width: 992px) and (orientation: landscape) {
  /* line 147, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin.page-section--pull-right .page-section__bg:before, .page-section--bg-image.page-section--pull-right .page-section__bg:before {
    /* https://www.cssmatic.com/gradient-generator#'\-moz\-linear\-gradient\%28left\%2C\%20rgba\%280\%2C0\%2C0\%2C0\%29\%2044\%25\%2C\%20rgba\%280\%2C0\%2C0\%2C0\.66\%29\%2064\%25\%29\%3B' */
    background-color: rgba(0, 0, 0, 0.3);
    background: -moz-linear-gradient(left, transparent 44%, rgba(0, 0, 0, 0.66) 64%);
    background: -webkit-gradient(left top, right top, color-stop(44%, transparent), color-stop(64%, rgba(0, 0, 0, 0.66)));
    background: -webkit-linear-gradient(left, transparent 44%, rgba(0, 0, 0, 0.66) 64%);
    background: -o-linear-gradient(left, transparent 44%, rgba(0, 0, 0, 0.66) 64%);
    background: -ms-linear-gradient(left, transparent 44%, rgba(0, 0, 0, 0.66) 64%);
    background: linear-gradient(to right, transparent 44%, rgba(0, 0, 0, 0.66) 64%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
  }
  /* line 158, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin.page-section--pull-left .page-section__bg:before, .page-section--bg-image.page-section--pull-left .page-section__bg:before {
    background-color: rgba(0, 0, 0, 0.3);
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.66) 36%, transparent 56%);
    background: -webkit-gradient(left top, right top, color-stop(36%, rgba(0, 0, 0, 0.66)), color-stop(56%, transparent));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.66) 36%, transparent 56%);
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0.66) 36%, transparent 56%);
    background: -ms-linear-gradient(left, rgba(0, 0, 0, 0.66) 36%, transparent 56%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.66) 36%, transparent 56%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
  }
  /* line 168, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin.page-section--pull-right.page-section--color-3 .page-section__bg:before, .page-section--bg-image.page-section--pull-right.page-section--color-3 .page-section__bg:before {
    /* https://www.cssmatic.com/gradient-generator#'\-moz\-linear\-gradient\%28left\%2C\%20rgba\%280\%2C0\%2C0\%2C0\%29\%2044\%25\%2C\%20rgba\%280\%2C0\%2C0\%2C0\.66\%29\%2064\%25\%29\%3B' */
    background: rgba(255, 255, 255, 0.3);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 44%, rgba(255, 255, 255, 0.66) 64%);
    background: -webkit-gradient(left top, right top, color-stop(44%, rgba(255, 255, 255, 0)), color-stop(64%, rgba(255, 255, 255, 0.66)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 44%, rgba(255, 255, 255, 0.66) 64%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 44%, rgba(255, 255, 255, 0.66) 64%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 44%, rgba(255, 255, 255, 0.66) 64%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 44%, rgba(255, 255, 255, 0.66) 64%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
  }
  /* line 179, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pin.page-section--pull-left.page-section--color-3 .page-section__bg:before, .page-section--bg-image.page-section--pull-left.page-section--color-3 .page-section__bg:before {
    background: rgba(255, 255, 255, 0.3);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.66) 36%, rgba(255, 255, 255, 0) 56%);
    background: -webkit-gradient(left top, right top, color-stop(36%, rgba(255, 255, 255, 0.66)), color-stop(56%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.66) 36%, rgba(255, 255, 255, 0) 56%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0.66) 36%, rgba(255, 255, 255, 0) 56%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0.66) 36%, rgba(255, 255, 255, 0) 56%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.66) 36%, rgba(255, 255, 255, 0) 56%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
  }
}

@media (min-width: 768px) {
  /* line 195, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax .page-section__bg {
    height: 130%;
  }
}

/* line 206, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section__content {
  position: relative;
  padding-top: 2em;
  padding-bottom: 2em;
  z-index: 2;
}

/* line 213, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section__content__center {
  width: 100%;
  min-height: 1px;
}

/* line 219, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section .page-section__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  min-height: 1px;
}

/* line 233, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--center .page-section__content__inner {
  text-align: center;
}

/* line 239, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--pull-left .page-section__content__inner {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 239, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pull-left .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 239, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pull-left .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 239, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pull-left .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 239, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pull-left .page-section__content__inner {
    margin-left: 16.66667%;
  }
}

/* line 246, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--pull-right .page-section__content__inner {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 246, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pull-right .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 246, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pull-right .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 246, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pull-right .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 246, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--pull-right .page-section__content__inner {
    margin-left: 58.33333%;
  }
}

/* line 252, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--parallax {
  min-height: 65vh;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  /* line 252, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax {
    min-height: 665.6px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  /* line 252, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax {
    min-height: 499.2px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  /* line 252, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax {
    min-height: 312px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  /* line 252, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax {
    min-height: 208px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  /* line 252, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax {
    min-height: 369.2px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  /* line 252, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax {
    min-height: 208px;
  }
}

/* line 255, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--parallax .page-section__content {
  min-height: 65vh;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  /* line 255, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax .page-section__content {
    min-height: 665.6px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  /* line 255, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax .page-section__content {
    min-height: 499.2px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  /* line 255, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax .page-section__content {
    min-height: 312px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  /* line 255, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax .page-section__content {
    min-height: 208px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  /* line 255, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax .page-section__content {
    min-height: 369.2px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  /* line 255, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax .page-section__content {
    min-height: 208px;
  }
}

/* line 261, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--full-height {
  min-height: 100vh;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  /* line 261, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height {
    min-height: 1024px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  /* line 261, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height {
    min-height: 768px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  /* line 261, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height {
    min-height: 480px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  /* line 261, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height {
    min-height: 320px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  /* line 261, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height {
    min-height: 568px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  /* line 261, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height {
    min-height: 320px;
  }
}

/* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--full-height .page-section__content {
  min-height: 100vh;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  /* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height .page-section__content {
    min-height: 1024px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  /* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height .page-section__content {
    min-height: 768px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  /* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height .page-section__content {
    min-height: 480px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  /* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height .page-section__content {
    min-height: 320px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  /* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height .page-section__content {
    min-height: 568px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  /* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--full-height .page-section__content {
    min-height: 320px;
  }
}

/* line 271, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--reduced-padding .page-section__content {
  padding-top: 1em;
  padding-bottom: 1em;
}

@media (min-width: 768px) {
  /* line 271, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--reduced-padding .page-section__content {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

/* line 282, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--no-padding .page-section__content {
  padding-top: 1em;
  padding-bottom: 1em;
}

/* line 288, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--reduced-padding-top .page-section__content {
  padding-top: 1em;
}

@media (min-width: 768px) {
  /* line 288, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--reduced-padding-top .page-section__content {
    padding-top: 2em;
  }
}

/* line 297, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--no-padding-top .page-section__content {
  padding-top: 1em;
}

/* line 303, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--reduced-padding-bottom .page-section__content {
  padding-bottom: 1em;
}

@media (min-width: 768px) {
  /* line 303, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--reduced-padding-bottom .page-section__content {
    padding-bottom: 2em;
  }
}

/* line 312, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--no-padding-bottom .page-section__content {
  padding-bottom: 1em;
}

/* line 317, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--parallax.px--text-light .page-section__content {
  text-shadow: 0px 0px 2em rgba(0, 0, 0, 0.85);
}

/* line 320, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--parallax.px--text-dark .page-section__content {
  text-shadow: 0px 0px 2em rgba(255, 255, 255, 0.85);
}

/* line 327, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--parallax-combo {
  min-height: 100vh;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  /* line 327, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax-combo {
    min-height: 1024px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  /* line 327, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax-combo {
    min-height: 768px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  /* line 327, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax-combo {
    min-height: 480px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  /* line 327, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax-combo {
    min-height: 320px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  /* line 327, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax-combo {
    min-height: 568px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  /* line 327, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--parallax-combo {
    min-height: 320px;
  }
}

/* line 332, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section.page-section--parallax-combo {
  padding: 0;
}

/* line 336, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.parallax-combo__heading_large {
  font-size: 30px;
}

@media (min-width: 768px) {
  /* line 336, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo__heading_large {
    font-size: 50px;
  }
}

@media (min-width: 992px) {
  /* line 336, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo__heading_large {
    font-size: 60px;
  }
}

@media (min-width: 768px) {
  /* line 349, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section__content {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  /* line 356, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo .page-section--parallax .page-section__content {
    text-align: center;
  }
  /* line 360, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo .page-section__content {
    padding: 50px 0;
  }
  /* line 363, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo .parallax-combo__content {
    min-height: 35vh;
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  /* line 363, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo .parallax-combo__content {
    min-height: 358.4px;
  }
}

@media only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  /* line 363, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo .parallax-combo__content {
    min-height: 268.8px;
  }
}

@media only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
  /* line 363, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo .parallax-combo__content {
    min-height: 168px;
  }
}

@media only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
  /* line 363, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo .parallax-combo__content {
    min-height: 112px;
  }
}

@media only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
  /* line 363, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo .parallax-combo__content {
    min-height: 198.8px;
  }
}

@media only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
  /* line 363, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .parallax-combo .parallax-combo__content {
    min-height: 112px;
  }
}

/* line 374, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.scrollmagic-pin-spacer {
  display: block !important;
}

/* line 379, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section__bg-clip {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

/* line 387, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section__bg picture img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

/* line 397, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.paragraphs-item-viu-text-section {
  padding: 30px 0;
}

/* line 403, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.field-paragraph-text {
  margin-top: 3em;
}

/* line 410, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--map {
  min-height: 65vh;
}

/* line 412, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--map iframe {
  min-height: 65vh;
}

/* line 419, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--youtube {
  min-height: 65vh;
}

/* line 421, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--youtube .player {
  min-height: 65vh;
}

/* line 424, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--youtube iframe {
  width: 100%;
  min-height: 65vh;
  height: 100%;
}

/* Text and Image */
/* line 433, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--text-and-image .page-section__content__inner {
  position: relative;
  float: left;
  width: 66.66667%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 16.66667%;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 433, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    width: 66.66667%;
  }
}

@media (min-width: 992px) {
  /* line 433, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 433, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  /* line 433, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image .page-section__content__inner {
    margin-left: 16.66667%;
  }
}

@media (min-width: 768px) {
  /* line 433, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image .page-section__content__inner {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 433, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image .page-section__content__inner {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 83.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 433, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image .page-section__content__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 433, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image .page-section__content__inner {
    margin-left: 8.33333%;
  }
}

@media (min-width: 992px) {
  /* line 433, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image .page-section__content__inner {
    display: flex;
    flex-direction: row-reverse;
  }
}

@media (min-width: 992px) {
  /* line 445, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image.page-section--text-image-pull-right .page-section__content__inner {
    flex-direction: row;
  }
}

/* line 450, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--text-and-image.page-section--text-image-center .page-section__content__inner {
  text-align: center;
}

/* line 455, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--text-and-image__image {
  margin-bottom: 2rem;
  padding-right: 0;
}

@media (min-width: 992px) {
  /* line 455, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image__image {
    flex: 0 0 350px;
  }
}

/* line 459, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section--text-and-image__image img {
  display: block;
  border-radius: 50%;
  max-width: 80%;
  margin: 0 auto;
}

@media (min-width: 992px) {
  /* line 455, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image__image {
    margin-left: 3em;
    max-width: 350px;
  }
  /* line 468, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-and-image__image img {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  /* line 474, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
  .page-section--text-image-pull-right .page-section--text-and-image__image {
    margin-left: 0;
    margin-right: 3em;
  }
}

/* line 484, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_sections.scss */
.page-section__button .cta-button {
  display: inline-block;
  margin-right: 0.5em;
}

/* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
.pane-viu-social {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
  .pane-viu-social {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
  .pane-viu-social {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 8.33333%;
  }
}

@media (min-width: 1200px) {
  /* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
  .pane-viu-social {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  /* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
  .pane-viu-social {
    margin-left: 83.33333%;
  }
}

/* line 8, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
.viu-social {
  margin: 0;
  padding: 1em 0;
  clear: both;
  list-style-type: none;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
.viu-social li {
  display: inline-block;
  margin-bottom: .75em;
}

/* line 17, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
.viu-social a {
  transition: color 0.5s ease;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
.viu-social a:hover {
  opacity: .7;
}

/* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
.viu-social .icon-fallback {
  display: none;
}

/* line 28, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
.viu-social__socialIcon {
  margin-right: 0.5em;
  font-size: 20px;
}

@media (min-width: 768px) {
  /* line 28, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
  .viu-social__socialIcon {
    font-size: 25px;
  }
}

/* line 37, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_social.scss */
.viu-social.reverse-color a {
  color: white;
}

/* line 6, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-listview > li {
  height: auto;
}

/* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-menu, .mm-panel {
  background: #003B5C;
  color: white;
}

/* line 15, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-panels {
  background: #003B5C;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-panels > .mm-panel {
  overflow-y: hidden;
}

/* line 23, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
vertical > .mm-panel, .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: #003B5C;
}

/* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
  padding: 10px 0 10px 0px;
}

/* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-listview, .mm-navbar .mm-navbar__title {
  font-size: .75em;
  line-height: 1.1em;
  font-weight: bold;
}

/* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-listview > li {
  margin-left: auto;
  margin-right: auto;
  width: 82%;
  max-width: 400px;
}

@media (min-width: 768px) {
  /* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
  .mm-listview > li {
    max-width: 470px;
  }
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-listview > li > a, .mm-listview > li > span {
  padding: .7em .7em .7em 0;
  white-space: normal;
}

/* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-listview > li:not(.mm-divider):after {
  width: 300%;
  left: -100%;
  border-color: #00A6F6;
}

/* line 51, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-panel:not(.mm-hasnavbar) .mm-listview > li:not(.mm-divider):first-child:before {
  content: '';
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #00A6F6;
  display: block;
  position: absolute;
  left: -100%;
  width: 300%;
  right: 0;
  top: 0;
}

/* line 63, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-next, .mm-btn.mm-prev {
  text-align: center;
  padding: 0;
  height: 2.4em;
}

/* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-btn.mm-prev {
  padding: 0;
  width: 30px;
  font-size: .75em;
}

@media (min-width: 768px) {
  /* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
  .mm-btn.mm-prev {
    width: 60px;
  }
}

/* line 79, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-btn_next:after {
  border-color: white;
}

/* line 82, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-btn_next:hover:after {
  border-color: #00A6F6;
}

/* line 86, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-listitem__btn {
  width: 50px;
}

/* line 89, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-btn_next:after {
  right: 15px;
}

/*
.mm-next:hover{
  color: $brand-primary;
}
.mm-listview .mm-next:before{
  border:0;
}

.mm-next:after,.mm-prev:before{
  border:0;
  display: inline-block;
  height:auto;
  width:auto;
  bottom:auto;
  right:.5em;
  left:auto;
  font-size:1em;
  font-family:FontAwesome;
  position: absolute;
  top: 50%;
  transform:unset;
  transform: translateY(-50%);
}

.mm-next:after{
  content:"\f061";
}
.mm-prev:before{
  color:white;
  content:"\f060";
  font-family:FontAwesome;
}
*/
/* line 128, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-panels > .mm-panel:first-child .mm-navbar {
  display: none;
}

/* line 132, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-navbar {
  background: #007DBA;
  border: 0;
  padding: 0;
}

/* line 137, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-navbar > * {
  padding: .7em 0;
}

/* line 140, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-navbar .mm-navbar__title {
  text-align: left;
  margin: 0 auto;
  width: 70%;
  position: relative;
  max-width: 400px;
  overflow: visible;
}

@media (min-width: 768px) {
  /* line 140, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
  .mm-navbar .mm-navbar__title {
    max-width: 520px;
  }
  /* line 150, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
  .mm-navbar .mm-navbar__title:before {
    content: "";
    width: 5px;
    background: #003B5C;
    display: block;
    position: absolute;
    top: -50%;
    height: 200%;
    left: -15px;
  }
}

/* line 161, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-navbar .mm-navbar__title > span {
  width: 100%;
}

/* line 166, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: #003B5C;
}

/* line 170, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.viuNavigation__social .mm-listitem__text {
  flex: none;
}

/* line 174, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 1.8em;
}

/* line 180, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-navbar__btn:before {
  border-color: white;
}

/* line 183, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-listitem__btn:not(.mm-listitem__text) {
  border-left: 1px solid #007DBA;
}

/* line 188, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.expanded.active-trail .mm-next {
  color: #003B5C;
}

/* line 192, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen), .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a:not(.mm-fullsubopen) {
  transition: color .2s ease;
}

/* line 196, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
  background: transparent;
  color: #003B5C;
}

/* line 200, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen):hover {
  background: transparent;
  color: #003B5C;
}

/* line 206, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-menu a, .mm-navbar__title {
  transition: padding-left, color !important;
  transition-duration: 250ms,250ms !important;
}

/* line 210, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-menu a:hover,
.mm-navbar__title:hover,
.mm-navbar__btn:not(.mm-hidden) + .mm-navbar__title:hover {
  padding-left: 0.5em;
}

/* line 216, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-menu a, .mm-menu a:active, .mm-menu a:hover, .mm-menu a:link, .mm-menu a:visited {
  color: white;
}

/* line 220, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_mmenu.scss */
.mm-listitem_selected > .mm-listitem__text,
.mm-menu_selected-hover .mm-listitem__btn:hover, .mm-menu_selected-hover .mm-listitem__text:hover {
  background: none;
}

/* line 1, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_search.scss */
.viu_program_quicksearch {
  position: relative;
}

/* line 3, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_search.scss */
.viu_program_quicksearch input {
  padding: 0.4em 1em;
  height: auto;
}

/* line 7, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_search.scss */
.viu_program_quicksearch button {
  border: 0;
  background: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: .5em;
  color: #007DBA;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_search.scss */
.viuNavigation__search {
  font-size: .75em;
  position: relative;
  margin-bottom: 2em;
}

/* line 23, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_search.scss */
.viuNavigation__search input {
  background: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 500px;
  text-align: center;
  font-size: 1em;
}

/* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_search.scss */
.viuNavigation__search input::placeholder {
  color: white;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_search.scss */
.viuNavigation__search button {
  color: white;
}

/* line 1, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 500;
  width: 100%;
  background: #003B5C;
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
.sidr.right {
  left: auto;
  right: -100%;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
.sidr.left {
  left: -100%;
  right: auto;
}

@media (min-width: 768px) {
  /* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
  .sidr {
    width: 600px;
  }
  /* line 29, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
  .sidr.right {
    left: auto;
    right: -600px;
  }
  /* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
  .sidr.left {
    left: -600px;
    right: auto;
  }
}

@media (min-width: 1200px) {
  /* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
  .sidr {
    width: 700px;
  }
  /* line 43, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
  .sidr.right {
    left: auto;
    right: -700px;
  }
  /* line 48, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
  .sidr.left {
    left: -700px;
    right: auto;
  }
}

/* line 58, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
#overlay {
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 102;
  display: none;
}

/* line 72, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav_sidr.scss */
.sidr-open #overlay {
  display: block;
}

/* line 2, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
#viuNavigation {
  font-size: 1.5em;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__inner {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 80px;
}

@media (min-width: 992px) {
  /* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
  .viuNavigation__inner {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  /* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
  .viuNavigation__toggle-button__text {
    display: none;
  }
}

/* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
#viuNavigation__toggle-button {
  position: absolute;
  font-size: 22px;
  top: 30px;
  background: #003B5C;
  padding: .75em 0 .75em 1em;
  text-decoration: none;
  color: white;
  white-space: nowrap;
  right: 100%;
  padding-right: 10px;
  width: 60px;
  transition: right 0.1s;
}

/* line 43, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
#viuNavigation__toggle-button:hover {
  right: calc(100% + 5px);
}

@media (min-width: 768px) {
  /* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
  #viuNavigation__toggle-button {
    padding-right: 20px;
    top: 80px;
    width: 160px;
  }
}

@media (min-width: 992px) {
  /* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
  #viuNavigation__toggle-button {
    padding-right: 25px;
    top: 100px;
  }
}

/* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__toggle-button__icon {
  display: inline-block;
  height: 15px;
  width: 20px;
  margin-right: .25em;
  position: relative;
}

/* line 64, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__toggle-button__icon span,
.viuNavigation__toggle-button__icon span:before,
.viuNavigation__toggle-button__icon span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 20px;
  background: white;
  position: absolute;
  display: inline-block;
  content: '';
  top: 0px;
}

/* line 77, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__toggle-button__icon span:before {
  top: 6px;
}

/* line 80, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__toggle-button__icon span:after {
  top: 12px;
}

/* line 84, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation--open #viuNavigation__toggle-button {
  padding-right: 0;
}

/* line 86, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation--open #viuNavigation__toggle-button:hover {
  right: 100%;
}

/* line 92, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
#viuNavigation__close-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  color: white;
  vertical-align: middle;
}

/* line 98, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
#viuNavigation__close-btn em {
  font-style: normal;
}

@media (min-width: 768px) {
  /* line 92, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
  #viuNavigation__close-btn {
    display: none;
  }
}

/* line 106, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__utilities {
  margin-left: auto;
  margin-right: auto;
  width: 82%;
  max-width: 400px;
}

@media (min-width: 768px) {
  /* line 106, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
  .viuNavigation__utilities {
    max-width: 470px;
  }
}

/* line 110, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__audience_nav {
  margin-left: 0;
  margin-bottom: 2em;
  columns: 2;
  font-size: 13px;
  list-style-type: none;
}

/* line 116, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__audience_nav li {
  margin-bottom: .5em;
}

/* line 121, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.cta-button.viuNavigation__apply_btn {
  background: #007DBA;
  color: white;
  display: block;
  text-align: center;
  font-size: .75em;
  margin: 1em 0;
}

/* line 131, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__audience_nav a {
  color: white;
}

/* line 133, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__audience_nav a:hover {
  color: white;
}

/* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__menu-header, .viuNavigation__toggle-menu {
  display: block;
  color: white;
  font-size: 1em;
  font-weight: bold;
  position: relative;
  padding: 10px 50px 10px 0;
  margin-left: auto;
  margin-right: auto;
  width: 82%;
  max-width: 400px;
}

@media (min-width: 992px) {
  /* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
  .viuNavigation__menu-header, .viuNavigation__toggle-menu {
    font-size: 1.25em;
  }
}

@media (min-width: 768px) {
  /* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
  .viuNavigation__menu-header, .viuNavigation__toggle-menu {
    max-width: 470px;
  }
}

/* line 152, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__menu-header:hover, .viuNavigation__toggle-menu:hover, .viuNavigation__menu-header:focus, .viuNavigation__toggle-menu:focus {
  text-decoration: none;
  color: white;
}

/* line 160, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__toggle-menu:after {
  content: '\f056';
  font-family: FontAwesome;
  position: absolute;
  right: 0px;
  width: 50px;
  text-align: right;
  padding-right: .25em;
  color: #00A6F6;
}

/* line 171, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__toggle-menu.collapsed:after {
  content: '\f055';
  color: white;
}

/* line 178, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__social a {
  display: inline-block !important;
  padding: .45em .45em .45em 0 !important;
}

/* line 181, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__social a:hover {
  color: #003B5C;
}

/* line 185, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__social .viu-social__socialIcon {
  font-size: 1.25em;
}

/* line 188, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
.viuNavigation__social .icon-fallback {
  display: none;
}

/* Bounce for attention */
@media (min-width: 992px) {
  /* line 196, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/layout/_viuNav.scss */
  .viuNavigation__toggle-button--bounce {
    animation-name: bounce;
    animation-timing-function: ease;
    animation-duration: 4s;
    animation-iteration-count: 3;
    transform-origin: bottom;
  }
  @keyframes bounce {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-20px);
    }
    30% {
      transform: translateX(0);
    }
    32% {
      transform: translateX(-2px);
    }
    38% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
}

/* line 1, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_advisory.scss */
#advisory, #covid-advisory {
  background: #ffc107;
  margin: 0 auto;
  padding: 10px 30px;
  color: #3c3c3c;
  z-index: 5;
  align-self: flex-start;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

/* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_advisory.scss */
#advisory a, #covid-advisory a {
  color: #3c3c3c;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_advisory.scss */
#advisory .cta-button, #covid-advisory .cta-button {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 20px;
  background: #e69519 !important;
  border-color: #e69519 !important;
  padding: 0.4em 0.75em;
  color: black;
}

/* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_advisory.scss */
#advisory .cta-button:hover, #covid-advisory .cta-button:hover {
  background: #ffa41c !important;
  border-color: #ffa41c !important;
}

/* line 28, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_advisory.scss */
#covid-advisory {
  background: #0078B3;
  color: white;
}

/* line 31, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_advisory.scss */
#covid-advisory a {
  color: white;
}

@media (max-width: 767px) {
  /* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_advisory.scss */
  #advisory, #covid-advisory {
    width: calc( 100% + 30px);
    margin-left: -15px;
    padding: 10px 80px;
  }
}

@media (min-width: 768px) {
  /* line 44, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_advisory.scss */
  .page-section.viu-covid .page-section__content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_bootstrap_panel.scss */
.panel {
  box-shadow: none;
  border-width: 0;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_bootstrap_panel.scss */
.panel-default > .panel-heading {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #D2D1D1;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_bootstrap_panel.scss */
.panel-default > .panel-heading .pane-title {
  color: #003B5C;
}

/* line 2, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_brightedge_lem_overrides.scss */
.row--brightedge {
  background-color: #003B5C;
}

/* line 6, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_brightedge_lem_overrides.scss */
.be-ix-link-block {
  background-color: transparent !important;
  margin-left: 0px !important;
  padding: 30px 0 !important;
  width: 100%;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_buttons.scss */
.btn-default {
  display: table;
  margin: 1.5em 0;
  padding: 0.6em 1em;
  border-radius: 500px;
  border: 1px solid;
  line-height: 1.2em;
  text-align: center;
  font-size: 1em;
  box-shadow: none;
}

/* line 37, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.btn-default:hover, .btn-default:focus {
  text-decoration: none;
}

/* line 12, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_buttons.scss */
.cta-button {
  display: table;
  margin: 1.5em 0;
  padding: 0.6em 1em;
  border-radius: 500px;
  border: 1px solid;
  line-height: 1.2em;
  text-align: center;
}

/* line 37, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.cta-button:hover, .cta-button:focus {
  text-decoration: none;
}

/* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_buttons.scss */
.cta-button--centered {
  margin-left: auto;
  margin-right: auto;
}

/* line 11, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.fullcalendar .fc-event {
  margin-bottom: 4px;
  padding: 2px;
  line-height: 1.35em;
  font-size: 9pt;
}

@media (max-width: 767px) {
  /* line 11, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
  .fullcalendar .fc-event {
    padding: 0px;
    font-size: 7pt;
  }
}

/* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.fullcalendar .fc-event-time {
  display: block;
}

/* line 31, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.fullcalendar .fc-agenda .fc-event-time {
  display: none;
}

/* line 38, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.fullcalendar .fc-view-basicDay .fc-state-highlight {
  background: transparent;
}

/* line 42, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.fullcalendar .fc-view-basicDay .fc-event {
  padding: 2px;
  margin-bottom: 4px;
  line-height: 1.35em;
  font-size: 9pt;
}

/* line 54, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.fullcalendar .fc-header-title h2 {
  font-size: 18px;
}

@media (max-width: 767px) {
  /* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
  .fullcalendar .fc-header-left {
    float: left;
    margin-bottom: 10px;
  }
  /* line 65, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
  .fullcalendar .fc-header-center {
    display: block;
    float: left;
    clear: left;
  }
  /* line 71, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
  .fullcalendar .fc-header-right {
    float: left;
    clear: left;
    text-align: left;
  }
}

/* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.fullcalendar-legend .fc-event a:focus, .fullcalendar-legend .fc-event a:hover, .fullcalendar-legend .fc-event a:active {
  color: inherit;
  text-decoration: none;
}

/* line 89, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.fullcalendar-legend .fc-event a:before {
  content: "\f058";
  font-family: FontAwesome;
  margin-right: .5em;
}

/* line 95, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.fullcalendar-legend .fc-event-hidden a:before {
  content: "\f1db";
}

/* line 105, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.view-oa-event-list .item-list li {
  list-style-type: none;
  margin-left: 0;
  line-height: 1.3;
}

/* line 110, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.oa-event-date-wrapper {
  height: auto;
  width: 45px;
  border: 0;
  background: #003f5f;
  color: white;
  padding: 2px 0;
  margin: 0 10px 10px 0px;
}

/* line 119, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.oa-event-date-month-wrapper {
  background: transparent;
  margin: 0;
  height: auto;
  line-height: 1.5em;
}

/* line 125, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.oa-event-date-month {
  font-weight: normal;
}

/* line 128, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_calendar.scss */
.oa-event-date-day {
  font-size: 1.4em;
  font-weight: bold;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_callout_box.scss */
.viu-callout-box {
  padding: 2em 1.5em;
  margin: 2em 0 3em 0;
}

/* line 8, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_callout_box.scss */
.viu-callout-box h3 {
  margin-top: 0px;
}

/* line 11, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_callout_box.scss */
.viu-callout-box .cta-button {
  margin-bottom: .5em;
}

/* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_callout_box.scss */
.field-callout-image {
  margin-top: 1.5em;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
.oa-reply-icon {
  display: inline;
}

/* line 8, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
.oa-reply-text {
  display: none;
}

/* line 12, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
#comments {
  margin-top: 0;
}

/* line 17, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
.oa-list.oa-comment .accordion-toggle {
  border-bottom: 1px solid #DDD;
  padding-bottom: 15px;
}

/* line 23, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
.oa-list.oa-comment .links {
  float: left;
  color: #a4a8ab;
  text-transform: uppercase;
  font-size: 11px;
  margin-left: -5px;
}

/* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
.oa-list.oa-comment .comment-label .oa-pullout-left {
  position: static;
  border: none;
  display: inline-block;
  padding: 3px 3px;
  background: #D6F5D6;
  margin-left: 5px;
  color: #a4a8ab;
  font-size: 10px;
  font-weight: normal;
  height: auto;
  min-width: 0;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
  line-height: 1em;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
.comment-label > a {
  display: inline-block;
  padding: 3px 3px;
  background: #D6F5D6;
  margin-left: 5px;
  color: #a4a8ab;
  font-size: 10px;
  font-weight: normal;
  height: auto;
  min-width: 0;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
  line-height: 1em;
}

/* line 67, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
.pane-node-comments .oa-list .oa-comment-hide,
.pane-content #comments .oa-list .oa-comment-hide {
  height: 64px;
}

/* line 72, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
td.views-field-comment-count {
  position: relative;
  text-align: center;
}

/* line 75, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
td.views-field-comment-count span {
  z-index: 2;
  position: relative;
  display: inline-block;
  color: white;
  width: 2em;
  text-align: center;
  margin: 0 3px;
  font-size: 12px;
}

/* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_comment.scss */
td.views-field-comment-count:after {
  content: "\f075";
  font-family: FontAwesome;
  position: absolute;
  z-index: 1;
  left: 50%;
  font-size: 25px;
  top: 1px;
  margin: 0 3px 0 -12px;
  color: rgba(64, 64, 64, 0.7);
}

/*
// This used to be in panopoly_images but was removed and told to put into theme
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

// This used to be in compass_radix to override panopoly_images
.field img.panopoly-image-full,
.field img.panopoly-image-half,
.field img.panopoly-image-quarter {
  max-width: 100%;
  width: auto;
  height: auto;
  float: none;
  margin: 0;
}

// reduce list padding
ul, ol{
  padding-left: 20px;
}
*/
/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.pdf']:before {
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 0.1em;
  padding-right: 0.2em;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.doc']:before {
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 0.1em;
  padding-right: 0.2em;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.docx']:before {
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 0.1em;
  padding-right: 0.2em;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.xls']:before {
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 0.1em;
  padding-right: 0.2em;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.xlsx']:before {
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 0.1em;
  padding-right: 0.2em;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.ppt']:before {
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 0.1em;
  padding-right: 0.2em;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.pot']:before {
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 0.1em;
  padding-right: 0.2em;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.dot']:before {
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 0.1em;
  padding-right: 0.2em;
}

/* line 42, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.pdf']:before {
  content: "\f1c1";
}

/* line 46, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.doc']:before, a[href$='.dot']:before, a[href$='.docx']:before {
  content: "\f1c2";
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.xls']:before, a[href$='.xlsx']:before {
  content: "\f1c3";
}

/* line 54, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a[href$='.ppt']:before, a[href$='.pot']:before {
  content: "\f1c4";
}

/* line 58, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
.content a[href*="//"]:not([href*="viu.ca"]):after,
#nav-site a[href*="//"]:not([href*="viu.ca"]):after {
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 0.2em;
  padding-right: 0.1em;
  content: "\f08e";
  font-size: 0.7em;
  vertical-align: super;
}

/* line 70, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
a.img-link:before,
a.img-link:after, #nav-site a.img-link[href*="//"]:not([href*="viu.ca"]):after, .content a.img-link[href*="//"]:not([href*="viu.ca"]):after {
  content: none;
}

/* line 75, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
.hideBeforeText:before {
  content: none !important;
}

/* line 80, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
iframe {
  max-width: 100%;
}

/* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_elements.scss */
img[src*="icon.gif"].media-image {
  display: none;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_field.scss */
.views-field-field-oa-worktracker-priority,
.views-field-comment-count,
.views-field-field-oa-worktracker-task-type,
.views-field-field-oa-worktracker-task-status {
  text-align: center;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
#edit-submit-program-area-course-live-results {
  display: none;
}

/* line 8, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
#autocomplete {
  z-index: 105;
  border: none;
  background: #fff;
  box-shadow: 0px 0px 5px #888888;
  border-radius: 20px;
  transform: translateY(10px);
  padding: 20px 0;
}

/* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
#autocomplete .live-result-search-item-wrapper {
  text-align: left;
  padding: 10px 20px;
  white-space: normal;
}

/* line 20, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
#autocomplete .live-result-search-item-wrapper.selected {
  background: #007DBA;
}

/* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
#autocomplete .live-result-search-item-wrapper.selected a, #autocomplete .live-result-search-item-wrapper.selected .viuSearchLR__Title, #autocomplete .live-result-search-item-wrapper.selected .viuSearchLR__subTitle {
  color: white;
  text-decoration: none;
}

/* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
#autocomplete .live-result-search-item-wrapper .viuSearchLR__Title {
  font-size: 1em;
  margin-bottom: 0;
  margin-top: 0;
  color: #007DBA;
}

/* line 33, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
#autocomplete .live-result-search-item-wrapper .viuSearchLR__subTitle {
  font-size: .8em;
  color: #007DBA;
}

/* line 37, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
#autocomplete .live-result-search-item-wrapper .viuSearchLR__advanced-link {
  color: #007DBA;
}

/* line 43, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
.views-exposed-widget.views-widget-filter-search_api_views_fulltext {
  float: none;
}

/* line 47, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
.input-group .form-control.live-results-search-textfield:not(:first-child):not(:last-child) {
  border-radius: 500px;
}

/* line 52, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
.form-autocomplete.live-results-search-textfield {
  color: #003B5C;
  border-radius: 500px;
}

/* line 58, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
html.js .form-autocomplete.live-results-search-textfield {
  background-image: url("/profiles/viu/themes/viu_theme/assets/images/icon/search-icon.svg");
  background-size: 20px;
  background-position: right 1em top 50% !important;
}

/* line 63, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
html.js .form-autocomplete.live-results-search-textfield::-ms-clear {
  display: none;
}

/* line 68, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form_program_search.scss */
html.js .live-results-search-textfield.throbbing {
  background-image: url("/profiles/viu/themes/viu_theme/assets/images/icon/AjaxLoader.gif");
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
#node-edit .form-actions input {
  background-color: white;
  color: #333;
  border-color: #C1C1C1;
}

/* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
form .form-actions {
  background-color: transparent;
}

/* line 12, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
form .form-actions .btn-default {
  display: inline-block;
}

/* line 17, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.form-control {
  font-size: 1em;
  height: auto;
}

/* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
input[type="text"], input[type="email"], input[type="password"], .ui-autocomplete-input, textarea, .uneditable-input {
  padding: 0.5em 1em;
  font-size: 1em;
}

/* line 30, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.chosen-container {
  width: 100% !important;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.chosen-container.form-control {
  font-size: .8em;
  box-shadow: none;
}

/* line 38, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.chosen-container .chosen-choices {
  background-image: none;
  border-radius: 8px;
  padding: .3em;
}

/* line 43, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  height: 100%;
}

/* line 48, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.chosen-container-multi .chosen-choices li.search-choice {
  background: #007DBA;
  color: white;
  border: 0;
  padding: 8px 25px 8px 10px;
  box-shadow: none;
  border-radius: 8px;
}

/* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  background: none;
  font-size: 1.1em;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}

/* line 61, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
  content: "X";
  font-weight: bold;
  color: white;
}

/* line 70, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.chosen-container .chosen-results li.highlighted {
  background: #007DBA;
}

/* line 76, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.view-curriculum-approval .views-submit-button {
  clear: both;
}

/* line 79, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.view-curriculum-approval .views-reset-button, .view-curriculum-approval .views-submit-button {
  float: right;
  padding: .5em .5em 0 0;
}

/*  WEBFORM CHANGES */
/* line 88, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.webform-container-inline .form-control {
  display: inline-block;
  width: auto;
}

/* line 93, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
#ui-datepicker-div {
  z-index: 4 !important;
}

/* line 98, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
form .webform-component {
  margin: 1em 0;
}

/* line 100, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
form .webform-component .form-textarea-wrapper {
  margin-bottom: 1.5em;
}

/* line 106, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_form.scss */
.webform-component-fieldset .fieldset-legend {
  font-weight: bold;
  font-size: 2rem;
}

/* line 5, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_image_cta_grid.scss */
.progAreaGrid__link {
  display: block;
  position: relative;
  margin-bottom: 30px;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_image_cta_grid.scss */
.progAreaGrid__link:hover .progAreaGrid__image:before {
  opacity: .8;
  background: rgba(255, 255, 255, 0);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, transparent 25%, black 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, transparent), color-stop(100%, black));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, transparent 25%, black 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, transparent 25%, black 100%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, transparent 25%, black 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, transparent 25%, black 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
}

/* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_image_cta_grid.scss */
.progAreaGrid__image:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #003B5C;
  opacity: .65;
  transition: opacity 0.25s ease, background 0.25s ease;
}

/* line 31, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_image_cta_grid.scss */
.progAreaGrid__title {
  position: absolute;
  bottom: .5em;
  left: 1em;
  padding-right: 1em;
  color: white;
  text-shadow: 0px 0px 2em rgba(0, 0, 0, 0.75);
  font-size: 1.2em;
}

/* line 6, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_gallery.scss */
.ss_thumbs ul {
  list-style-type: none !important;
  padding-left: 0 !important;
}

/* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_gallery.scss */
.ss_thumbs li {
  display: inline-block;
  width: 130px;
  height: 130px;
  padding: 15px;
  vertical-align: top;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu {
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0;
  font-size: .8em;
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li {
  padding: 0;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li:not(:last-child) {
  border-bottom: 0;
}

/* line 21, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li .nolink {
  color: #555;
  display: block;
  padding: 7px 10px;
}

/* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li, .nav-viu li.active, .nav-viu li.active:hover, .nav-viu li.active:focus {
  border-color: rgba(0, 0, 0, 0.1);
}

/* line 29, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li a {
  color: #555;
  display: block;
  padding: 7px 10px;
  transition: all 0.1s ease-in-out 0s;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li a:hover {
  text-decoration: none;
  background: #2D83C5;
  color: white;
}

/* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li.expanded > a, .nav-viu li.expanded > a.active, .nav-viu li.expanded > .nolink {
  padding-right: 40px;
}

/* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li ul {
  padding: 0;
  margin-left: 0;
  list-style: none;
}

/* line 51, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li.active, .nav-viu li.active:hover {
  background-color: transparent;
}

/* line 55, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li li {
  border-bottom: 0;
  border-right: 0;
}

/* line 58, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li li, .nav-viu li li.active, .nav-viu li li.active:hover {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* line 62, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li a.active {
  color: white;
  background: #2D83C5;
}

/* line 65, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li a.active:hover {
  color: white;
}

/* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu li i.fa {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 85%;
}

/* line 85, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu > li.active-trail.active > a, .nav-viu > li.active-trail.active > .nolink {
  position: relative;
  color: #FFF;
}

/* line 88, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu > li.active-trail.active > a:after, .nav-viu > li.active-trail.active > .nolink:after {
  content: "";
  display: block;
  background: #003F5F;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -1px;
  z-index: 3;
  left: 0;
}

/* line 100, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu > li.active-trail.active > a:hover, .nav-viu > li.active-trail.active a:after {
  color: white;
}

/* line 105, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nav-viu .collapse, .nav-viu .collapsing {
  background: rgba(0, 0, 0, 0.04);
  margin-left: 8px;
}

/* line 114, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.list-toggle.menu-block-handle {
  border: 0;
  position: absolute;
  background: transparent;
  top: 0;
  right: 0;
  padding: 7px 10px;
}

/* line 121, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.list-toggle.menu-block-handle span {
  display: inline-block;
  background: transparent;
  text-indent: -9999em;
}

/* line 126, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.list-toggle.menu-block-handle:after {
  color: #777;
  font-size: 13px;
  height: 20px;
  width: 20px;
  line-height: 19px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid transparent;
  top: 50%;
  margin-top: -10px;
}

/* line 138, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.list-toggle.menu-block-handle.active:after {
  color: #777;
  line-height: 18px;
}

/* line 143, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.list-toggle.menu-block-handle:hover:after {
  border: 0;
  background: #2D83C5;
  color: #fff;
}

/* line 149, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
a.active + .list-toggle.menu-block-handle:after, a:hover + .list-toggle.menu-block-handle:after {
  color: #fff;
}

/* line 155, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.nolink + .menu-block-handle {
  width: 100%;
}

@media (min-width: 768px) {
  /* line 164, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu {
    display: table;
    display: flex;
  }
  /* line 167, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu > li {
    position: relative;
    float: none;
    display: table-cell;
    display: flex;
    -webkit-align-items: stretch;
    align-items: stretch;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    z-index: 3;
  }
  /* line 179, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu > li:hover:before, .main-nav-viu > li.active-trail:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: #003F5F;
  }
  /* line 188, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu > li > .nolink, .main-nav-viu > li > a, .main-nav-viu > li > a.active {
    font-size: 12px;
    padding: 10px 20px;
  }
  /* line 193, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu > li > a:hover, .main-nav-viu > li > a.active:hover {
    background: #fff;
    color: #555;
  }
  /* line 196, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu > li > a:hover + .menu-block-handle:after, .main-nav-viu > li > a.active:hover + .menu-block-handle:after {
    color: #777;
  }
  /* line 201, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu > li > .list-toggle {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  /* line 204, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu > li > .list-toggle:after {
    content: "\f107";
  }
  /* line 208, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu > li > .subnav {
    position: absolute;
    margin: 0;
    min-width: 250px;
    background: #fff;
    left: 0;
    top: 100%;
    z-index: 9;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  /* line 218, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu > li.edge > .subnav {
    left: auto;
    right: 0;
  }
  /* line 226, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .no-touch .main-nav-viu .list-toggle {
    display: none;
  }
  /* line 229, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .no-touch .main-nav-viu .subnav .subnav {
    position: absolute;
    left: 100%;
    min-width: 250px;
    top: -1px;
    margin-left: 0;
    background: #fff;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  /* line 239, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .no-touch .main-nav-viu .subnav .edge .subnav {
    left: auto;
    right: 100%;
    z-index: 1;
  }
  /* line 245, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .no-touch .main-nav-viu .expanded > a, .no-touch .main-nav-viu .expanded > .nolink {
    padding-right: 30px;
  }
  /* line 247, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .no-touch .main-nav-viu .expanded > a:before, .no-touch .main-nav-viu .expanded > .nolink:before {
    top: 10px;
    right: 15px;
    font-size: 11px;
    content: "\f107";
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome;
  }
  /* line 259, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .no-touch .main-nav-viu .expanded:hover > .subnav {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: 0.1s 0.2s;
    height: auto !important;
  }
  /* line 270, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .no-touch .main-nav-viu .subnav .expanded > a:before, .no-touch .main-nav-viu .subnav .expanded > .nolink:before {
    content: "\f105";
  }
  /* line 275, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .no-touch .main-nav-viu .collapse {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: 0.1s 0.2s;
  }
  /* line 284, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .main-nav-viu .collapse.in {
    display: none;
  }
  /* line 289, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .touch .main-nav-viu.ready .collapse.in {
    display: block;
  }
}

@media (min-width: 768px) {
  /* line 299, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .header-viu .dropdown > a::after {
    right: 10px;
  }
}

@media (min-width: 992px) {
  /* line 299, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
  .header-viu .dropdown > a::after {
    right: 15px;
  }
}

/* line 315, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.region-footer {
  overflow: hidden;
}

/* line 317, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.region-footer ul.menu {
  margin-bottom: 0;
}

/* line 319, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.region-footer ul.menu > li {
  float: left;
  min-width: 150px;
  list-style: none !important;
}

/* line 323, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.region-footer ul.menu > li > a {
  font-weight: bold;
  font-size: 14px;
}

/* line 328, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.region-footer ul.menu > li > ul > li {
  float: none;
}

/* line 330, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.region-footer ul.menu > li > ul > li > a {
  font-weight: normal;
  font-size: 12px;
}

/* line 337, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.region-footer ul.menu a {
  border: 0;
  display: block;
  padding: 0 5px;
}

/* line 341, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.region-footer ul.menu a:hover {
  background-color: transparent;
  text-decoration: underline;
}

/* line 350, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
#main ul.dropdown-menu ul li a:hover {
  background-color: #7b7b7b;
  background-image: -moz-linear-gradient(top, gray, #737373);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(gray), to(#737373));
  background-image: -webkit-linear-gradient(top, gray, #737373);
  background-image: -o-linear-gradient(top, gray, #737373);
  background-image: linear-gradient(to bottom, gray, #737373);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7d7d7d', endColorstr='#717171', GradientType=0);
}

/* line 355, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.viu a.nolink:hover {
  cursor: default;
}

/* line 359, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_menu.scss */
.toolbar-search .btn {
  background: none;
  color: #ffffff;
}

/* line 1, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_paragraphs.scss */
.student-story__photo img {
  display: none;
}

@media (min-width: 992px) {
  /* line 1, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_paragraphs.scss */
  .student-story__photo img {
    display: block;
    border-radius: 50%;
    max-width: 80%;
    margin: 0 auto;
  }
}

/*Icons
------------------------------------*/
/*Social Icons*/
/* line 5, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social-icons {
  margin: 0;
  padding: 0;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social-icons li {
  list-style: none;
  margin-right: 3px;
  margin-bottom: 5px;
  text-indent: -9999px;
  display: inline-block;
}

/* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social-icons li a {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

/* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
a.social-icon {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

/* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social-icons li:hover a {
  background-position: 0 -38px;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social-icons-color li a {
  opacity: 0.7;
  background-position: 0 -38px !important;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}

/* line 47, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social-icons-color li a:hover {
  opacity: 1;
}

/* line 52, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_amazon {
  background: url(../images/icon/social/amazon.png) no-repeat;
}

/* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_behance {
  background: url(../images/icon/social/behance.png) no-repeat;
}

/* line 60, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_blogger {
  background: url(../images/icon/social/blogger.png) no-repeat;
}

/* line 64, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_deviantart {
  background: url(../images/icon/social/deviantart.png) no-repeat;
}

/* line 68, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_dribbble {
  background: url(../images/icon/social/dribbble.png) no-repeat;
}

/* line 72, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_dropbox {
  background: url(../images/icon/social/dropbox.png) no-repeat;
}

/* line 76, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_evernote {
  background: url(../images/icon/social/evernote.png) no-repeat;
}

/* line 80, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_facebook {
  background: url(../images/icon/social/facebook.png) no-repeat;
}

/* line 84, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_forrst {
  background: url(../images/icon/social/forrst.png) no-repeat;
}

/* line 88, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_github {
  background: url(../images/icon/social/github.png) no-repeat;
}

/* line 92, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_googleplus {
  background: url(../images/icon/social/googleplus.png) no-repeat;
}

/* line 96, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_jolicloud {
  background: url(../images/icon/social/jolicloud.png) no-repeat;
}

/* line 100, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_last-fm {
  background: url(../images/icon/social/last-fm.png) no-repeat;
}

/* line 104, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_linkedin {
  background: url(../images/icon/social/linkedin.png) no-repeat;
}

/* line 108, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_picasa {
  background: url(../images/icon/social/picasa.png) no-repeat;
}

/* line 112, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_pintrest {
  background: url(../images/icon/social/pintrest.png) no-repeat;
}

/* line 116, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_rss {
  background: url(../images/icon/social/rss.png) no-repeat;
}

/* line 120, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_skype {
  background: url(../images/icon/social/skype.png) no-repeat;
}

/* line 124, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_spotify {
  background: url(../images/icon/social/spotify.png) no-repeat;
}

/* line 128, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_stumbleupon {
  background: url(../images/icon/social/stumbleupon.png) no-repeat;
}

/* line 132, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_tumblr {
  background: url(../images/icon/social/tumblr.png) no-repeat;
}

/* line 136, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_twitter {
  background: url(../images/icon/social/twitter.png) no-repeat;
}

/* line 140, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_vimeo {
  background: url(../images/icon/social/vimeo.png) no-repeat;
}

/* line 144, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_wordpress {
  background: url(../images/icon/social/wordpress.png) no-repeat;
}

/* line 148, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_xing {
  background: url(../images/icon/social/xing.png) no-repeat;
}

/* line 152, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_yahoo {
  background: url(../images/icon/social/yahoo.png) no-repeat;
}

/* line 156, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_youtube {
  background: url(../images/icon/social/youtube.png) no-repeat;
}

/* line 160, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_vk {
  background: url(../images/icon/social/vk.png) no-repeat;
}

/* line 164, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_legacy_social.scss */
.social_instagram {
  background: url(../images/icon/social/instagram.png) no-repeat;
}

/* line 5, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu_hint.scss */
.menu-hint {
  position: absolute;
  right: calc(100% + 40px);
  top: 160px;
  width: 250px;
  background: #fff;
  border: 3px solid #BFD22B;
  border-radius: .4em;
  color: #0F3B5C;
  font-size: .6em;
  font-weight: bold;
  padding: 1em 1em 3em 1em;
}

@media only screen and (max-width: 767px) {
  /* line 5, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu_hint.scss */
  .menu-hint {
    right: calc(100% + 10px);
    top: 90px;
  }
}

/* line 21, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu_hint.scss */
.menu-hint a {
  color: white;
  display: block;
  position: absolute;
  bottom: 1.25em;
  right: 1.25em;
  background: #0078B3;
  font-size: .8em;
  padding: .25em .5em;
  font-weight: bold;
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu_hint.scss */
.menu-hint:before {
  content: '';
  position: absolute;
  top: 0;
  left: 90%;
  width: 0;
  height: 0;
  border: 13px solid transparent;
  border-bottom-color: #BFD22B;
  border-top: 0;
  margin-left: -13px;
  margin-top: -14px;
}

/* line 47, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu_hint.scss */
.menu-hint:after {
  content: '';
  position: absolute;
  top: 0;
  left: 90%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  border-top: 0;
  margin-left: -10px;
  margin-top: -10px;
}

/* line 7, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.oa_toolbar .dropdown-menu {
  height: auto;
  max-height: 300px;
  overflow-x: hidden;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.oa_toolbar .dropdown-menu.oa-noscroll {
  overflow-x: visible;
  max-height: none;
}

/* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.oa_toolbar .dropdown > a > i {
  pointer-events: none;
}

/* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.oa-navbar .dropdown .dropdown-menu .oa-column {
  border-right: 1px solid #ECECF0;
}

/* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.oa-navbar .dropdown .dropdown-menu .oa-column:last-child {
  border-right: none;
}

/* line 32, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.navbar .oa-navbar {
  box-shadow: inset 0px -1px 0 0 rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  padding-bottom: 10px;
}

/* line 37, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.navbar .oa-navbar .pane-oa-toolbar-oa-breadcrumb ul.oa_toolbar li.btn-group > .btn,
.navbar .oa-navbar .pane-oa-space-nav ul.oa_toolbar li.btn-group > .btn {
  height: auto;
  padding-bottom: 4px;
}

/* line 42, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.navbar .oa-navbar .panel-panel {
  margin: 0;
}

@media (max-width: 767px) {
  /* line 47, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
  .navbar .oa-navbar {
    padding: 0;
  }
}

/* line 52, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.pane-oa-space-menu {
  border-bottom: 1px solid #E7E7E7;
}

/* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.pane-oa-edit-minipanel .pull-right .dropdown-menu {
  left: auto;
  right: 0;
}

/* line 61, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.dropup ul.dropdown-menu {
  margin-bottom: 0;
}

/* line 65, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.pane-oa-toolbar-search ul.dropdown-menu {
  margin-top: -2px;
}

/* line 70, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
ul.dropdown-menu .item-list h4 {
  text-shadow: none;
  background: none;
  padding: 3px 20px;
  line-height: 20px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

/* line 81, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
#oa-user-badge .dropdown-menu ul.oa-user-links {
  border-top: 1px solid #D1D1D4;
  margin-top: 0.5em;
  padding-top: 0.5em;
}

@media (max-width: 767px) {
  /* line 88, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
  .oa-navigation {
    width: 100%;
  }
  /* line 90, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
  .oa-navigation nav {
    width: 100%;
  }
  /* line 93, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
  .oa-navigation ul.menu {
    background: #555;
  }
  /* line 96, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
  .oa-navigation ul.menu a > .caret {
    float: right;
    margin: 6px 12px;
    height: 12px;
    cursor: pointer;
  }
}

/* line 109, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.oa-navbar ul.oa-space-nav ul.dropdown-menu ul li a.active {
  position: relative;
}

/* line 111, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.oa-navbar ul.oa-space-nav ul.dropdown-menu ul li a.active:not(:hover) {
  background: transparent;
  color: #333;
}

/* line 115, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.oa-navbar ul.oa-space-nav ul.dropdown-menu ul li a.active:before {
  content: "\f04b";
  font-family: FontAwesome;
  position: absolute;
  z-index: 1;
  left: 8px;
  font-style: normal;
  font-size: 0.9em;
  top: 0;
  padding: 3px 0;
}

/* line 128, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_menu.scss */
.oa-navbar ul.oa-space-nav ul.dropdown-menu ul li.oa-sections a.active:before {
  content: " ";
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.news_events_title {
  font-size: 2em;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.viuEvents__event,
.viuNews__story {
  margin-bottom: 2em;
}

/* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.viuNews__story__heading, .viuEvents__event__heading {
  font-size: 1em;
  margin-top: 0;
}

@media (min-width: 768px) {
  /* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
  .viuNews__story__heading, .viuEvents__event__heading {
    font-size: 19px;
  }
}

@media (min-width: 992px) {
  /* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
  .viuNews__story__heading, .viuEvents__event__heading {
    font-size: 22px;
  }
}

/* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.viuNews__story__heading a, .viuEvents__event__heading a {
  text-decoration: none !important;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
#viuNewsEvents.page-section--color-1 .viuNews__story__heading a,
#viuNewsEvents.page-section--color-1 .viuEvents__event__heading a {
  color: white !important;
}

/* line 41, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.content-container .viuNews__story__heading,
.content-container .viuEvents__event__heading {
  margin: 0 0 .5em 0;
}

/* line 47, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.viuNews__story__photo,
.viuEvents__event__dateicon {
  float: right;
  margin-left: 10px;
  max-width: 100px;
}

/* line 54, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon, .oa-event-date-wrapper {
  position: relative;
  background: transparent;
  border: 0;
  height: 60px;
  width: 60px;
  padding: 5px 0;
  border: 3px solid;
}

/* line 63, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon .oa-event-date-month-wrapper, .oa-event-date-wrapper .oa-event-date-month-wrapper {
  background: transparent;
  margin: 0 0 5px 0;
  line-height: 1em;
}

/* line 69, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon .oa-event-date-month, .oa-event-date-wrapper .oa-event-date-month {
  color: inherit;
}

/* line 73, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon .oa-event-date-day-wrapper,
.viuEvents__event__dateicon .oa-event-date-day, .oa-event-date-wrapper .oa-event-date-day-wrapper,
.oa-event-date-wrapper .oa-event-date-day {
  font-size: 22px;
  font-weight: bold;
  line-height: 0.9em;
}

/* line 79, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.viuEvents__event__dateicon br, .oa-event-date-wrapper br {
  display: none;
}

/* line 84, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.page-section--text-only.page-section--color-1 .viuEvents__event__dateicon, .page-section--text-only.page-section--color-2 .viuEvents__event__dateicon {
  border-color: white;
}

/***
  News & Events in the sidebar.
 */
/* line 95, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.news-teaser-sidebar h3, .events-teaser-sidebar h3 {
  font-size: 14px;
}

/* line 98, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_news_events.scss */
.news-teaser-sidebar p, .events-teaser-sidebar p {
  font-size: 0.8em;
}

/* line 5, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_node.scss */
.node-oa-worktracker-task h2.title.comment-form {
  margin-top: 1em;
}

/* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_node.scss */
.oa-event-date-month-wrapper {
  background: #40A03A;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_numbered_step.scss */
.viu-step {
  margin: 3em 0;
}

@media (min-width: 768px) {
  /* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_numbered_step.scss */
  .viu-step {
    padding-left: 5em;
    position: relative;
  }
  /* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_numbered_step.scss */
  .viu-step__number {
    font-family: 'ProximaNova-LightIt', sans-serif;
    color: #C9C8C8;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1em;
    font-size: 84px;
    font-weight: normal;
  }
}

@media (min-width: 992px) {
  /* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_numbered_step.scss */
  .viu-step__number {
    font-size: 120px;
  }
  /* line 29, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_numbered_step.scss */
  .viu-step__heading {
    font-size: 38px;
  }
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.content .radix-layouts-content .panel-pane {
  padding: 0;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-footer-panel .radix-layouts-content {
  padding-top: 1em;
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa_visibility_toolbar ul {
  padding: 5px 10px;
}

/* line 17, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa_visibility_toolbar i {
  vertical-align: middle;
}

/* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.content .radix-layouts-content .inner-pane > .panel-pane,
body.panels-ipe .content .radix-layouts-content .panels-ipe-portlet-content > .panel-pane,
.content .radix-layouts-content .panel-pane.pane-views-panes {
  border-right: 1px solid #E4E3E6;
  margin-right: -20px;
  padding-right: 20px;
}

/* line 32, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.content .radix-layouts-content .inner-pane > .panel-pane:after,
body.panels-ipe .content .radix-layouts-content .panels-ipe-portlet-content > .panel-pane:after,
.content .radix-layouts-content .panel-pane.pane-views-panes:after {
  content: " ";
  display: block;
  border-bottom: 2em solid #F0F0F3;
  margin: 2em -21px 0;
  border-top: 1px solid #E4E3E6;
}

@media (min-width: 992px) {
  /* line 42, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
  .content .radix-layouts-content .inner-pane > .panel-pane,
  body.panels-ipe .content .radix-layouts-content .panels-ipe-portlet-content > .panel-pane,
  .content .radix-layouts-content .panel-pane.pane-views-panes {
    margin-right: -40px;
    padding-right: 40px;
  }
  /* line 47, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
  .content .radix-layouts-content .inner-pane > .panel-pane:after,
  body.panels-ipe .content .radix-layouts-content .panels-ipe-portlet-content > .panel-pane:after,
  .content .radix-layouts-content .panel-pane.pane-views-panes:after {
    margin: 2em -41px 0;
  }
}

/* line 53, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-pane {
  border: none;
}

/* line 57, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-toolbar-theme-panel {
  padding-top: 12px;
  height: 67px;
}

/* line 62, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .panel-panel .pane-oa-navigation {
  display: inline-block;
  width: auto;
  background: transparent !important;
  padding: 0 0 5px 10px;
  max-height: 80px;
}

/* line 68, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .panel-panel .pane-oa-navigation img.oa-site-banner-img {
  margin-right: 10px;
}

/* line 71, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .panel-panel .pane-oa-navigation .oa-menu-banner {
  margin-top: -5px;
}

/* line 76, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .panel-panel .pane-oa-navigation ul.menu > li .caret {
  margin-left: 4px;
}

/* line 79, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .panel-panel .pane-oa-navigation ul.menu > li .dropdown-menu {
  background-color: #FFF;
}

/* line 82, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .panel-panel .pane-oa-navigation ul.menu > li .dropdown-menu > li > a {
  color: #333;
}

/* line 84, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .panel-panel .pane-oa-navigation ul.menu > li .dropdown-menu > li > a:hover {
  color: white;
}

@media (max-width: 767px) {
  /* line 95, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
  .oa-banner {
    display: none;
  }
}

/* line 101, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.navbar .panel-panel ul.oa_toolbar.oa-space-nav h3 > a {
  font-weight: bold;
  padding: 3px 10px;
  margin: -3px -10px;
  display: block;
  font-size: 14px;
}

/* line 107, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.navbar .panel-panel ul.oa_toolbar.oa-space-nav h3 > a:hover, .navbar .panel-panel ul.oa_toolbar.oa-space-nav h3 > a:active {
  text-decoration: none;
}

/* line 114, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.navbar .panel-panel ul.oa_toolbar.oa-space-nav li.btn-group ul.dropdown-menu li.btn-group.oa-no-separator:after {
  display: none !important;
}

/* line 119, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.navbar .panel-panel ul.oa_toolbar.oa-space-nav li.btn-group ul.dropdown-menu li.oa-hierarchy ul {
  margin-left: 10px;
}

/* line 122, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.navbar .panel-panel ul.oa_toolbar.oa-space-nav li.btn-group ul.dropdown-menu li.oa-hierarchy h3 {
  text-transform: uppercase;
  font-weight: normal;
  padding-bottom: 0;
  margin-top: 2px;
  font-style: italic;
  border: none;
}

/* line 135, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
body.oa-main-menu .navbar .panel-panel .pane-oa-space-nav {
  padding-left: 20px;
  border-left: 2px solid rgba(255, 255, 255, 0.3) !important;
}

@media (max-width: 767px) {
  /* line 142, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
  body.oa-main-menu .navbar .panel-panel .pane-oa-space-nav {
    padding-left: 0;
    padding-top: 7px;
    border-left: none !important;
  }
}

/* line 150, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar #oa-navbar-spaces .oa-space-nav {
  height: 36px;
  width: 100%;
}

/* line 153, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar #oa-navbar-spaces .oa-space-nav > li:first-child > ul.dropdown-menu {
  display: block;
  top: 34px;
  width: 100%;
}

/* line 159, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar #oa-navbar-spaces .oa-space-nav > li {
  width: auto;
  position: static;
}

/* line 163, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar #oa-navbar-spaces .oa-space-nav .oa-separator {
  width: auto;
  border-left: 1px solid #76BF72;
  height: 24px;
  margin-top: 5px;
}

/* line 171, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-separator {
  border-left: 2px solid rgba(255, 255, 255, 0.3) !important;
  padding-left: 15px;
  height: 42px;
}

/* line 178, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-recent .flag-favorite-space > a {
  text-align: left;
  border: none;
  margin-top: 1em;
  font-size: 13px;
}

/* line 187, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-edit-minipanel .radix-layouts-sidebar {
  position: absolute;
  bottom: 0;
}

/* line 193, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
body.oa-home .pane-oa-edit-minipanel {
  display: none;
}

/* line 197, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.btn.btn-circle {
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  display: inline-block;
  padding: 8px;
}

/* line 204, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-admin-minipanel .btn:hover {
  color: white !important;
  border-color: gray !important;
  background: #555 !important;
}

/* line 212, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-edit-minipanel .pane-add-space-content .btn-circle {
  border-radius: 22px;
  padding-right: 15px;
}

/* line 215, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-edit-minipanel .pane-add-space-content .btn-circle i {
  vertical-align: middle;
}

/* line 219, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-edit-minipanel .btn {
  background-color: #2a2d37;
  opacity: .65;
  color: #FFF;
}

/* line 223, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-edit-minipanel .btn.btn-primary {
  background-color: #49B544;
  opacity: 1;
}

/* line 227, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-edit-minipanel .btn > i {
  font-size: 24px;
  display: inline-block;
  width: 24px;
  height: 24px;
}

/* line 233, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-edit-minipanel .btn:hover {
  background-color: #FFF;
  opacity: .95;
  color: #2a2c37;
  border-color: #2a2d37;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.59);
}

/* line 245, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
body.oa-process-ipe #panels-ipe-control-container a.panels-ipe-startedit,
body.oa-process-ipe #panels-ipe-control-container a.panels-ipe-change-layout {
  display: none;
}

/* line 252, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-event-detail-event-details .oa-event-header {
  font-size: 14px;
  margin-bottom: 2em;
  color: #60616d;
}

/* line 256, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-event-detail-event-details .oa-event-header p {
  font-size: 14px;
  margin-bottom: 0 !important;
}

/* line 261, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-event-detail-event-details .oa-event-description {
  clear: both;
}

/* line 264, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-event-detail-event-details.panel-pane:after {
  display: none !important;
}

/* line 270, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-node-field-oa-related .addthisevent-drop {
  display: none;
}

/* line 273, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-node-field-oa-related .panel-pane:after {
  display: none !important;
}

/* line 276, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-node-field-oa-related .radix-layouts-sidebar {
  background: transparent !important;
}

/* line 279, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-node-field-oa-related .more-link {
  float: right;
}

/* line 282, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-node-field-oa-related .oa-callout {
  background-color: #F7F7F7;
  border-color: #C1C1C1;
}

/* line 289, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-event-list-oa-events-upcoming-small .oa-event-date-wrapper {
  font-size: 14px;
}

/* line 294, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.fullcalendar .fc-today {
  background-color: #CEF4CD;
}

/* line 299, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .toolbar-search form input {
  padding-right: 25px;
}

/* line 303, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .toolbar-search form .btn-group .btn.dropdown-toggle {
  position: absolute;
  left: -30px;
  background: transparent;
  border: none;
}

/* line 309, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .toolbar-search form .btn-group .btn.dropdown-toggle:hover .caret, .oa-navbar .toolbar-search form .btn-group .btn.dropdown-toggle:active .caret {
  border-top-color: #333;
  border-bottom-color: #333;
}

/* line 315, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .toolbar-search form .btn-group ul.dropdown-menu {
  right: 40px;
  left: auto;
}

@media (max-width: 767px) {
  /* line 324, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
  #oa-navbar-search .toolbar-search {
    width: calc(100% - 50px);
    text-align: right;
  }
  /* line 327, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
  #oa-navbar-search .toolbar-search input.search-text {
    width: calc(100% - 46px);
  }
}

/* line 337, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-notifications-pane .form-item-oa-notifications-notify-list-combined label,
.pane-node-form-oa-notifications .form-item-oa-notifications-notify-list-combined label {
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
}

/* line 343, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-notifications-pane .form-item-oa-notifications-skip-notify,
.pane-node-form-oa-notifications .form-item-oa-notifications-skip-notify {
  font-size: 12px;
  color: #777;
}

/* line 347, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-notifications-pane .flag-wrapper,
.pane-node-form-oa-notifications .flag-wrapper {
  margin-bottom: 1em;
}

/* line 350, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-notifications-pane .flag-message,
.pane-node-form-oa-notifications .flag-message {
  font-size: 13px;
  font-style: italic;
}

/* line 357, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-comment-media-commentnode-attachments .attachments {
  padding: 0;
  border-bottom: none;
}

/* line 361, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-comment-media-commentnode-attachments .views-row {
  margin-bottom: 5px;
  padding: 10px;
  background: #F4F4F4;
  border: 1px solid #E6E6E8;
}

/* line 366, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-comment-media-commentnode-attachments .views-row img {
  margin-right: 10px;
}

/* line 369, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-comment-media-commentnode-attachments .views-row .user-info {
  font-size: 13px;
  margin-bottom: 0;
  font-style: italic;
}

/* line 373, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-comment-media-commentnode-attachments .views-row .user-info a {
  font-weight: normal;
}

/* line 377, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-comment-media-commentnode-attachments .views-row .comment-label > a {
  margin-left: 0;
}

/* line 380, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-comment-media-commentnode-attachments .views-row .file-info {
  text-transform: uppercase;
}

/* line 384, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-comment-media-commentnode-attachments .views-row .filename i {
  color: #40A13A;
}

/* line 388, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.pane-oa-comment-media-commentnode-attachments .views-row .oa-media-document .oa-description {
  clear: both;
}

/* line 394, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .panel-panel .panel-pane {
  min-height: 45px;
}

/* line 399, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .pull-left #oa-user-badge {
  margin-right: 8px;
}

/* line 402, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.oa-navbar .pull-right #oa-user-badge {
  margin-left: 8px;
}

/* line 407, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
div#panels-ipe-control-container {
  top: 20px;
  right: 130px;
  left: 130px;
}

/* line 415, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
div#panels-ipe-control-container .form-item-revision {
  margin-right: 0.5em;
}

/* line 418, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
div#panels-ipe-control-container .form-type-textfield {
  width: 40%;
}

/* line 424, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_panel.scss */
.custom-program-option-header .field-item ul {
  list-style: none !important;
  padding-left: 0 !important;
}

/* line 1, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_responsive_video.scss */
.videoWrapper, .media-viutube-video, .media-youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

/* line 6, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_responsive_video.scss */
.videoWrapper iframe, .media-viutube-video iframe, .media-youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 1, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_sa11y.scss */
#sa11y-readability {
  padding: 10px 0px;
  border-bottom: 1px solid #cdcdcd !important;
}

/* line 6, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_sa11y.scss */
ul#readability-list {
  padding: 0px;
  list-style: none;
  font-size: 15px;
  margin: 5px 0px 0px 0px;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_sa11y.scss */
#readability-status {
  display: inline-block;
}

/* line 17, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_sa11y.scss */
.flesch-score {
  margin: 0 7px 0 7px;
}

/* line 21, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_sa11y.scss */
.readability-label {
  background-color: #e9e9e9 !important;
  border-radius: 0.25em !important;
  padding: 3px 5px !important;
  color: #4b4b4b !important;
  font-size: 15px;
}

/* line 1, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
.side-search-tab, .side-apply-tab {
  position: fixed;
  display: block;
  padding: .75em 1em .75em 1em;
  font-size: 20px;
  color: white;
  width: 60px;
  transition: right 0.1s;
  z-index: 100;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
.side-search-tab {
  top: 105px;
  right: 0;
  /* background-color: $brand-secondary; */
}

/* line 17, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
.side-search-tab:hover, .side-search-tab:focus {
  text-decoration: none;
  color: white;
  right: 0;
}

/* line 22, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
.side-search-tab span {
  display: none;
}

/* line 27, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
.side-apply-tab {
  display: none;
}

@media (min-width: 768px) {
  /* line 33, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
  .side-search-tab, .side-apply-tab {
    padding: .75em 1em .75em 1em;
    width: 145px;
    white-space: nowrap;
  }
  /* line 39, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
  .side-search-tab {
    top: 160px;
    right: -5px;
  }
  /* line 42, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
  .side-search-tab span {
    display: inline;
  }
}

@media (min-width: 992px) {
  /* line 48, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
  .side-search-tab {
    top: 185px;
  }
  /* line 52, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
  .og-context-node-266.root_space_id_2507 .side-apply-tab {
    display: block;
    top: 265px;
    right: -5px;
    /* background-color: $brand-secondary; */
  }
  /* line 57, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_side_tabs.scss */
  .og-context-node-266.root_space_id_2507 .side-apply-tab:hover, .og-context-node-266.root_space_id_2507 .side-apply-tab:focus {
    text-decoration: none;
    color: white;
    right: 0;
  }
}

/* line 29, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_site.scss */
.root_space_uuid_e34f6811-2ef6-4c57-9cf8-66d41a32abc4 #cta-apply--main-menu-header,
.root_space_uuid_f47015c5-fd41-47a3-9e64-29d4dd042c37 #cta-apply--main-menu-header,
.root_space_uuid_16beacd4-498a-49c7-b605-c4026500b9e0 #cta-apply--main-menu-header {
  display: none;
}

/* line 37, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_site.scss */
.root_space_uuid_4160105a-ca2d-42cb-aa84-5c2ac2738ab3 .side-search-tab {
  display: none;
}

/* line 2, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_social.scss */
.viuFollow {
  margin-top: 1em;
  text-align: center;
}

/* line 5, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_social.scss */
.viuFollow a:after {
  content: none !important;
}

/* line 8, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_social.scss */
.viuFollow .viu-social__socialIcon {
  font-size: 40px;
  margin: 0 1em;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.table-bordered {
  border: 0 !important;
}

/* line 8, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.table-bordered > tbody > tr > td {
  border: none;
  border-bottom: 1px solid #ddd;
}

/* line 13, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.table > thead > tr > th,
.sticky-header > thead > tr > th {
  background: transparent;
}

/* line 19, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.table > thead > tr > th.active {
  background: #e0e0e0;
}

/* line 23, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.table-bordered > thead > tr > th,
.sticky-header > thead > tr > th {
  border: none;
  border-bottom: 1px solid #ddd;
}

/* line 28, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.table-bordered > thead > tr > th:first-child,
.sticky-header > thead > tr > th:first-child {
  border-left: none;
}

/* line 31, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.table-bordered > thead > tr > th:last-child,
.sticky-header > thead > tr > th:last-child {
  border-right: none;
}

/* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
table td > div.form-type-checkbox,
table th > div.form-type-checkbox {
  text-align: center;
}

/* line 43, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: transparent;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.pane-content {
  position: relative;
}

/* line 54, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.oa-pane .pane-content {
  position: relative;
  padding: 0;
}

/* line 57, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.oa-pane .pane-content .oa-list {
  margin: 0;
}

/* line 62, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.oa-pane.inner-pane .panel-pane {
  padding-bottom: 1em;
}

/* try to target the main content region when inside a panel layout */
/* line 68, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.pane-page-content .radix-layouts-content > .panel-panel-inner {
  padding-right: 25px;
}

/* line 74, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.pane-page-content .radix-layouts-content .oa-pane.inner-pane .panel-pane {
  padding-bottom: 0;
  padding-top: 1.5em;
}

/* line 79, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
body.panels-ipe .pane-page-content .radix-layouts-content > .panel-panel-inner {
  border-right: none;
  border-bottom: none;
  padding: 0;
}

@media (min-width: 992px) {
  /* line 88, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
  .pane-page-content .radix-layouts-content > .panel-panel-inner {
    padding: 0;
  }
  /* line 92, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
  body.panels-ipe .pane-page-content .radix-layouts-content > .panel-panel-inner {
    padding: 0;
  }
}

/* line 99, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.oa-list.well {
  background: transparent;
  border: none;
}

/* line 105, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.oa-list-header {
  padding-top: 0;
}

/* line 110, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.group-content > li {
  border-top: 1px solid #EAEAEA;
  padding: 10px 0;
}

/* line 117, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.radix-layouts-sidebar .group-content li {
  padding: 5px 0;
}

/* line 122, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.oa-list-category {
  text-align: right;
  margin-left: 10px;
}

/* line 127, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_structure.scss */
.pane-pane-oa-messages {
  margin-top: 1em;
}

/* line 5, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_tab-accordion.scss */
.tabCollapse {
  *zoom: 1;
  margin-bottom: 3em;
}

/* line 9, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.tabCollapse:before, .tabCollapse:after {
  content: " ";
  display: table;
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/base/_mixins.scss */
.tabCollapse:after {
  clear: both;
}

/* line 12, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_tab-accordion.scss */
.nav-tabs--tabCollapse {
  float: left;
  width: 30%;
  border: 0;
  border-right: 1px solid #ededed;
}

/* line 18, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_tab-accordion.scss */
.nav-tabs--tabCollapse > li {
  float: none;
  border-bottom: 1px solid #ededed;
}

/* line 21, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_tab-accordion.scss */
.nav-tabs--tabCollapse > li a {
  margin-right: 0;
  border-radius: 0;
  border: 0;
}

/* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_tab-accordion.scss */
.nav-tabs--tabCollapse > li.active a, .nav-tabs--tabCollapse > li.active a:hover, .nav-tabs--tabCollapse > li.active a:focus {
  border: 0;
  background: rgba(0, 0, 0, 0.05);
}

/* line 34, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_tab-accordion.scss */
.tab-content--tabCollapse {
  float: left;
  padding-left: 2em;
  width: 70%;
}

/* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_tab-accordion.scss */
.panel-group .panel-heading .panel-title a:focus, .panel-group .panel-heading .panel-title a:hover, .viu-collapsible .panel-heading .panel-title a:focus, .viu-collapsible .panel-heading .panel-title a:hover {
  text-decoration: none;
}

/* line 48, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_tab-accordion.scss */
.panel-group .panel-heading .panel-title a:before, .viu-collapsible .panel-heading .panel-title a:before {
  content: "\f0dd ";
  font-family: "Fontawesome";
  margin-right: .5em;
}

/* line 53, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_tab-accordion.scss */
.panel-group .panel-heading .panel-title a.collapsed:before, .viu-collapsible .panel-heading .panel-title a.collapsed:before {
  content: "\f0da ";
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.user-info {
  font-size: 15px;
  color: #a4a8ab;
  margin-bottom: 10px;
}

/* line 8, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.user-info a {
  color: #2a2c37;
  font-weight: bold;
}

/* line 14, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.user-picture img,
.user-badge img {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border: 1px solid #eee;
}

/* line 24, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.content .pane-user-field-user-picture img {
  border: 1px solid #ddd;
  padding-bottom: 0;
  margin-bottom: 15px;
  background: white;
}

/* line 32, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.oa-list .pull-left img {
  margin-right: 0;
}

/* line 36, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.btn-inverse.user-badge img {
  border: 1px solid transparent;
}

/* line 40, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.view-oa-team-list .user-badge {
  background-color: transparent;
  border: none;
}

/* line 43, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.view-oa-team-list .user-badge img {
  margin-right: 0;
}

/* line 48, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.oa-comment .user-picture {
  margin-top: 14px;
}

/* line 50, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.oa-comment .user-picture img {
  height: 40px;
  width: 40px;
}

/* line 56, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.oa-list .user-picture img {
  max-height: 40px;
  max-width: 40px;
}

/* line 61, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_user.scss */
.indented {
  margin-left: 50px;
}

/* line 4, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.views-exposed-form .filter-btn {
  position: absolute;
  top: -40px;
  right: 0;
}

/* line 10, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-pane .views-exposed-form .filter-btn {
  top: -40px;
}

/* line 16, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.pane-content .oa-river .oa-list-inner,
.pane-oa-recent-activity-section-activity .oa-list-inner {
  margin-left: 30px;
  padding-left: 5px;
}

/* line 21, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.pane-content .oa-river .oa-list-header > span > a > strong,
.pane-oa-recent-activity-section-activity .oa-list-header > span > a > strong {
  font-weight: normal;
  font-size: 16px;
}

/* line 26, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.pane-content .oa-river .oa-list-header > span > a:first-of-type,
.pane-oa-recent-activity-section-activity .oa-list-header > span > a:first-of-type {
  color: #2a2c37 !important;
  font-weight: bold;
}

/* line 35, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.pane-open-atrium-news-oa-recent-news .pane-content {
  border-top: 4px solid #EAEAEA;
  padding-top: 20px;
}

/* line 42, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.label .marker {
  color: white;
}

/* line 45, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.label.mark-new {
  background-color: rgba(64, 161, 58, 0.7);
}

/* line 48, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.label.mark-updated {
  background-color: rgba(150, 150, 0, 0.7);
}

/* line 51, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.label.mark-unpublished {
  background-color: rgba(160, 0, 0, 0.7);
}

/* line 57, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-list-header.oa-description {
  overflow: visible;
  text-align: right;
}

/* line 61, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic h5 {
  font-size: 16px;
}

/* line 64, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .user-info {
  font-size: inherit;
}

/* line 67, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-date a {
  color: inherit;
}

/* line 70, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-description {
  color: #777;
}

/* line 73, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-reply-last {
  display: inline-block;
  text-align: right;
}

/* line 76, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-reply-last > a {
  color: inherit;
}

/* line 80, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-reply-icon {
  position: relative;
  display: inline-block;
}

/* line 83, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-reply-icon .oa-reply-count {
  position: relative;
  display: inline-block;
  z-index: 2;
  color: white;
  width: 2em;
  text-align: center;
  margin: 0 3px;
}

/* line 91, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-reply-icon .oa-reply-count a {
  color: inherit;
}

/* line 95, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-reply-icon i {
  position: absolute;
  z-index: 1;
  left: 0;
  font-size: 25px;
  top: -0.1em;
  margin: 0 3px;
  color: rgba(64, 64, 64, 0.7);
}

/* line 104, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-reply-icon.updated i {
  color: rgba(150, 150, 0, 0.7);
}

/* line 107, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-discussion-topic .oa-reply-icon.new i {
  color: rgba(64, 161, 58, 0.7);
}

/* line 113, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-news {
  border-bottom: 1px solid #CFCFCF;
  padding: 0 0 25px 0;
  margin-bottom: 30px;
}

/* line 117, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-news .oa-edit-node {
  margin-left: 10px;
}

/* line 119, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-news .oa-edit-node i {
  font-size: 18px;
  color: #a4a8ab;
}

/* line 124, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-news .oa-edit-node a:hover {
  text-decoration: none;
  color: #333;
}

/* line 128, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-news .oa-edit-node a > span {
  text-indent: -9999px;
  visibility: hidden;
}

/* line 134, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-news p, .oa-list.oa-news ol, .oa-list.oa-news ul {
  font-size: 14px;
  margin-bottom: 1em;
}

/* line 138, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-news .oa-news-body {
  margin-top: 0.8em;
}

/* line 141, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-news .oa-news-header h3 {
  margin: 0 0 0.5em 0;
  font-size: 24px;
  font-weight: normal;
}

/* line 146, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-news h4 {
  margin-bottom: 0.9em;
}

/* line 149, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.oa-list.oa-news .more-link {
  float: right;
  font-size: 14px;
}

/* line 157, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.views-widget-filter-search_api_views_fulltext label {
  display: none;
}

/* line 162, /home/boquistm/projects/d7_lagoon/viu-services/profiles/viu/themes/viu_theme/scss/components/_view.scss */
.pane-programs-options-pane-program-options-json h2 {
  display: none;
}
